import React, { useState } from 'react';
import { StyleSheet, Text, View, Button, ScrollView } from 'react-native';

import Utils from '../Utils';

const DEFAULT_LOG = {
    "sleepOnsetLatency": "25",
    "sleepQuality": "2",
    "sleepStressed": "2",
    "timeAwakeAtNight": "65",
    "timeInBed": "00:05",
    "timeLightsOut": "00:35",
    "timeOutOfBed": "7:30",
    "timesAwake": "2",
    "wakeEarly": "no",
    "wakeTime": "7:00"
};
const NULL_LOG = {
    "sleepOnsetLatency": "0",
    "sleepQuality": "3",
    "sleepStressed": "3",
    "timeAwakeAtNight": "0",
    "timeInBed": "04:00",
    "timeLightsOut": "04:00",
    "timeOutOfBed": "4:00",
    "timesAwake": "0",
    "wakeEarly": "no",
    "wakeTime": "4:00"
};
 
export function SleepDataBar(props) {
	const log = props.log || (props.log === null ? NULL_LOG : DEFAULT_LOG);
	const label = props.label || "12-31";
	let hr0 = props.startHour === undefined ? 1 : props.startHour;
	let hr1 = props.endHour === undefined ? 11 : props.endHour;
	if(hr0 > hr1) {
		console.log("added 24 hrs since endHour="+hr1+" was before startHour="+hr0);
		hr1 += 24;
	}

	//let dbg = label + ": ";

	function invLerpTime(hour, min) {
		let hr = hour + min / 60.0;
		if(hr < hr0 && hr + 24 <= hr1)
			hr += 24;
		//dbg += "\n" + hr + " : (" + hr1 + " - " + hr0 + ") = " + ((hr - hr0) / (hr1 - hr0));
		return (hr - hr0) / (hr1 - hr0);
	}
	
	// NOTE: no longer forcing morning/night for log times, because the logs *should* be giving accurate 24-hour times
	const startBedTime = Utils.parseTime(log.timeInBed);
	const lightsOut = Utils.parseTime(log.timeLightsOut); 
	const solMins = log.sleepOnsetLatency;
	const sol = { hr: Math.floor(solMins/60), min: Math.floor(solMins%60) }; // Utils.parseDuration(log.sleepOnsetLatency);
	const wakeUpCount = parseInt(log.timesAwake, 10);
	const wasoMins = log.timeAwakeAtNight;
	const wasoTime = wakeUpCount <= 0 ? {hr:0, min:0} : { hr: Math.floor(wasoMins/60), min: Math.floor(wasoMins%60) };//Utils.parseDuration(log.timeAwakeAtNight);
	const timeWokeUp = Utils.parseTime(log.wakeTime);
	const endBedTime = Utils.parseTime(log.timeOutOfBed);
		
	
	const breakPoints = [
		0,
		invLerpTime(startBedTime.hr, startBedTime.min),
		invLerpTime(lightsOut.hr, lightsOut.min),
		invLerpTime(lightsOut.hr + sol.hr, lightsOut.min + sol.min),
		invLerpTime(timeWokeUp.hr, timeWokeUp.min),
		invLerpTime(endBedTime.hr, endBedTime.min),
		1
	];
	
	//console.log(dbg);

	const wasoElems = [];
	if(wakeUpCount > 0 && (wasoTime.hr > 0 || wasoTime.min > 0)) {
		const timeFellAsleep = {hr: (lightsOut.hr + sol.hr + Math.floor((lightsOut.min + sol.min) / 60)), min: ((lightsOut.min + sol.min) % 60)};
		const hrsSleeping = ((timeWokeUp.hr + 48 + timeWokeUp.min / 60) - (timeFellAsleep.hr + 24 + timeFellAsleep.min / 60)) % 24;
		const hrsPerWaso = (wasoTime.hr + wasoTime.min / 60) / wakeUpCount;
		const hrsNotWaso = (hrsSleeping - hrsPerWaso * wakeUpCount) / (wakeUpCount + 1);
		console.log("waso="+wakeUpCount+" x "+hrsPerWaso+", nonWaso=" + (wakeUpCount+1) + " x " + hrsNotWaso);
		for(let i=0; i<wakeUpCount; i++) {
			wasoElems[i * 2] = (<View key={i*2} style={[{flex: hrsNotWaso}, styles.clear]} />);
			wasoElems[i * 2 + 1] = (<View  key={i*2+1} style={[{flex: hrsPerWaso}, styles.wasoTime]}></View>);			
		}
		wasoElems[wasoElems.length] = (<View key="x" style={{flex: hrsNotWaso, ...styles.clear}} />);
	}
	else {
		wasoElems[0] = (<View key={0} style={[{flex: 0}, styles.wasoTime]}></View>)
	}

	console.log(label, JSON.stringify(breakPoints));


	return (
	<View style={styles.cont}>
		<View style={styles.barBox}>
			<View style={[{flex: (breakPoints[1] - breakPoints[0])}, styles.clear]}/>
			<View style={[{flex: (breakPoints[2] - breakPoints[1])}, styles.invalidBedtime]}/>
			<View style={[{flex: (breakPoints[3] - breakPoints[2])}, styles.solTime]}/>
			<View style={[{flex: (breakPoints[4] - breakPoints[3])}, styles.sleepTime]}>
				{wasoElems}
				{log === NULL_LOG ? null : (<View style={styles.midsleepMark} />)}
			</View>
			<View style={[{flex: (breakPoints[5] - breakPoints[4])}, styles.invalidBedtime]}/>
			<View style={[{flex: (breakPoints[6] - breakPoints[5])}, styles.clear]}/>
		</View>
		<View style={styles.labelCont}>
			<Text style={log !== NULL_LOG ? styles.label : styles.labelDisable}>{label}</Text>
		</View>
	</View>
	);	
}


const styles = StyleSheet.create({
	cont: {
		flexGrow: 1,
		flexShrink: 1,
		marginLeft: 1,
		marginRight: 1,
		maxWidth: 100
	},
	barBox: {
		height: "100%",
		alignItems: "stretch"
	},
	clear: {
		backgroundColor: "#00000005"
	},
	invalidBedtime: {
		backgroundColor: "#ff8000ee"
	},
	solTime: {
		backgroundColor: "#ffaf40ee"
	},
	wasoTime: {
		backgroundColor: "#ffaf40ee",

	},
	sleepTime: {
		backgroundColor: "#00aaffee",
		justifyContent: "space-around",
		alignItems: "stretch",
	},
	midsleepMark: {
		backgroundColor: "#005580",
		height: 1,
		position: "absolute",
		top: "50%",
		width: "30%",
		marginLeft: "35%"
	},
	labelCont: {
		marginLeft: "50%"
	},
	label: {
		fontSize: 11,
		textAlign: "center",
		position: "absolute",
		width: 80,
		left: -40
	},
	labelDisable: {
		fontSize: 11,
		textAlign: "center",
		position: "absolute",
		width: 80,
		left: -40,
		color: "#bbbbbb"
	}

});
