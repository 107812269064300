import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
//import * as React from 'react';
import React, { useState, useRef } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import MoreScreen from '../screens/MoreScreen';
import QuestionsScreen from '../screens/QuestionsScreen';
import useCachedResources from '../hooks/useCachedResources';

// import * as Linking from 'expo-linking';

// const linking = {
  // prefixes: [Linking.makeUrl('/')],
  // config: {
    // More: {
      // path: 'more',
      // screens: {
		// More: 'more'
      // },
    // },
	// More2: {
      // path: 'more2',
      // screens: {
		// More: 'more'
      // },
    // },
  // },
// };

const IntakeFormDefaults = {
	primaryConcerns: [],
	insomniaLength: null, // string eg "< 3 months" or "1-2 years"
	wakeUpCount: null,
	wakeUpDuration: { hr: 0, min: 0},
	wakeUpBeforeAlarm: null, 
	differentWeekends: null, 
	bedTimeWeekday: { hr: 22, min: 0},
	solWeekday: { hr: 0, min: 0},
	wakeTimeWeekday: { hr: 7, min: 0},
	bedTimeWeekend: { hr: 22, min: 0},
	solWeekend: { hr: 0, min: 0},
	wakeTimeWeekend: { hr: 7, min: 0},
	napsPerDay: null,
	napDuration: { hr: 0, min: 0 },
	fatigue: null,
	irritability: null,
	concentration: null,
	hyperactivity: null,
	impulsiveness: null,
	moodChanges: null,
	sleepinessCar: null,
	sleepinessTv: null,
	sleepinessWorkSchool: null,
	sleepinessOther: null,
	fallAsleepFocus: null,
	activitiesInBed: [],
	activitiesInBedOther: '',
	bedPartners: [],
	bedPartnerDisturb: null,
	bedPartnersOther: '',
	activitiesCantSleep: [],
	activitiesCantSleepOther: '',
	exercisePerWeek: null,
	exerciseTime: { hr: 6, min: 0 },
	inBedDuringDay: null,
	brightnessNight: null,
	brightnessMorning: null,
	loudnessNight: null,
	comfortMattress: null,
	substances: [],
	substancesOther: '',
	pastSolutions: [],
	pastSolutionsOther: '',
	pastRxs: [],
	pastRxsOther: '',
	pastOtcs: [],
	pastOtcsOther: ''
};

// js equality is weird. we want to test if two object have all the same properties with the same values... recursion time!
const EqualityChecker = (a, b) => {
	
	// quick easy check first, only true for primitives and identical references
	if(a === b)
		return true;
	
	// test arrays
	if(Array.isArray(a) || Array.isArray(b))
	{
		if(!Array.isArray(a) || !Array.isArray(b) || a.length != b.length)
			return false;
		for(let i=0; i<a.length; i++)
			if(!EqualityChecker(a[i], b[i]))
				return false;
		return true;
	}
	// test objects
	if((typeof a === 'object' && a !== null) || (typeof b === 'object' && b !== null))
	{
		if(!(typeof a === 'object' && a !== null) || !(typeof b === 'object' && b !== null))
			return false;
		var aProps = Object.getOwnPropertyNames(a);
		var bProps = Object.getOwnPropertyNames(b);	
		if(aProps.length != bProps.length)
			return false;
		for(let i=0; i<aProps.length; i++)
			if(!EqualityChecker(a[aProps[i]], b[aProps[i]]))
				return false;
		return true;
	}
	
	return false;
};

const Stack = createStackNavigator();

export default function IntakeNavScreen(props) {
	const [startTime, setStartTime] = useState(Date.now());
	const [intakeData, setIntakeData] = useState({...IntakeFormDefaults});
	const isLoadingComplete = useCachedResources();

	const udata = props.userdata;
	const setUdata = props.setUserdata;
	const userid = (!udata || !udata.profile) ? null :  udata.profile.userid;

	if(udata && udata.profile && udata.profile.intakeData && Object.keys(udata.profile.intakeData).length > Object.keys(intakeData).length) {
		console.log("updated intake data");
		setIntakeData({...udata.profile.intakeData});
	}
	
	function handleDataChange(newData) {
		// const isEqual = EqualityChecker(newData, intakeData);
		// if(isEqual) { 
			// console.log("Got equality for objects:");
			// console.log(intakeData);
			// console.log(newData);
			// return;
		// }
		setIntakeData(newData);
	}
	
	console.log("Stack:");
	console.log(Stack);
  
  if (!isLoadingComplete) {
    return null;
  } else {

	const PAGE_COUNT = 7;
	const pages = [...Array(PAGE_COUNT).keys()];

    return (
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}
        <NavigationContainer independent={true}>
          <Stack.Navigator>
			{pages.map(i => {
				return (
				  <Stack.Screen name={"Page "+i} key={i} options={{title:'Intake ' + (i+1) + '/' + PAGE_COUNT, headerShown:true}}>
					{props => 
					<QuestionsScreen {...props} 
						page={i} 
						userid={userid} 
						onSubmitSuccess={(profile) => { if(udata && setUdata) { udata.profile = profile; setUdata({...udata}); } }} 
						pageCount={PAGE_COUNT} 
						time={startTime} 
						data={intakeData} 
						onChange={(newData) => handleDataChange(newData)}
					/>}
				  </Stack.Screen>
				);
			  })}		  
            <Stack.Screen name="More" component={QuestionsScreen} />
            <Stack.Screen name="More2" component={MoreScreen} />
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
