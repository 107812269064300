import React, { useState } from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, FlatList, Picker, Switch, TextInput  } from 'react-native';


// ok, combine TimePicker and TimePicker2 to make the ultimate customizable time picker
// expected props:
//  - (req) hour - int from 0 to 23
//  - (req) minute - int from 0 to 59
//	- (req) onTimeChanged(hour,minute) - hour is 0-23, accounting for am/pm, minute is 0-59
//  - (opt) resolution - gap between minutes options, min is 1, max is 60, default is 1
//	- (opt) combined - if true, there is one picker for hours+mins, if false they are separated. default is false
//	- (opt) dialog - if true, android shows dropdown list as modal dialog, doesnt affect web or ios. default is false
export function TimePickerCallen(props) {
	const [internalHours, setInternalHours] = useState(22);
	const [internalMinutes, setInternalMinutes] = useState(0);
	const [rndID, setRndID] = useState(Math.floor(Math.random() * 1000));
	
	function clamp(value, min, max) { 
		return Math.max(min, Math.min(max, value));
	}
	function onChanged(newHour, newMin)  {
		newHour = clamp(newHour, 0, 23);
		newMin = clamp(newMin, 0, 59);
		setInternalHours(newHour);
		setInternalMinutes(newMin);
		if(props.onTimeChanged)
			props.onTimeChanged(newHour, newMin);
	}

	
	
	const resolution = (props && typeof props.resolution === 'number') ? clamp(props.resolution, 1, 60) : 1;
	const combine = (props && props.combined);
	const dialog = (props && props.dialog);
	const tsMode = (props && props.timespan === true);

	const hr = (props && typeof props.hour === 'number') ? clamp(props.hour, 0, 23) : internalHours;
	const min = (props && typeof props.minute === 'number') ? clamp(props.minute, 0, 59) : internalMinutes;
	const isPm = hr >= 12;
	
	const prettyHr = ((isPm ? (hr - 12) : hr) + 11) % 12 + 1;
	const lockedMin = (Math.floor(0.01 + min / resolution) * resolution);
	
	function makePicker(num, values, selectedValue, width, callback) {
		//console.log(values);
		return (	
		<View style={Platform.OS === 'android' ? styles.pickerBoxAndroid : null}>		
			<Picker
				style={[styles.picker, {'width': width}]}
				mode={dialog ? 'dialog' : 'dropdown'}
				selectedValue={selectedValue}
				onValueChange={(itemValue, itemIndex) => callback(itemValue)}
			>{values.map((t,ix) =>{
				//const uniqueKey = num+"-"+rndID + "-" + ix;
				//console.log(uniqueKey);
				return (<Picker.Item label={t} value={t} style={{width:80, color: (ix % 2 == 0 ? 'red' : 'white')}}/>);
			})}</Picker>
		</View>
		);
	}
	
	function formatHour(index) {
		if(!tsMode) return	(((index + 11) % 12) + 1);
		else return (index + 0);
	}
	
	let picker1 = null, picker2 = null, separator = null, suffix = null;
	if(combine)	{
		const platformWidth = Platform.OS === 'ios' ? 100 : (Platform.OS === 'android' ? 120 : 110);
		const vals = [];
		for(let i=0; i<12; i++) 
			for(let j=0; j<60; j+=resolution) 
				vals.push(formatHour(i) + ":" + ("00" + j).slice(-2));
		picker1 = makePicker(0, vals, prettyHr+":"+("00"+lockedMin).slice(-2), platformWidth, 
			(val)=>{
				const parts = val.split(':');
				const hrPart = parseInt(parts[0].trim()) % 12 + (isPm ? 12 : 0);
				const minPart = parseInt(parts[1].trim());
				onChanged(hrPart, minPart);
			});
		if(tsMode) suffix = (<Text>(Hours:Minutes)</Text>);
	} else {
		const platformWidth = Platform.OS === 'ios' ? 50 : (Platform.OS === 'android' ? 84 : 75);
		const hrVals = [];
		const minVals = [];
		for(let i=0; i<12; i++) hrVals.push(formatHour(i)+"");
		for(let j=0; j<60; j+=resolution) minVals.push(("00" + j).slice(-2));
		picker1 = makePicker(1, hrVals, prettyHr + "", platformWidth, (val) => { onChanged(parseInt(val) % 12 + (isPm ? 12 : 0), min); });
		picker2 = makePicker(2, minVals, ("00"+lockedMin).slice(-2), platformWidth, (val) => { onChanged(hr, parseInt(val)); });
		if(!tsMode)
			separator = (<Text style={styles.timeColon}> : </Text>);	
		else {
			separator = (<Text style={styles.tsLabelHr}>Hours</Text>);
			suffix = (<Text style={styles.tsLabelMin}>Minutes</Text>);
		}
	}
	
	return (
		<View style={styles.horzContainer}>
			{ !tsMode 
			? null
			: (<View style={styles.tsSpacer}/>)
			}

			{[picker1, separator, picker2, suffix]}
			<View style={styles.spacer} />
			{ tsMode 
			? null 
			:
			<View style={{alignItems: "center"}}>
				<Switch value={isPm}
					onValueChange={(value) => {onChanged(hr % 12 + (value ? 12 : 0), min); }} 
				/>
				<View style={styles.horzContainer}>
					<Text style={isPm ? styles.textOff : styles.textOn}>AM</Text>
					<View style={styles.spacer} />
					<Text style={isPm ? styles.textOn : styles.textOff}>PM</Text>
				</View>
			</View>
			}
		</View>
	);

}



const styles = StyleSheet.create({

  horzContainer: {
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center"
  },
  picker: {
	fontSize: 18,
	textAlign: 'center',
	padding: 0,
  },
  pickerBoxAndroid: {
	borderTopWidth: 1,
	borderBottomWidth: 1,
	borderColor: '#ddd'
  },
  textOn:{
	  color: "#009688",
	  fontWeight: "bold"
  },
  textOff:{
	  color: "#aaa"
  },
 
  spacer:{
	  width: 10,
	  height: 10,
  },   
  tsSpacer:{
	  width: 15,
	  height: 15,
  },  

  timeColon: {
	  fontSize: 26, 
	  fontWeight: "bold", 
	  paddingBottom: 4,
  },
  tsLabelHr:{
	  marginLeft: 3,
	  marginRight: 20,
	  fontSize: 16, 
  },
  tsLabelMin:{
	  marginLeft: 3,
	  marginRight: 5,
	  fontSize: 16, 
  }
  
});

