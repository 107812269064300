import React, { useState } from 'react';
import { StyleSheet, Text, View, Button, ScrollView, TouchableOpacity } from 'react-native';


export function IntroScreen(props) {

	const onComplete = props.onComplete || null;

	/*return (
	<ScrollView style={sty.scrollCont}>
		<View style={sty.container}>
			<Text style={sty.titleText}>Introduction to CBT-i</Text>
			<View style={sty.textCont}>
				<Text>1) Psychoeducation and cognitive restructuring.  Cognitive behavioral therapy helps to shift common insomnia misconceptions and perceived poor sleep quality. For instance, participants learn that nighttime wakening is normal. The education content includes significant information about education and cognitive restructuring</Text>
				<View style={sty.spacer} />
				<Text>2) Sleep hygiene. Practical and general information about optimal sleep health, sleep hygiene and sleep/wake regulation are key CBT-I techniques to facilitate behavior change and set sleep health priorities. Participants are encouraged to eliminate daytime napping, use of caffeine after 2pm, and consumption of nicotine, and alcohol at night. Participants should also consider increasing exercise and improving the bedroom environment. Participants should also minimize screen time use at night. </Text>
				<View style={sty.spacer} />				 
				<Text>3) Relaxation exercises. Progressive muscle relaxation is a technique commonly used in CBT-I. Participants are encouraged to identify calming podcasts or other relaxation exercises to be done in preparation for bed but not actually to fall asleep.</Text>
				<View style={sty.spacer} />
				<Text>4) Stimulus control.  Dissociating wakefulness and frustration lying in bed awake is a key component of CBT-I. Participants are encouraged to leave the bed after 20 minutes if they are lying in bed awake. In another room, the participant should read or listen to non-stimulating material, or will provide ideas for other calming activities (i.e., no use of screens). Participants should only return to the bedroom when sleepy.</Text>
				<View style={sty.spacer} />
				<Text>5) Sleep restriction. Sleep restriction is a challenging aspect of CBT-I that directs individuals to use utmost discipline to reduce their sleep duration as a means of increasing their homeostatic sleep drive to ultimately consolidate and improve sleep continuity and quality.  </Text>
			</View>
			<View style={sty.spacer} />
			{!onComplete ? null :
				<Button style={sty.buttons} 
					title="   Let's Go!   " 
					onPress={onComplete}
				/>
			}
		</View>
	</ScrollView>
	);*/
	return (
	<ScrollView style={sty.scrollCont}>
		<View style={sty.container}>
			<Text style={sty.titleText}>Introduction to Insomnia</Text>
			<View style={sty.textCont}>
			
				<Text>
					<Text style={sty.bulletPoint}>
						{`What is insomnia? \n`}
					</Text>
					Insomnia is a very common sleep disorder. It is defined as difficulty falling asleep or staying asleep, and/or poor-quality sleep.  Daytime impairment (e.g., tiredness, inattention, moodiness) and excessive worry about sleep are associated with the nighttime sleep disruption. 
				</Text>
				<View style={sty.spacer} />		
							
				<Text>
					<Text style={sty.bulletPoint}>
						{`Prevalence of insomnia in breast cancer survivors. \n`}
					</Text>
					Insomnia is common in adults, including those without a history of breast cancer. Breast cancer puts women at even higher risk, because of its many physical and psychological challenges. For example, feeling stressed at diagnosis, struggling to sleep while you were recovering from surgery, or the hot flashes that might have developed during hormonal therapy, are all possible triggers for developing insomnia. Many treatments for breast cancer result in early menopause, which accounts for some of the insomnia symptoms.  Estimates suggest that between one third to half of breast cancer survivors report insomnia.
				</Text>
				<View style={sty.spacer} />		
							
				<Text>
					<Text style={sty.bulletPoint}>
						{`Physical health consequences \n`}
					</Text>
					Getting consistent and sufficient sleep is essential for good health. Chronic insomnia has been associated with various health problems when it is persistent and untreated.  Sudden onset of insomnia can also be a sign of other health problems. During sleep, our bodies and brains repair themselves. Some research suggests our brains use the time during sleep to clear away toxins that build up during the day.
				</Text>
				<View style={sty.spacer} />		
							
				<Text>
					<Text style={sty.bulletPoint}>
						{`Psychological health consequences \n`}
					</Text>
					Insomnia can contribute to worsening mood, and in some cases may be associated with depression and anxiety. Overall, it has been shown to lower your quality of life. What is unique about insomnia and mood health is that the relationship is bidirectional. Research has demonstrated that if you successfully improve your insomnia, your mental health improves as well.
				</Text>
				<View style={sty.spacer} />		
				
			</View>
			<View style={sty.spacer} />
			{!onComplete ? null :
				<Button style={sty.buttons} 
					title="   Let's Go!   " 
					onPress={onComplete}
				/>
			}			
		</View>
	</ScrollView>
	);
}


const sty = StyleSheet.create({
  scrollCont: {
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'	  
  },	
  container: {
	alignItems: 'center',
	width: '100%',
	backgroundColor: '#FFFFFF'
  },
  titleText: {
    fontSize: 32,
    color: '#102452',
    lineHeight: 50,
    textAlign: 'center',
	margin: 8
  },
  textCont: {
	width: 600,
	maxWidth: "95%",
	fontSize: 18,
  },
  spacer: {
	  width: 20,
	  height: 20
  },
  bulletPoint: {
	  fontWeight: 'bold'
  }
});