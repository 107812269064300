import React, { useState } from 'react';
import { StyleSheet, Text, View, TextInput, Button, Keyboard, ScrollView  } from 'react-native';
import { NumberStepper } from '../components/NumberStepper';
import { NumericCodeEntry } from '../components/NumericCodeEntry';

import AsyncStorage from '@react-native-async-storage/async-storage';

const VERSION = "0.6.2"; // last updated 04-08-2022

const LOGOUT_PW = "sug3kw5ty";

// TODO: HEY NOTICE ME SEMPAI! We need to add timezone info to the intake flow to be used by the alexa skill.

export function LoginScreen(props) {
	const [emailAddr, setEmailAddr] = useState("");
	const [canEditEmail, setCanEditEmail] = useState(true);
	const [isNetworking, setIsNetworking] = useState(false);
	const [authTtl, setAuthTtl] = useState(0);
	const [isCreate, setIsCreate] = useState(false);
	const [authCode, setAuthCode] = useState([0,0,0,0,0])
	const [errorMsg, setErrorMsg] = useState("");
	const [timerTO, setTimerTO] = useState(null);
	const [showingLogOut, setShowingLogOut] = useState(false);
	const [logoutPw, setLogoutPw] = useState("Enter Password?");
		
	const udata = props.userdata;
	const setUdata = props.setUserdata;
	
	const pushToken = props.pushToken;
	
	const userid = (!udata || !udata.profile) ? null :  udata.profile.userid;
	const logoutBlocked = (!udata || !udata.profile || !udata.profile.logoutBlocked) ? false : true;
	
	const showOtherButtons = true;
	
	if(userid) {
		if(udata.profile && emailAddr !== udata.profile.email)
			setEmailAddr(udata.profile.email);
		if(canEditEmail !== false)
			setCanEditEmail(false);
	}
	else if(showingLogOut) {
		setShowingLogOut(false);
	}

	
	// ugh: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
	// using loose rules, check for: 1. an @ symbol, with at least 1 char before it, 2. a dot, more than 1 char after the last @ symbol and last dot, 3. at least 2 chars after the last dot.
	function isEmailValid(emailStr) {
		emailStr = emailStr.trim().toLowerCase();
		if(emailStr.length <= 0)
			return false;
		const lastAt = emailStr.lastIndexOf('@');
		const lastDot = emailStr.lastIndexOf('.');
		if(lastAt <= 0) return false;
		if(lastDot <= lastAt + 1) return false;
		return lastDot < emailStr.length - 2; // shortest valid TLD is 2 chars
		// NOTE: it would be cool to validate TLD by downloading this list at startup, but seems like overkill... http://data.iana.org/TLD/tlds-alpha-by-domain.txt
	}

	
	function submitEmail(forceCreate) {
		const trimAddr = emailAddr.trim();
		
		let formData = { mode: "auth", email: trimAddr };
		if(forceCreate) formData.create = "yes";
		
		if(trimAddr !== emailAddr) setEmailAddr(trimAddr);
		setIsNetworking(true);
		
		fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
		{
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify(formData)
		})
		.then(response => response.json())
		.then(resp => { 
			console.log(resp);
			setIsNetworking(false);

			if(resp.success === true) {
				setAuthTtl(resp.ttl);
				return;
			}
			// failure, or new user?
			if(forceCreate) {
				// failure.
				setCanEditEmail(true); 
				setEmailAddr("");
				setIsCreate(false);
				setErrorMsg("Failed to create user.");
			}
			else {
				// new user
				setIsCreate(true);
			}
		});	
	}
	
	function submitAuthCode(authcode) {
		const codeStr = authcode || authCode.join('');
		const formData = {
			mode: 'authcode',
			email: emailAddr,
			code: codeStr
		};
		setIsNetworking(true);

		fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
		{
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify(formData)
		})
		.then(response => response.json())
		.then(resp => { 
			setIsNetworking(false);
			if(resp.success !== true) {
				console.log("Auth failed.");
				console.log(resp);
				setErrorMsg("Log in failed, check the code and try again");
				return;
			}
			setAuthTtl(0);

			udata.profile = resp.profile;
			AsyncStorage.setItem('sleepHelperUID', resp.profile.userid)
				.then((res) => console.log("saved uid, result: " + res), (err) => console.log(err));
			setUdata({...udata});
			console.log("Set udata=" +JSON.stringify(udata));
		});		
	}
	
	if(errorMsg !== "") {
		setTimeout(()=>setErrorMsg(""), 3500);
	}
	
	if(authTtl > 0 && timerTO === null && !userid) {
		setTimerTO(setTimeout(()=>{
			setTimerTO(null);
			setAuthTtl(Math.max(0, authTtl - 1));
		}, 900));
	}

	return (
		<ScrollView style={sty.scrollCont}>
		<View style={sty.container}>
			{!errorMsg ? null : <Text style={sty.errorText}>{errorMsg}</Text>}
			<Text style={sty.headerText}>Faster Asleep</Text>
			<Text style={sty.subheaderText}>Companion App (v {VERSION})</Text>
			<View style={sty.innerContainer}>
				<Text style={sty.emailLabel}>Enter Your Email:</Text>
				<TextInput
					style={canEditEmail ? sty.inputText : StyleSheet.compose(sty.inputText, {backgroundColor: "#f4f4ff", borderWidth: 0})}
					textContentType="emailAddress"
					keyboardType="email-address"
					autoCorrect={false}
					autoCapitalize="none"
					placeholder="example@website.com"
					editable={canEditEmail}
					value={emailAddr}
					onChangeText={(txt) => setEmailAddr(txt)}
				/>
				<Button 
					color='#323180'
					title=" Start "
					disabled={!isEmailValid(emailAddr) || !canEditEmail}
					onPress={()=>{
						console.log("entered email: " + emailAddr); 
						setCanEditEmail(false);
						setIsCreate(false);
						submitEmail(false);
					}}
				/>		
				{(!!userid || canEditEmail || !isCreate || authTtl > 0)
				? null :
				<View>
					<View style={sty.createLabelContainer}>
						<Text style={sty.createLabel}>
							There is no account for this email address, would you like to create a new account?
						</Text>
					</View>
					<View style={sty.createBtns}>

						<View style={sty.createBtn}>					
							<Button 
								color='#323180'
								title="Create New Account"
								disabled={isNetworking}
								onPress={()=>{
									console.log("entered email: " + emailAddr);
									submitEmail(true);
								}}
							/>
						</View>
						<View style={sty.createBtn}>
							<Button 
								color='#a22b29'
								title="Re-enter Email"
								disabled={isNetworking}
								onPress={()=>{setCanEditEmail(true); setEmailAddr(""); setIsCreate(false);}}
							/>
						</View>
					</View>
				</View>
				}
				{(!!userid || authTtl <= 0)
				? null :
				<View>
					<View style={sty.createLabelContainer}>
						<Text style={sty.createLabel}>
							Please check your email for a login code, and enter it below ({Math.floor(authTtl / 60)}:{("00" + (Math.floor(authTtl)%60)).slice(-2)} remain):
						</Text>
					</View>	
					<View style={sty.authSteppers}>
						{/*<NumberStepper 
							title="" 
							style={sty.stepper}
							vertical={true}
							maximum={9}
							value={authCode[0]} 
							onChange={(val)=>{authCode[0] = val; setAuthCode([...authCode]);}} 
						/>
						<NumberStepper 
							title="" 
							style={sty.stepper}
							vertical={true}
							maximum={9}
							value={authCode[1]} 
							onChange={(val)=>{authCode[1] = val; setAuthCode([...authCode]);}} 
						/>
						<NumberStepper 
							title="" 
							style={sty.stepper}
							vertical={true}
							maximum={9}
							value={authCode[2]} 
							onChange={(val)=>{authCode[2] = val; setAuthCode([...authCode]);}} 
						/>
						<NumberStepper 
							title="" 
							style={sty.stepper}
							vertical={true}
							maximum={9}
							value={authCode[3]} 
							onChange={(val)=>{authCode[3] = val; setAuthCode([...authCode]);}} 
						/>
						<NumberStepper 
							title="" 
							style={sty.stepper}
							vertical={true}
							maximum={9}
							value={authCode[4]} 
							onChange={(val)=>{authCode[4] = val; setAuthCode([...authCode]);}} 
						/>*/}
						<NumericCodeEntry 
							title=''
							digits={5}
							onSubmit={(code) => submitAuthCode(code)}
						/>
					</View>
					{/*<Button 
						color='#323180'
						title=" Log In "
						disabled={isNetworking || authCode.join('') == '00000'}
						onPress={()=>{
							console.log("entered email: " + emailAddr); 
							submitAuthCode();
						}}
					/>*/}					
				</View>
				}
				{!userid ? null :
				<View>
					<Text style={sty.detailText}>
						Logged in with userid: {"\n"+userid+"\n\n"} 
						Account Creation: {(!udata.profile.created ? "unknown" : new Date(udata.profile.created)) + "\n"}
						Last Authentication:  {(!udata.profile.lastLogin ? "unknown" : new Date(udata.profile.lastLogin)) + "\n"}
						Push Notifications: {!!pushToken ? `Enabled (Token: ${pushToken})` : (pushToken === "" ? "Not enabled" : "N/A")}
					</Text>
					{showingLogOut || logoutBlocked ? null :
					<Button 
						color='#484766'
						title=" Log Out "
						onPress={()=>{
							/*AsyncStorage.setItem('sleepHelperUID', "")
								.then((res) => console.log("saved uid, result: " + res), (err) => console.log(err));
							setUdata(null);*/
							setLogoutPw("Enter Password?");
							setShowingLogOut(true);
						}}
					/>}
					{!showingLogOut ? null :
					<View>
						<Text>Are you sure you want to log out of Faster Asleep, and delete all data from your device? You will need to confirm your email to log in again.</Text>
						<View style={sty.createBtn}>
							<Button 
								color='#484766'
								title=" No, Cancel "
								onPress={()=>{
									setShowingLogOut(false);
								}}
							/>
						</View>
						<TextInput
							style={sty.inputText}
							autoCorrect={false}
							autoCapitalize="none"
							value={logoutPw}
							onChangeText={(txt) => setLogoutPw(txt)}
						/>	
						{(logoutPw != LOGOUT_PW) ? null :
						<View style={sty.createBtn}>						
							<Button 
								color='#484766'
								title=" Yes, Log Out "
								style={sty.createBtn}
								onPress={()=>{
									setShowingLogOut(false);
									AsyncStorage.setItem('sleepHelperUID', "")
									.then((res) => { 
										console.log("saved uid, result: " + res) ;
										setUdata(null);
									}, (err) => console.log(err));									
								}}
							/>
						</View>
						}
					</View>
					}
				</View>
				}
				{!userid || true ? null : 
				<View>
					<View style={sty.spacer}></View>
					<Button 
						title=" Intake Form "
						onPress={()=>{
							props.navigation.navigate("Intake");
						}}
					/>
					<View style={sty.spacer}></View>
					<Button 
						title=" Device Setup "
						onPress={()=>{
							props.navigation.navigate("Devices");
						}}
					/>
				</View>
				}
			</View>
			{/*<NumericCodeEntry 
				title="Enter a secret code:"
				onSubmit={(code) => {console.log("got the code! " + code); console.log("code equals secret number? " + (code == "12345")); }}
				digits={5}/>*/}
		</View>
		</ScrollView>
	);
}

const sty = StyleSheet.create({
  scrollCont: {
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'	  
  },
  container: {
	alignItems: 'center',
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'
  },
  innerContainer: {
	  width: 270,
	  marginTop: 10,
	  marginBottom: 50
  },
  headerText: {
	fontSize: 42,
	margin: 8,
	color: "#323180"
  },
  subheaderText: {
	fontSize: 24,
	marginBottom: 16,
	color: "#55548c"
  },
  emailLabel: {
	fontSize: 20,
	textAlign: 'center',
	color: "#55548c"
  },
  inputText: {
	fontSize: 20,
	borderWidth: 1,
	borderColor: "#c8c8ea",
	marginVertical: 8,
	padding: 4,
	color: '#323180',
	textAlign: 'center'
  },
  createLabelContainer: {
	 marginTop: 12
  },
  createLabel: {
	fontSize: 16,
	textAlign: 'center',
	color: "#000040"
  },
  createBtns: {	 
	margin: 12
  },
  createBtn: {
	  margin: 8
  },
  authSteppers: {
	  flexDirection: 'row',
	  borderWidth: 0,
	  alignItems: 'center',
	  justifyContent: 'space-around'
  },
  stepper: {
	  width: 50
  },
  detailText: {
	  marginVertical: 12,
	  color: "#aaa",
	  fontSize: 12
  },
  
  errorText: {
	  color: 'white',
	  backgroundColor: 'red',
	  padding: 8,
	  fontSize: 20
  },
  
  spacer: {
	  height: 1,
	  width: 1,
	  margin: 4
  }
});

