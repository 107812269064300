//import Constants from 'expo-constants';
//import * as Notifications from 'expo-notifications';

// Most of these functions are copied from the backend skill code. If you find bugs, check both places!
const Utils = {

    
    // return the average of what calcSleepMetrics returns, over the given array of logs
    calcSleepMetricAverages: function(sleeplogsArr) {
        if(!sleeplogsArr || !sleeplogsArr.length)
            return null;
        let metrics = [];
        let sleepMin = -1;
        let sleepMax = -1;
        for(let i=0; i<sleeplogsArr.length; i++) {
            const metric = this.calcSleepMetrics(sleeplogsArr[i]);
            metrics[metrics.length] = metric;
            if(sleepMin < 0 || sleepMin > metric.timeAsleep) sleepMin = metric.timeAsleep;
            if(sleepMax < 0 || sleepMax < metric.timeAsleep) sleepMax = metric.timeAsleep;
        }
        
        console.log("SLEEP LOG SOL METRICS:");
        let count = 0;
        console.log("SOL="+metrics.reduce((sum, m) => { 
            console.log(`c=${count}, sum=${JSON.stringify(sum)}, m=${JSON.stringify(m)}`);
            count++;
            return sum + parseInt(m.sol, 10); 
        }, 0));
        // return an object with the same fields as calcSleepMetrics, but averaged out
        // (NOTE: Added min and max for Day 8 calculations which use them)
        return {
            timeEnterBed: this.avgTimes(metrics.map(m => m.timeEnterBed)),
            timeExitBed: this.avgTimes(metrics.map(m => m.timeExitBed)),
            timeLightsOut: this.avgTimes(metrics.map(m => m.timeLightsOut)),
            timeFallAsleep: this.avgTimes(metrics.map(m => m.timeFallAsleep)),
            timeWakeUp: this.avgTimes(metrics.map(m => m.timeWakeUp)),
            midsleepTime: this.avgTimes(metrics.map(m => m.midsleepTime)),

            sol: metrics.reduce((sum, m) => sum + m.sol, 0) / metrics.length,
            tib: metrics.reduce((sum, m) => sum + m.tib, 0) / metrics.length,
            timeAsleep: metrics.reduce((sum, m) => sum + m.timeAsleep, 0) / metrics.length,
            waso: metrics.reduce((sum, m) => sum + m.waso, 0) / metrics.length,
            wasoCount: metrics.reduce((sum, m) => sum + m.wasoCount, 0) / metrics.length,
            
            timeAsleepMin: sleepMin,
            timeAsleepMax: sleepMax,
        };
    },
    
    // return an object with many stats calculated from a sleep log: TIB, Time Asleep, SE, WASO, etc
    calcSleepMetrics: function(sleeplog) {
        
        const sd = sleeplog.seqData;
        const sol = parseFloat(sd.sleepOnsetLatency);// this.parseDuration(sd.sleepOnsetLatency);
        const lightsOut = this.parseTime(sd.timeLightsOut);
        
        const tEnterBed =  this.parseTime(sd.timeInBed);
        const tExitBed =  this.parseTime(sd.timeOutOfBed);
        
        const sleepStartTime = this.offsetTime(lightsOut, 0, sol);
        
        const wakeUpTime = this.parseTime(sd.wakeTime);
        const nightAwakeCount = parseInt(sd.timesAwake, 10);
        const nightAwake = nightAwakeCount > 0 ? parseFloat(sd.timeAwakeAtNight) : 0;
        
        // calculate difference between sleep start and end
        let durHr = wakeUpTime.hr;
        if(sleepStartTime.hr > wakeUpTime.hr)
            durHr += (24 - sleepStartTime.hr);
        else
            durHr -= sleepStartTime.hr;
        //durHr -= nightAwake.hr; //nightAwake is now a scalar
        
        // calculate total TIB
        let tibHr = tExitBed.hr;
        if(tEnterBed.hr > tExitBed.hr)
            tibHr += (24 - tEnterBed.hr);
        else
            tibHr -= tEnterBed.hr;

        let ret = {}; 

        ret.timeEnterBed = tEnterBed;
        ret.timeExitBed = tExitBed;
        ret.timeLightsOut = lightsOut;
        ret.sol = sol;
        
        ret.timeFallAsleep = sleepStartTime;
        ret.timeWakeUp = wakeUpTime;
        ret.waso = nightAwake;
        ret.wasoCount = nightAwakeCount;
        
        ret.midsleepTime = this.avgTimes([sleepStartTime, wakeUpTime]);
        ret.timeAsleep = 60 * durHr + wakeUpTime.min - sleepStartTime.min - nightAwake;
        ret.tib = 60 * tibHr + tExitBed.min - tEnterBed.min;
        
        return ret;
    },
	
	addTimes: function(t1, t2) {
        let hr = t1.hr + t2.hr;
        let min = t1.min + t2.min;
        hr += Math.floor(min / 60);
        min %= 60;
        hr %= 24;
        return {hr, min};
    },
    
    offsetTime: function(t, offsetHrs, offsetMins=0) {
        let hr = t.hr + offsetHrs;
        let min = t.min + offsetMins;
        while(min < 0) {
            hr -= 1;
            min += 60;
        }
        while(hr < 0) {
            hr += 24;
        }
        hr += Math.floor(min / 60);
        min %= 60;
        hr %= 24;
        return {hr, min}; 
    },
	
    avgTimes: function(timeArr) {
        if(!timeArr || !timeArr.length)
            return null;
        let x = 0;
        let y = 0;
        for(let i=0; i<timeArr.length; i++) {
            const timeAng = Math.PI * 2 * (timeArr[i].hr + (timeArr[i].min / 60.0)) / 24.0; // convert time to angle (radians)
            x += Math.cos(timeAng);
            y += Math.sin(timeAng);
        }
        x /= timeArr.length; // get avg of cos and sin
        y /= timeArr.length;
        const avgAng = Math.atan2(y, x); // this is the average angle, just need to convert back into a time.
        let avg = avgAng / (Math.PI * 2); // map to 0-1
        avg = avg * 24; // map to 24 hrs
        if(avg < 0) avg += 24; // handle "negative" time by wrapping around the clock
        const hr = Math.floor(avg);
        const min = Math.floor((avg - hr) * 60);
        return {hr, min};
    },
	
    timeObjToString: function(objHrMin, use24Hr) {
        if(!objHrMin)
            return null;
        let hr = objHrMin.hr;
        let min = objHrMin.min;
        if(hr === undefined && min === undefined) {
            const isNum = typeof objHrMin === 'number' && isFinite(objHrMin);
            console.log("WARNING: timeObjToString did not receive object with hr and min. Received isNum="+isNum+", obj="+JSON.stringify(objHrMin));
            if(!isNum)
                return null;
            hr = Math.floor(objHrMin / 60);
            min = Math.floor(objHrMin % 60);
        }
        return this.timeToString(hr, min, use24Hr);
    },
    
    timeToString: function(hour, min, use24Hr) {
        const hr = use24Hr ? (hour) : (((hour + 23) % 12) + 1); //eg. 23 = 11, 0 = 12
        const minStr = (min < 10 ? "0" : "") + min;
        return hr + ":" + minStr + (use24Hr ? "" : (hour >= 12 ? " P.M." : " A.M."));
    },

    
    minsToSpeech: function(mins)
    {
        if(!mins && mins !== 0) return "unknown amount of time";
        mins = Math.floor(mins); 
        
        if(mins > 60 * 24 * 5) return "over " + (Math.floor(mins/(60))) + " days";
        if(mins < 0) return "error" ;
        if(mins == 0) return "0 minutes" ;
        if(mins < 1) return "less than a minute";
        if(mins < 60) return mins + " minutes";
        if(mins == 60) return "1 hour";
        if(mins % 60 == 0) return (mins / 60) + " hours";
        let hours = 0;
        while(mins >= 60){
            hours++;
            mins -= 60;
        }
        return `${hours} hour${hours==1?'':'s'}, ${mins} minute${mins==1?'':'s'}`;
    },    


	// todo: double-check how this is used. i think the interpretAsMorning param should be removed, because all times should be precisely defined as 00:00 to 23:59
	parseTime: function(str, interpretAsMorning) {
		const colon = str.indexOf(":");
		const hrStr = colon < 0 ? str : str.substring(0,colon);
		const minStr = colon < 0 ? "0" : str.substring(colon + 1);
		let hr = parseInt(hrStr, 10);
		const min = parseInt(minStr, 10);
		if(interpretAsMorning === true) {
			if(hr <= 2) hr += 12; // eg. 1:00 interpreted as 13:00
		} else if(interpretAsMorning === false) {
			if(hr >= 7 && hr <= 12) hr = (hr + 12) % 12; // eg. 10:00 interpreted as 22:00, 12:00 as 0:00
		}
		return {hr, min};
    },
	
	// (NOTE: I don't think anything uses this anymore, since we stopped using amazon's duration format.) return an object of {hr,min} from the given string in ISO 8601. eg P1Y2M10DT2H30M, though we'll only check for hours and mins, eg PT1H15M
    /*parseDuration: function(str) {
        try {
            if(str[0] != "P") console.log("No P in duration string, this might be an error. str=" + str);
            const ixT = str.indexOf("T");
            const ixH = str.indexOf("H");
            const ixM = str.indexOf("M");
            
            const hr = ixH < 0 ? 0 : parseInt(str.substring(ixT + 1, ixH), 10);
            const min = ixM < 0 ? 0 : parseInt(str.substring((ixH < 0 ? ixT : ixH) + 1, ixM), 10);
            return {hr, min};
        
        } catch (e) { console.log("parseDuration failed for string="+str); }
        return {hr:0, min:0};
    },*/
	

};

export default Utils;