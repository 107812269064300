// Total rewrite of dots, much simpler, more responsive
import React, { Component, useRef, useState } from 'react';
import { View, ScrollView, Platform, StyleSheet } from 'react-native';


const DEFAULT_PASSIVE_DOT_WIDTH = 10;
const DEFAULT_ACTIVE_DOT_WIDTH = 15;


export function DotsDisplay(props) {

	const length = props.length || 1;
	const active = props.active || 0;
	
	const activeDotWidth = props.activeDotWidth || DEFAULT_ACTIVE_DOT_WIDTH;
	const activeDotHeight = props.activeDotHeight || activeDotWidth;
	const passiveDotWidth = props.passiveDotWidth || DEFAULT_PASSIVE_DOT_WIDTH;
	const passiveDotHeight = props.passiveDotHeight || passiveDotWidth;
	const passiveColor = props.passiveColor || '#CCCCCC';
	const activeColor = props.activeColor || '#016bd8';
	const activeBorder = props.activeBorder || false;
	const activeBorderWidth = props.activeBorderWidth || 3;
	const activeBorderColor = props.activeBorderColor || '#FFF';
	
	let mode = props.activeMode || "single";
	if(mode != "right" && mode != "left" && mode != "single")
		mode = "single"

    const list = [...Array(length).keys()];
	
	
	
	function dotStyle(isActive) {

		const width = isActive ? activeDotWidth : passiveDotWidth;
		let height = width;
		if (isActive) height = activeDotHeight;
		else height = passiveDotHeight;

		let style = {
			width,
			height,
			backgroundColor: isActive ? activeColor : passiveColor,
			borderRadius: width,
		};
				
		// active Border Styles.
		if (activeBorder && isActive) {
			style.borderWidth = activeBorderWidth;
			style.borderColor = activeBorderColor;
		}

		return style;
	}		
	
	return (
		<View style={[sty.container,{height: Math.max(passiveDotHeight, activeDotHeight)}]}>
			{list.map(i => {
				return (
				  <View
					key={i}
					style={dotStyle( mode == "single" ? (active === i) : (mode == "left" ? (active >= i) : (active <= i)) )}
				  />
				);
			  })}		
		</View>
	);
}

const sty = StyleSheet.create({
  container: {
	flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'center'
  }
});