import React, { useState } from 'react';
import { StyleSheet, Text, View, Button, ScrollView, TouchableOpacity } from 'react-native';


const CHR_a = "a".charCodeAt(0);

export function EducationScreen(props) {
	const [parsedText, setParsedText] = useState([]);
	const [sectionState, setSectionState] = useState([]);
	
	const useWidthLimit = props && props.limitWidth === true;
	const trackingFunc = props.tracker || ((name, amt) => { });

	if(parsedText.length == 0) {
		const pt = getParsedText();
		setParsedText(pt);
		let ss = [];
		for(let i=0; i<pt.length; i++) {
			ss[i] = {show: true, showSub: []};
			for(let j=0; j<(!pt[i] ? 0 : pt[i].lines.length); j++)
				ss[i].showSub[j] = false;
		}
		setSectionState(ss);
		return null;
	}
	
	function renderEdu() {
		return parsedText.map((item, index) => 			
			<View key={index} style={sty.header0box}>
				<View style={sty.header0textbox}>
					<Text style={sty.header0text} onPress={() => trackingFunc("click",1)} >
						{index+1}) {!item ? "" : item.header}
					</Text>
				</View>
				
				{!sectionState[index].show ? null :
				<View>
				{!item ? null : item.lines.map((jtem, jndex) =>
					<View style={sty.header1box} key={jndex}>
						<TouchableOpacity style={sty.header1textbox} 
							onPress={() => { 
								sectionState[index].showSub[jndex] = !sectionState[index].showSub[jndex]; 
								setSectionState([...sectionState]); 
								trackingFunc(sectionState[index].showSub[jndex] ? "openSect" : "click",1);
							}}
						>
							<Text style={sectionState[index].showSub[jndex] ? sty.header1text : [sty.underline, sty.header1text]} >
								{String.fromCharCode(jndex+CHR_a)}. {!jtem ? "" : jtem.header}
							</Text>
						</TouchableOpacity>
						
						{!sectionState[index].showSub[jndex] ? null :
						<View>
						{!jtem ? null : jtem.lines.map((ktem, kndex) =>
							<Text key={kndex} style={sty.eduText} onPress={() => trackingFunc("click",1)} onLongPress={() => trackingFunc("click",1)}>{ktem}</Text>
						)}
						</View>
						}
					</View>
				)}
				</View>
				}
			</View>);
	}

	return (
	<ScrollView style={sty.scrollCont} onScroll={() => trackingFunc("scroll",1)} scrollEventThrottle={32}>
		<View style={useWidthLimit ? sty.containerLimit : sty.container}>
			<Text style={sty.titleText}>Insomnia Education</Text>			
			{renderEdu()}
		</View>
	</ScrollView>
	);
}


const sty = StyleSheet.create({
  scrollCont: {
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'	  
  },	
  container: {
	alignItems: 'center',
	width: '100%',
	backgroundColor: '#FFFFFF'
  },	
  containerLimit: {
	alignItems: 'center',
	width: 800,
	maxWidth: "95%",
	margin: "auto",
	backgroundColor: '#FFFFFF'
  },
  titleText: {
    fontSize: 32,
    color: '#102452',
    lineHeight: 50,
    textAlign: 'center',
	margin: 8
  },
  underline: {
	  textDecorationLine: 'underline'
  },
  header0box: {
	  width: '100%',
	  paddingLeft: 10,
	  paddingRight: 10,
	  borderWidth: 0,
	  borderColor: '#aaa'
  },
  header0textbox: {
	  backgroundColor: '#ffffff',
	  marginBottom: 0
  },
  header0text: {
	  color: '#102452',
	  fontSize: 20,
	  margin: 0
  },
  header1box: {
	  padding: 10,
	  marginLeft: 10,
	  borderWidth: 0,
	  borderColor: '#aaa'
  },
  header1textbox: {
	  backgroundColor: '#f8f8f8'
  },
  header1text: {
	  color: '#4b5d87',
	  fontSize: 18,
	  margin: 5
  },
  eduText: {
	  color: '#333',
	  fontSize: 16,
	  margin: 5,	 
	  padding: 5,
	  borderWidth: 0,
	  borderColor: '#aaa'
  }
});

function getParsedText() {
	const lines = EDU_TEXT.split(/\r?\n/);
	let ret = []; // each array element should be an object like {header: <str>, lines: [...]}, where each element in lines is the same (recurse until no headers, 2 levels deep)
	let curObj = null;
	let curSubObj = null;
	for(let i=0; i<lines.length; i++) {
		const line = lines[i].trim();
		
		if(line.length > 2 && (line[1] == ")" || line[2] == ")")) { // test for top-level header
			const pi = line.indexOf(")");
			const index = parseInt(line.substring(0, pi).trim(), 10);
			const header = line.substring(pi+1, line.length).trim();
			
			// console.log("Switching to next header " + header);
			// console.log(JSON.stringify(curObj));
			// console.log(JSON.stringify(curSubObj));

			curObj = { header, lines: [] };
			ret[index - 1] = curObj;
			curSubObj = null;
		}
		else if(line.length > 1 && line[1] == "." && line[0].toLowerCase() >= "a" && line[0].toLowerCase() <= "z") { // test for sub header

			const index = line[0].toLowerCase().charCodeAt(0) - CHR_a;
			const header = line.substring(2, line.length).trim();
			
			// console.log("Switching to next subheader " + header);
			// console.log(JSON.stringify(curObj));
			// console.log(JSON.stringify(curSubObj));

			curSubObj = { header, lines: [] };
			if(curObj) curObj.lines[index] = curSubObj;
		}
		else if(curSubObj) {
			curSubObj.lines[curSubObj.lines.length] = line;
		}
	}

	
	for(let i=0; i<ret.length; i++) {
		for(let j=0; j<ret[i].lines.length; j++) {
			const l = ret[i].lines[j];
			while(l && l.lines.length > 0 && l.lines[l.lines.length - 1].length <= 0)
				l.lines.length = l.lines.length - 1;
			for(let k=0; k<l.lines.length; k++) {
				console.log("proc line="+l.lines[k]);
				// add the commented portion to only remove double blank lines, instead of all
				if(l.lines[k].length == 0) { // && k < l.lines.length - 1 && l.lines[k+1].length == 0) { 
					l.lines.splice(k,1);
					k--;
					console.log("removed");
				}
			}
		}
	}
	
	return ret;
}



const EDU_TEXT = `

1)    Insomnia overview

a.  What is insomnia? 
Insomnia is a very common sleep disorder among adults in the United States. It is characterized by the difficulty someone has falling asleep, or staying asleep, and the feeling that their sleep was non-restorative. Insomnia is associated with impairment in daytime function, lowered sense of well being, or excessive daytime worry about sleep. Often, patients perceive their sleep to be of poor quality, leaving them feeling tired the next day. Consequently, this sleep will impair daily function, with issues such as tiredness, difficulty concentrating, and mood problems like feeling depressed, or irritable.

b. Prevalence of insomnia in the general population.
Insomnia is quite a common issue in women. Approximately one in three women experience some symptoms of insomnia. This includes having trouble falling asleep or staying asleep, or feeling like the sleep that they are getting at night is not of good enough quality to feel rested the next morning. To give you a sense of just how common insomnia is, some estimates suggest that more than half of adults have experienced at least brief bouts of insomnia at some point in their life. This means that you are not alone in your journey with insomnia!

c. Prevalence of insomnia in breast cancer survivors. 
Insomnia is common in adults, including those without a history of breast cancer. Having had breast cancer puts women at even higher risk because of the many physical and psychological challenges that you have faced. For example, feeling stressed at diagnosis, or struggling to sleep while you were recovering from surgery in the hospital, or the hot flashes that might have developed during hormonal therapy are all possible triggers to developing insomnia. Estimates suggest that between one third to half of breast cancer survivors report insomnia.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               

2)    Misconceptions about insomnia

a. Insufficient sleep
A common worry for those struggling with insomnia is that they are not getting enough sleep. While this may be true, it is important to remember a few things. First, everyone is different in terms of how much sleep they need.

If you are younger than 65 years old: For adults such as yourself, the National Sleep Foundation suggests that anywhere from 6 to 9 hours of total sleep may be needed. It is most common to need between 7 to 9 hours of total sleep, but it may be perfectly acceptable to get less or more than that.

If you are 65 or older: For adults such as yourself, the National Sleep Foundation suggests that anywhere from 5 to 9 hours of total sleep may be needed. It is most common to need between 7 to 9 hours of total sleep, but it may be perfectly acceptable to get less or more than that.

Second, remember that it is not simply about how much sleep you get, but also whether that sleep is good quality. Quality sleep tends to be consolidated, meaning you do not wake up for extended periods of time, and consistent, meaning that you get the approximately same amount of sleep at around the same time of night, most days of the week.

b. Other sleep disorders
Other than insomnia, there are a number of different sleep disorders that people struggle with. A common misconception is that treating these other sleep disorders simultaneously treats insomnia. For example, many adults who start treating their sleep apnea using a CPAP are surprised when their insomnia is still a problem for them. If you are not familiar with sleep apnea, it is a common sleep disorder among adults. It describes a situation in which breathing repeatedly stops and starts. This can be a dangerous condition unless treated. Signs of sleep apnea include loud snoring or gasping for air during sleep, and should be discussed with your physician. Restless legs syndrome is another type of sleep disorder, where individuals have an uncomfortable sensation in their legs and an urge to move their legs while trying to fall asleep. Again, successfully treating restless leg syndrome can improve your overall sleep quality, but does not necessarily treat insomnia.

c. “Cures itself”
A common misconception is that chronic or persistent insomnia will simply cure itself over time. Unfortunately, researchers have demonstrated this is not typically the case. Insomnia severity varies over time, sometimes getting better and sometimes getting worse. This is why it is so valuable that you are choosing to do the work needed to improve your insomnia. Keep it up and the benefits of your hard work will pay off in the coming months, as well as in the future.
3)    Consequences of insomnia

a. Physical health consequences
Getting consistent and sufficient sleep is essential for good health. During sleep, our bodies and brains repair themselves. Some research suggests our brains use the time during sleep to clear away toxins that build up during the day. Insomnia can lead to greater risk of diabetes, heart disease, and stroke, and affects your immune system health. 

b. Psychological health consequences
Insomnia can contribute to worsening mood, such as depression and anxiety. Overall, it has been shown to lower your quality of life. What is unique about insomnia and mood health is that the relationship is bidirectional. Research has demonstrated that if you successfully improve your insomnia, your mental health improves as well.

c. Cancer-specific consequences
Insomnia can make cancer-related symptoms of pain, fatigue, depression, or anxiety much harder to cope with. It may also cause feelings of isolation, which can be difficult for cancer survivors.

4)    Treatment options

a. Cognitive Behavioral Therapy for Insomnia
Cognitive Behavioral Therapy for Insomnia is the first choice for insomnia treatment. Cognitive Behavioral Therapy for Insomnia involves different components: sleep restriction, stimulus control, sleep education, sleep hygiene, and relaxation exercises. Don’t worry about what these terms mean right now- we will go through them together over the next few weeks. Research shows that even if they have had very long term insomnia 70% of people who complete Cognitive Behavioral Therapy for Insomnia will have long lasting sleep improvements.

b. Prescribed medications 
Prescribed medications for insomnia may improve insomnia symptoms in the short-term, but most of them have negative side effects, and don’t work as well if you take them for a long time. Sleep medication can also lead to tolerance and dependence.

c. Over-the-counter medications
Some people use over the counter pills like melatonin to try to fall asleep. Melatonin is a hormone that we naturally produce that is essential in the regulation of the timing of sleep and other essential body functions. Synthetic melatonin supplements have been shown to be useful for people who go to sleep late and wake up late and during travel and adjusting to different time zones. Still, melatonin use should be discussed with your physician, particularly because you have had other health problems and melatonin may not be safe for you.
 
5) Sleep Restriction


a. Sleep pressure/debt
Sleep pressure, or sleep debt, refers to your increasing sense of sleepiness. In essence, the less someone sleeps, the more sleep pressure they feel. And then, the more sleep pressure someone feels, the more likely it is that they will fall asleep. This sleep pressure is an important part of sleep restriction. The more of it that you build up, the more likely it is that your body’s internal clock will reset and allow you to sleep at the right hours.

b. Example of natural sleep debt accumulation
Just think back to when you might have had to get up very early in the morning in the past. Maybe you had to catch an early flight that departed at 6AM. In this scenario, you might have had to wake up at 3:30AM to be able to get to the airport in time. Because you did not get a lot of sleep that night, you were probably dozing off while at your airport gate waiting to board your flight, or may have been barely able to keep your eyes open while waiting for the plane to take off. 

c. Utilizing sleep pressure/debt
When you are in the sleep restriction part of CBT-I, you are accumulating sleep debt. As your body becomes more and more sleepy, because you have more and more sleep debt, you are better able to override the dysfunctional internal clock that you had previously. This internal clock used to make it so that you struggled with either falling asleep, or waking up in the middle of the night. With the extra sleep debt, you can then teach your body to fall asleep and stay asleep within a certain window of time. Over a period of about 1 week, your body learns to sleep on a more consolidated schedule that is made possible by your sleep debt.

d. Short vs. long-term sleep
Many patients with insomnia struggle with how they balance their short and long term sleep. People often choose to worry about how they feel in the short term. Like today, or tomorrow. That is why patients with insomnia commonly do things like stay in bed for an extra hour or two, if they have had a bad night of sleep. They are prioritizing their short term well-being by trying to get more sleep that morning. However, by doing this, they start to train their bodies to repeatedly wake up in the middle of the night because their bodies have learned that they will get catch-up sleep later on. In this program, I want to help you understand that it is your long-term sleep that is more important. 
6) Stimulus control


a. Classical conditioning example
Classical conditioning may be a principle that you know of as Pavlovian conditioning. This is because it was discovered by someone named Ivan Pavlov, who was a Russian physiologist. In the original experiment, which is the most famous example of classical conditioning, dogs were taught to salivate when hearing a ringing bell. This was because that ringing bell had been paired with being fed food in the past.
b. Common activities to avoid in bed: 
You should use your bed only for sleep and sex. Avoid making phone calls, browsing the web, watching TV, or working in bed. Doing these activities in bed can increase your alertness and make it harder to fall asleep.


c. Sleep effort
A common activity that is performed in bed is something called sleep effort. This describes a situation where someone is in bed, and tries to fall asleep. This might involve ensuring that they get into just the right position, or have the white noise machine set at just the right level, or are using a special pillow. In all of these instances, the person is putting a lot of effort, and pressure, on being able to sleep. This added pressure only makes sleep more difficult, which is what you are trying to avoid by practicing stimulus control.

d. Places to go
Ideally, you are able to leave the bedroom and go to another room entirely when you are not able to sleep. This could be the living room, a den, the basement. Pretty much wherever you want. However, this may not be possible for several reasons. For example, if you are someone who is at greater risk for significant injury from falls, you would want to minimize how far you walk when you are drowsy in the middle of the night. Or if you are someone who is in a considerable amount of pain, too much movement may make it difficult to sleep because it exacerbates your pain. In these situations, rather than leaving the bedroom entirely, see if you can potentially move to another location in your bedroom. If you have a chair in your bedroom, that would work just fine. If you think that you simply cannot get out of bed, then try to adjust your position so that you are not laying down. Perhaps you could sit up on the other side of the bed, for example. Feel free to be creative to try to teach your body not to associate your bed as a place for not sleeping.


 7) Sleep Hygiene 

a. No clock watching
DO NOT look at the clock after you turn off the lights and try to fall asleep. Don’t look at it until your alarm goes off in the morning!  Clock watching is likely to increase your frustration about not sleeping. For most people it is a powerful habit and potent sleep disrupter!  Learn to trust your alarm clock! Remind yourself that it is ok to wake up at night.  If you start feeling frustrated or wide awake, you can get out of bed, sit on the edge of the bed, or try a brief relaxation exercise. For example, try taking a few deep breaths and do a quick body scan.

b. Avoiding (or reducing) electronics usage in the evening and overnight
Do you use your phone, tablet, or laptop in bed or have a TV in your bedroom?  These devices can affect both your ability to fall asleep and your quality of sleep. The light emitted from electronics can make you more alert and can  cue your circadian system that it is not yet time to fall asleep.  Light from devices can actually change the release of important hormones that affect not just your sleep, but other body systems. Start by putting down your electronics 30 minutes before you go to sleep. You can try reading a book or listening to a podcast instead.

c. Caffeine/alcohol/nicotine/marijuana

Caffeine has a long half life, meaning it stays in your system for a long time - between 8 and 12 hours!  If you do have an all day caffeine habit, it is important to break it. But those who are very caffeinated in the late afternoon and early evening may want to slowly decrease the amount, and then discontinue use, rather than go cold turkey. This is because there are withdrawal effects that could make you feel irritable, or have a headache, and could even disrupt your sleep for the first week. Work on trying to eliminate caffeine after 2pm over the next few days, cutting down on the amount you are consuming one day at a time.

Using substances, such as alcohol or cannabis, as a sleep aid is common among cancer survivors. I will start by talking about alcohol use. It is used by some as a sedating substance, to help that person fall asleep. It is important to know that at higher doses it can disrupt your ability to stay asleep, because it increases activation and can disrupt different stages of sleep, decreasing overall sleep quality. Also, alcohol can potentially worsen snoring and sleep apnea.

When it comes to cannabis and sleep, there is far less research than alcohol and sleep. Cannabis may be helpful in bringing about sleep, but it does have side effects to consider. Some common ones are next-day grogginess, dry mouth, and increased appetite after ingestion. If you use cannabis for an extended period of time to help you sleep, there may be both physiological withdrawal symptoms, such as trouble falling asleep, vivid dreams, and psychological withdrawal symptoms such as changes to your mood. One common concern is the preference for cancer survivors not to have to depend on a substance to help them sleep. During this insomnia program, you should try to improve your sleep without any substances if this is possible, unless you have been otherwise advised by your medical team. That way, you can see just how good your sleep can get, without the need for any medications or substances.

d. Physical activity
Maintaining some kind of exercise routine supports your sleep, and is important for your overall health. Particularly when first starting an exercise routine, it is very common to come up with lots of reasons or excuses to skip it.  However, I can guarantee that after you exercise you will feel better, and thank yourself.  Once you are in the habit of exercising every day, or at least three days a week, it can become a positive addiction, and you will have thoughts about how you are going to fit it in. Remember that exercise is not only good for your sleep and general health, but it improves your focus, and quality of life.  So even if you feel like you do not have time, when you fit it in, it is likely that you will enjoy your activities, and actually be more productive.  Set reasonable goals for yourself such as starting out with a 10-minute brisk walk, or a walk up and down the stairs on a work break, and then gradually increase the duration of your walk, run, or bike ride. If you need motivation, try finding a friend to walk with or listen to a podcast you like as you walk.

Vigorous exercise during the hour before sleep is not recommended.

e. Creating an optimal bedroom environment
A comfortable bedroom is a very important part of improving your sleep!  Here are some problems areas and ideas to resolve them:
Too much light or too little light can be a problem, but is a personal preference (some people like absolute dark and others like dim light).  It is important to sleep in an environment without too much light.  Try black out shades on the window side of your current window treatments. The cheap roll up types are usually fine if you don’t want to invest in permanent shades.

Cover any lights from electronics or your alarm with a piece of fabric or a little bit of electrical tape.

Have a dim light that is easy to reach if you need to get up in the middle of the night to go to the bathroom or do your stimulus control.

If you and your partner like different amounts of light, perhaps negotiate a short-term change to help you during this active treatment period or one of you could wear an eye mask to sleep.

Too much noise is a sleep disruptor and a frequent complaint, however, some people prefer to sleep with some quiet music in the background, noise or a relaxation loop (for example, running water, wind, quiet forest sounds).

Avoid using televisions and radios as your sound source as most stations have a lot of built in changes in brightness or volume that can result in poorer sleep quality and awakenings. If you like to watch TV in bed, try cutting out this habit and watching in another place in your home.  If having the TV on is the only way to keep your mind quiet, then try listening to music, an audiobook or a podcast. The light from a television can also impact your circadian clock.

Ear plugs are fine unless you consider them a safety hazard.

Use white noise or nature sounds to help fall asleep.

If your partner snores they may want to consider participating in a sleep study to rule out a danger to their health, but in the short-term while you are actively working on your sleep, you may want to consider sleeping in separate bedrooms.

Mattress and pillow comfort is also very important.  A good comfortable pillow is a good and relatively inexpensive investment.  If you haven’t  budgeted for a new mattress and planned on getting one, there are a few options. If you want a firmer mattress you may want to put a piece of plywood between your mattress and box spring.   If your mattress is too firm or too lumpy, you may want to consider buying a mattress topper that is washable. You may also want to do some research on materials in mattresses.

f. Meal timing
An important element of maintaining a well-regulated circadian clock depends on when you have your meals. The more regular your daily meals are, the better! Many individuals who struggle with poor sleep are more likely to eat unhealthy foods later in the evening, and perhaps even during the overnight period. This can lead to greater weight gain. Do your best to avoid late night snacks that aren’t good for you, and try to eat a healthy breakfast to start your day off right.

g. Light exposure in morning
The sun is the most important cue for our circadian clock, informing our body when we should be asleep and when we should be awake. You can take advantage of this information to help you sleep better at night and feel better during the day. First, it is recommended that you get exposure to bright light in the morning, as soon as possible after waking. This helps teach your body that it is the right time to be awake. Sunlight is best, so try and get outdoors. If that isn’t possible, consider going into the brightest room in your home to have your breakfast. Make sure that you are facing a window and sitting close to that window. If the sun has not yet risen by the time you wake up, consider using a light box that is at least 10,000 lux.
 
8) Cognitive therapy

a. Common maladaptive cognitions related to sleep
How you think about sleep can change how you feel about your sleep. One of the more common sleep myths is that everyone needs 8 hours of sleep, each and every night. As you have hopefully seen over these past few weeks, this is not true. Some people need less than 8 hours, and some people need more. Often, when someone believes that they need 8 hours of sleep, but only need 7 hours per night in reality, they feel disappointed when they wake up in the morning even if they have gotten enough sleep.

Another common myth is that if you have a bad night of sleep you won’t be able to function the next day. If this is something that you struggle with, I encourage you to collect information to prove or disprove this belief, using yourself as the subject. Try keeping a log where you start by rating your daily function on a scale from 1 to 10 and do this for a period of a week or 10 days. Then look back and see if each bad day was after a poor night of sleep. Or, maybe your bad days are sometimes after a bad night of sleep, and sometimes after a good night of sleep. If you are struggling with assessing how you feel, consider asking a partner, a friend, or a co-worker, to help you assess how you are doing each day.

The goal is to shift some of your assumptions or myths which takes some pressure off you to get great sleep every night.  That pressure can lead to anxiety about your sleep and can worsen your sleep. The new thought can be, “after an occasional bad night I’ll be fine and I’ll catch up on my sleep over the next couple of days.”

People suffering from insomnia often worry about their sleep problems and anticipate bad mornings. These negative thoughts can become associated with sleep, the bedroom, and how you feel in the morning. These thoughts can be disruptive during the day and can be self-fulfilling prophecies at night.  

Let us examine some negative thoughts about sleep, which may be called “maladaptive cognitions.” Take a moment to think about the message that plays on repeat in your mind when you are struggling to sleep at night, or when you feel tired or sleepy during the day because of your poor sleep. See if any of these negative thoughts seem familiar:

“I need to fall asleep faster. I don’t want to spend hours awake again, because I have such a busy day tomorrow, my terrible sleep will turn my day into a nightmare!”

“If I am not able to get back to sleep, I know I am going to feel miserable.”

“I’ve read that not getting enough sleep might make you sick. This is the last thing I need after my cancer.”

I can assure you that these are just thoughts. None are completely true and they can be viewed as habits that you can end. Ask yourself, can I know that this thought is absolutely true?  The answer is no.  A thought is a thought. Remember that you have both bad and good nights of sleep.

b. Cannot function next day
A common worry that those struggling with insomnia report is that they simply cannot function the next day if their sleep was poor the night before. Ask yourself if this is 100% accurate. Think back to the last few nights in which you may have had a terrible night of sleep. Were you unable to do anything the next day? Did you call in sick to work? Were you unable to get out of bed, and spent the entire day laying in bed recuperating? More likely, you were feeling a little under the weather, but you still went about your day…perhaps with a little more coffee than usual. 

Instead of fixating on how you might feel tomorrow, why don’t we try and see if we can do the right things for your sleep in the future. Our goal is to improve your sleep within a few weeks so that you don’t have to be on this rollercoaster of ups and downs with sleep each night.

c. Health consequences
Many individuals with insomnia think that their poor sleep will contribute to health problems. Research has shown that individuals with insomnia may struggle with their mood, experiencing some cognitive challenges such as difficulty with their memory, and physical health effects like feeling more pain and fatigue. This is precisely the reason why it is important to work on your sleep using this program! In the long run, sticking to the pattern of some good nights of sleep followed by some bad nights of sleep that is very typical of insomnia is not what you want. The goal is for consistent sleep to help with your health.

d. Challenging cognitions
All humans have negative and positive thoughts.  Being ruled by negative thoughts that include worry, replaying difficult situations, feeling overwhelmed by negative emotions are signs of depression and anxiety and can interfere with our sleep. Negative thoughts are almost always linked to negative emotions (for example sadness, worry, shame, embarrassment).  Exposure to trauma, including health problems, can be associated with intrusive and challenging cognitions and emotions. 
While some worry is healthy and keeps us on our toes, persistent negative thoughts and the negative emotions associated with them are  a form of suffering.  The good news is that there are lots of techniques that you can learn to regulate negative thoughts.  Regulating rather than stopping is an important goal because when we try to suppress thoughts and the emotions associated with them they can become more powerful.  
Here are some techniques to help regulate negative thoughts:

When you feel swept away by a flow of negative thoughts try naming them and the emotion associated with them.  Repeat the labels you come up with and observe how the thought changes when you label it.  Usually the label helps you isolate the thought and emotion so that it does not carry you off down the stream of uncontrolled suffering.

Challenge the thought.  Ask yourself, is that true?  Can I really know that thought is true?  Tell yourself that you can never be sure if a thought is “true or not”!

Tell yourself, I do not need that thought, it is not important right now, or I am just going to let it go rather allow it to control me or distract my attention.

A key principle that you can return to is that no thought or emotion, however challenging, is constant.  Thoughts and emotions come and go just like waves.  If you wait a bit you will notice that the thought has less sway over you.

The ‘C’ in CBT-I is for cognition. It refers to techniques to regulate negative thoughts and the emotions associated with them.  Negative thoughts are almost always linked to some real event, trauma exposure or life challenge, but we do not have to be carried away and distracted by them.  They tend to have a powerful impact on sleep because it is harder to control thoughts and emotions when they are tired.

 
9) Relaxation exercises

a. Information
Relaxation exercises, also called mindfulness exercises, are techniques to calm your mind, improve attention, and be more present (for example, when you are in a conversation, really listening rather than being ruled by a flow of thoughts and emotions and judgments that interfere with your connection to another person or activity).
Relaxation and mindfulness exercises are called practices because the more we use them (even a couple of minutes a day) the more we are able to use them during challenging times when we are feeling frustrated, angry, defensive, and having difficulty falling asleep in the beginning or middle of the night.
There are many types of mindfulness practices. Everyone can find some practice that is right for them. You may also find that one technique is great during the day when you are about to go into a challenging meeting, and another is very effective at night when you have to let go of thoughts and stress so that you can drift off to sleep
Relaxation and mindfulness are an important part of insomnia interventions because they can help to regulate physical, emotional and/or cognitive arousal and frustration.

b. Accessing Relaxation
Launch Faster Asleep anytime on your Echo device, after completing your Morning Log and daily Education content (if these are incomplete, you must launch Faster Asleep a second time), and you will hear "You're all caught up, which mode would you like to run?" Say "relaxation" to access the exercises. Also, when you complete your Night Log before bed, the system will offer different relaxation exercises.

The first time relaxation is accessed, you will hear an introduction with a short sample of each relaxation type we provide: Guided Imagery, Progressive Muscle Relaxation, Body Scan, and RAIN (Recognize, Allow, Investigate, Nurture). 

After that, you will be offered one of the four types, and asked to say "Yes" to begin that type of relaxation. Saying "No" will prompt the system to offer another type. If you say "No" to all four types, you can listen to the introduction again, but it is suggested to focus on one type of relaxation at a time.

As you experiment with different relaxation scripts, the system will learn your preferences and offer those first. Also, once you listen to a specific type of relaxation it will be offered to you in the standard form (usually 7-8 minutes) or a brief version (around 3 minutes). Each type of relaxation is explained in more detail below.

c. Body Scan
This is an exercise for self-observation, to increase awareness of the body. It helps improve awareness of your body or emotional state. Your attention is directed at various areas of the body and noting body sensations that happen in the present moment.

d. Progressive Muscle Relaxation
This exercise promotes deep muscle relaxation, based on the premise that muscle tension is the body's psychological response to anxiety-provoking thoughts and that muscle relaxation can reduce anxiety. The technique involves first tensing and then relaxing different muscle groups. When this tension is released, your attention is directed towards the differences felt during tension and relaxation, so that you learn to recognize the contrast between them.

e. Guided Imagery
This exercise is designed to evoke imaginative mental images. The goal is to recreate the sensory perception of sights, sounds, tastes, smells, movement, texture, temperature, and experiences that may precipitate strong emotions or feelings. This focus on the interaction between the brain, body, and behavior is practiced with the intention to use the mind to alter physical function and promote overall health and well-being.

f. RAIN (Recognize, Allow, Investigate, Nurture)
This exercise follows four steps which spell the acronym "RAIN". First, Recognize an unpleasant experience or feeling. Then, Allow it to be, without judgment. Investigate the experience or feeling to gain understanding. Finally, Nurture yourself with self-compassion.


10) The science of sleep

a. Homeostatic sleep drive
One of the most important elements of whether or not someone is able to fall asleep and stay asleep is called your homeostatic sleep drive. This describes your body’s increasing need to sleep, which develops over the course of your waking hours. Think of it like your appetite for sleep. For example, when you do not eat food for a longer than normal period of time, you start to become hungry. Similarly, when you have not slept for an extended period of time, your body naturally starts to feel sleepy. During this program, I will teach you how to effectively utilize the sleep debt that your body accumulates while you are not sleeping. This sleep debt is what helps you reset your internal clock, allowing you to fall asleep quicker and stay asleep better.

b. Circadian rhythms 
Your body’s circadian rhythm is a natural, internal clock that repeats roughly every 24 hours. It helps to regulate your sleep-wake cycle, among other natural body events. For example, in addition to sleep, it can also have an impact on body temperature, hunger, and hormone regulation. When your circadian cycle is misaligned, it can make insomnia worse. One of the most important factors that helps your body set its internal clock is the visual cue of light. Light is transmitted through your eyes and into a specific part of your brain that sets that internal clock. This is one of the reasons why everyone is encouraged to get as much light as they can in the morning, and to try and avoid light in the evenings. This includes artificial light, such as electronic devices.

c. Sleep and evolution/ancestral patterns (vigilance before bedtime)
Have you noticed that sometimes, right before your body clock tells you to go to bed, you have a burst of vigilance or a second wind? You might start a new activity, worry about sleep, check the news, or check your favorite social media platform.  This burst of alertness probably played an important role for our ancestors, who needed to be vigilant to threats, because they typically did not have a safe place to sleep. I suggest that you look for that burst of activation, and instead of giving into a new activity or worry, try to continue with your bedtime routine. If you still feel activated, engage in a pleasant and quiet activity, such as listening to a relaxation recording.

d. Nighttime awakenings
There are a few reasons why we wake up at night and most of the awakenings are normal, but are viewed as a problem.  Some brief awakenings occur as we transition between sleep stages or occur during specific stages.  There are 4 stages of sleep that all human beings cycle  through in a very predictable way starting in late infancy and continuing for the rest of our lives.  There are three stages of non rapid eye movement sleep also called non-REM. Non-REM stage 1 is a very light and transitional state that occurs about 1-2% of the sleep period and is the gateway to stages.  We might have some awareness as we drift in and out of stage 1.  Stage 2 non-REM  takes up about  50% of the night.   We still don’t know a lot about non-REM stage 2 and its purpose but it is deeper than stage 1. Non-REM stage 3 is a very deep stage of sleep taking up about 25% of the night and mostly occurring during the first half of the night.  This stage is associated with lots of necessary physical functions.  During the day we develop an appetite for non-REM stage 3 and we go into this deep stage very soon after we fall asleep. If something wakes us  from this deep sleep  stage we can be disoriented.  Another deep stage of sleep is REM sleep which is when we have most of our long dreams. During REM our brains are active, but our bodies are paralyzed which prevents us from acting out our dreams. We might wake from a bad dream or nightmare or may have some awareness of our dreams and feel like we are half asleep and half awake.   Most of our long periods of REM occur in the second half of the night.  We cycle through these stages every 60-100 minutes and at each transition between stages there is typically a very brief awakening. We cycle through the stages and may have very brief awakenings at transitions from stage to stage.  

It is very important to understand that sleep changes a lot throughout the night and some people with insomnia may perceive these changes and sign that something is wrong.  Specifically, the first half of the night, called first sleep, tends to be very deep while the second sleep, occurring in the second half of the night, has more dreams. 

Another type of awakening occurs when we satisfy our appetite for deep sleep and while very inconvenient, we have a decreased sleep drive in the middle of the night.  This can occur 3 to 5 hours after we first fall asleep and can result in a longer awakening.  Some people actually work for an hour or two and then go back to sleep.

 The third category of awakening is more problematic and can be caused by internal problems or external disruptors.  Sleep disorders (snoring, sleep apnea, restless legs syndrome), pain,  anxiety) can either wake us from sleep or make brief awakenings longer.  Someone with classic insomnia often has a normal awakening and then thinks,  ‘oh no! I am awake, what is wrong with me?’.   This upset and frustration can prolong the wakening.  Other causes are environmental noise, a snoring spouse, an active pet and a bright light or noisy bedroom or home environment.

Some people with insomnia cannot tell if they are awake or asleep and feel that they spend much of the night awake or in a twilight state.  This is a special type of insomnia called sleep state misperception or paradoxical insomnia.)


e. Chronotype
One of the most important scientific findings that came late in the last century is that there are clock genes.   These are our circadian regulators.  Having a clock in our genes means that every cell in the body has a biological or circadian rhythm.  An important part of the circadian story for all of us, but particularly people with insomnia is that there is a lot of human variation in the preferred timing of our sleep.  These individual differences are called chronotypes (chrono means time).  A small percentage of people have very strong preferences to go to bed early and wake early (morning chronotype) and a slightly larger percentage prefer to go to bed late and wake up late (evening chronotype).  There are changes across development (young children and the elderly tend to be morning chronotypes and teenagers tend to be evening chronotypes) that have a strong basis in biology.  Most species benefit from having ‘chono-variation’ because it helps the whole herd when some like the evening shift and others like the morning shift.  If the whole herd or village is asleep at the same time, the predators win!

`;