import * as WebBrowser from 'expo-web-browser';
import React, { useState } from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, FlatList, Picker, Switch, TextInput, Button  } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Fontisto } from '@expo/vector-icons'; 
import { CheckBox } from 'react-native-elements';
import { MonoText } from '../components/StyledText';
import { TimePickerCallen } from '../components/TimePickerCallen';
import { MultiCheckBox } from '../components/MultiCheckBox';
import { LikertScale } from '../components/LikertScale';
import { DotsPaging } from '../components/DotsPaging';

export default function MoreScreen(props) {
	
	const [concerns, setConcerns] = useState([false,false,false,false,false]);
	const [struggle, setStruggle] = useState([false,false,false,false,false,false]);
	const [upEarly, setUpEarly] = useState([false,false]);
	const [tobacco, setTobacco] = useState([false,false]);	
	const [hr1, setHr1] = useState(9);
	const [min1, setMin1] = useState(30);
	console.log("m screen:");
	console.log(props);	
  return (
    <View style={styles.container}>
		<ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>        
			<View style={styles.getStartedContainer}>
				<DotsPaging length={5} active={1} marginHorizontal={4}  paddingHorizontal={50} />		
				{/*
				<MultiCheckBox 
					title='What are your primary concerns related to your sleep health? (check all that apply)'
					fieldNames={[
						'Falling asleep',
						'Sleep maintenance (nighttime awakenings)',
						'Early morning awakenings',
						'Poor sleep quality',
						'Impact of sleep on daytime functioning.'
						]}
					fieldValues={concerns}
					onChange={(arr) => { setConcerns(arr); }} 
				 />		 
					*/}	  

				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Sleep Issues:</Text>
				</View>
				
				<MultiCheckBox 
					title='How long have you struggled with your sleep?'
					fieldNames={[
						'< 3 months ',
						'3-6 months',
						'6-12 months',
						'1-2 years',
						'3-5 years',
						'> 5 years'
						]}
					fieldValues={struggle}
					isRadio={true}
					onChange={(arr) => { setStruggle(arr); }} 
				 />
								
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>Approximately how many times do you wake up in the middle of the night?</Text>
					<View style={styles.horzContainer}>
						<TextInput keyboardType="numeric" style={styles.textInputStyle} />	
					</View>
				</View>
								
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>How long are you typically awake for, in total, during the middle of the night?</Text>
					<View style={styles.horzContainer}>
						<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
						<View style={styles.spacerMedium} />
						<Text>Minutes</Text>
					</View>
				</View>
									 
						  
				<MultiCheckBox 
					title='Do you wake up earlier than intended (i.e., before your alarm clock)?'
					fieldNames={[ 'Yes', 'No' ]}
					fieldValues={upEarly}
					isRadio={true}
					onChange={(arr) => { setUpEarly(arr); }} 
				 />						

				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Sleep Schedule:</Text>
				</View>
				{/*
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>What time do you typically start to prepare for bed on weekdays?</Text>
					<TimePickerCallen />
				</View>

				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>What time do you typically start to prepare for bed on weekends?</Text>
					<TimePickerCallen />
				</View>
				*/}
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>What time do you typically go to bed and try to fall asleep on a weekday?</Text>
					<TimePickerCallen resolution={5} dropdown={false} combined={false} />
				</View>
				
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>What time do you typically go to bed and try to fall asleep on a weekend?</Text>
					<TimePickerCallen resolution={5} dropdown={false} combined={false} />
				</View>
								
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>How long does it typically take you to fall asleep during a weekday?</Text>
					<View style={styles.horzContainer}>
						<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
						<View style={styles.spacerMedium} />
						<Text>Minutes</Text>
					</View>
				</View>								
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>How long does it typically take you to fall asleep during a weekend?</Text>
					<View style={styles.horzContainer}>
						<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
						<View style={styles.spacerMedium} />
						<Text>Minutes</Text>
					</View>
				</View>
				
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>What time do you typically wake up and start your day on weekdays? </Text>
					<TimePickerCallen resolution={5} dropdown={false} hour={hr1} minute={min1} onTimeChanged={(hr,min) => { setHr1(hr); setMin1(min); }}/>
					<Text style={[styles.getStartedText, {opacity: 0.4}]}>Debug: {("00"+hr1).slice(-2) + ":" + ("00"+min1).slice(-2)} / {(((hr1+11)%12)+1)+":"+("00"+min1).slice(-2)+(hr1 < 12 ? " AM" : " PM")}</Text>
				</View>
				
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>What time do you typically wake up and start your day on weekends? </Text>
					<TimePickerCallen resolution={5} dropdown={false}/>
				</View>
			
					 
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>Do you take daytime naps?</Text>
					<View style={styles.horzContainer}>
						<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
						<View style={styles.spacerMedium} />
						<Text style={{width: 120}}>Naps</Text>
					</View>
					<View style={styles.horzContainer}>
						<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
						<View style={styles.spacerMedium} />
						<Text style={{width: 120}}>Minutes per nap</Text>
					</View>
				</View>				
								
				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Please indicate if your sleep affects your daytime function in the following areas: </Text>
				</View>
				
				<MultiCheckBox 
					title='Have you ever fallen asleep during focused activities (e.g., during a conversation with someone)?'
					fieldNames={[ 'Yes', 'No' ]}
					isRadio={true} 
				 />	
 				<View style={styles.spacerMedium} />

				<LikertScale title='Fatigue' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected' />
				<View style={styles.spacerMedium} />

				<LikertScale title='Irritability' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected' />
				<View style={styles.spacerMedium} />

				<LikertScale title='Concentration' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected'  />
				<View style={styles.spacerMedium} />

				<LikertScale title='Hyperactivity' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected' />
				<View style={styles.spacerMedium} />

				<LikertScale title='Impulsiveness' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected'  />
				<View style={styles.spacerMedium} />

				<LikertScale title='Mood changes' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected'   />
				<View style={styles.spacerMedium} />

				<LikertScale title='Sleepiness in Car' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected'   />
				<View style={styles.spacerMedium} />

				<LikertScale title='Sleepiness Watching TV' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected' />
				<View style={styles.spacerMedium} />

				<LikertScale title='Sleepiness at Work/School ' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected' />
				<View style={styles.spacerMedium} />

				<LikertScale title='Sleepiness During Other Activities' minDescriptor='Minimally affected' maxDescriptor='Very affected' midDescriptor='Somewhat affected' />
				<View style={styles.spacerMedium} />
				 
				 {/*<MultiCheckBox 
					title='What would you describe as your primary sleep problem?'
					fieldNames={[ 
						'Difficulty falling asleep', 
						'staying asleep', 
						'poor sleep quality', 
						'unrestorative sleep', 
						'Sleep disturbance due to physical discomfort', 
						'sleep disturbance due to mood or anxiety problems', 
						'early morning awakening', 
						'Daytime impact of sleep problems' ]}
				 />	*/}				 
				 
				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Sleep Environment/Behaviors:</Text>
				</View>
				
				<MultiCheckBox 
					title='Which of the following activities do you engage in while in your bed?'
					fieldNames={[ 
						'Eating',
						'Watching TV', 
						'Using Phone', 
						'Using Tablet',
						'Using Laptop',
						'Listening to Music', 
						'Reading', 
						'Doing Work', 
						(<View style={{flexDirection: "row", alignItems: "center", marginLeft: 10}}><Text>Other: </Text><TextInput  style={[styles.textInputStyle, {textAlign:"left"}]} /></View>)
					]}
				 />					 
				<View style={styles.spacerMedium} />
				 				
				<MultiCheckBox 
					title='Who shares a bed with you at night?'
					fieldNames={[ 
						'No one',
						'Partner', 
						'Child', 
						'Pet',
						(<View style={{flexDirection: "row", alignItems: "center", marginLeft: 10}}><Text>Other: </Text><TextInput  style={[styles.textInputStyle, {textAlign:"left"}]} /></View>)
						]}
				 />					 
				<View style={styles.spacerMedium} />
				 				
				<MultiCheckBox 
					title='When you cannot sleep, what do you do?'
					fieldNames={[ 
						'Lie in bed and engage in an activity: look at my phone or other electronic device to check the time, the news, social media, Email.', 
						'Watch TV in bed',
						'Get something to eat',
						'Get something to drink (alcohol; water; juice; hot tea)',
						'Lie in bed and engage in a quiet activity: reading, relaxation/meditation; listen to a podcast/audio book', 
						'Get out of bed and engage in a quiet activity ', 
						'Get out of bed and watch TV',
						'Get out of bed and engage in an activity, work, email, cleaning',
						'Go to sleep somewhere',
						/*(<View style={{flexDirection: "row", alignItems: "center", marginLeft: 10}}><Text>Other: </Text><TextInput  style={[styles.textInputStyle, {textAlign:"left"}]} /></View>)
						 */]}
				 />					 
				<View style={styles.spacerMedium} />
				
				<View style={styles.myContainer}>				
					<View>
						<Text style={styles.getStartedText}>How many days a week do you engage in in physical activity (exercise)?</Text>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>Times per week</Text>
						</View>
					</View>
					<MultiCheckBox 
						title='Do you engage in vigorous activity within two hours of bedtime?'
						fieldNames={[ 'Yes', 'No' ]}
						isRadio={true}
					 />								
				</View>


				
				<LikertScale title='How bright is your bedroom at night?' minDescriptor='Completely Dark' maxDescriptor='Very Bright' />
				<View style={styles.spacerMedium} />
				<LikertScale title='How bright is your bedroom in the morning?' minDescriptor='Completely Dark' maxDescriptor='Very Bright' />
				<View style={styles.spacerMedium} />
				<LikertScale title='How loud is it in your bedroom at night?' minDescriptor='Silent' maxDescriptor='Very Loud' />
				<View style={styles.spacerMedium} />
				<LikertScale title='How comfortable is your mattress?' minDescriptor='Not' maxDescriptor='Very' />
				<View style={styles.spacerMedium} />
			
				
				<MultiCheckBox
					title="Do you consume any of the following things which may impact your sleep?"
					fieldNames={[
						'Alcohol',
						'Tobacco',
						'THC / CBD',
						'Stimulants', 
						'Sedatives / Depressants', 
						'Hallucinogens', 
						(<View style={{flexDirection: "row", alignItems: "center", marginLeft: 10}}><Text>Other: </Text><TextInput  style={[styles.textInputStyle, {textAlign:"left"}]} /></View>)
					]}
				/>			

				
				{/*
				<View style={{opacity:0.35}}>
					<View style={styles.myContainer}>
						<Text style={styles.getStartedText}>How much alcohol do you typically drink?</Text>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>Drinks Before Noon</Text>
						</View>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>Afternoon</Text>
						</View>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>With Dinner</Text>
						</View>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>Within 2 hours of bedtime</Text>
						</View>
					</View>

					<View style={styles.myContainer}>
						<MultiCheckBox 
							title='Do you use tobacco products?'
							fieldNames={[ 'Yes', 'No' ]}
							fieldValues={tobacco}
							isRadio={true}
							onChange={(arr) => { setTobacco(arr); }} 
						 />			
						{( !tobacco[0] ? null :					
						<View>
							<Text style={styles.getStartedText}>Time of final use before bed:</Text>
							<TimePickerCallen resolution={15} dropdown={true}/>
						</View>
						)}
					</View>
					
									
					<MultiCheckBox 
						title='Do you use recreational drugs?'
						fieldNames={[ 
							'Stimulants', 
							'Sedatives / Depressants', 
							'Hallucinogens', 
							(<View style={{flexDirection: "row", alignItems: "center", marginLeft: 10}}><Text>Other: </Text><TextInput  style={[styles.textInputStyle, {textAlign:"left"}]} /></View>)
							 ]}
					 />	
					 
					<View style={styles.myContainer}>
						<Text style={styles.getStartedText}>How much caffeine do you typically drink?</Text>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>Drinks Before Noon</Text>
						</View>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>Afternoon</Text>
						</View>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>With Dinner</Text>
						</View>
						<View style={styles.horzContainer}>
							<TextInput keyboardType="numeric" style={styles.textInputStyle} />						
							<View style={styles.spacerMedium} />
							<Text style={{width: 120}}>Within 2 hours of bedtime</Text>
						</View>
					</View>
				</View>
				*/}
				<View style={styles.spacerLarge} />
				<View style={styles.spacerLarge} />
			</View>
		</ScrollView>
	</View>
  );
}

/*
function MultiCheckBox(props) {

	const dat = [];
	for(let i=0; i<props.fieldNames.length; i++)
		dat[i] = { key: i+"", name: props.fieldNames[i], value: props.fieldValues[i], cb: () => { props.onChange(i); }};

	const renderResult = (item) => {
		return (
			<CheckBox
				title={item.item.name}
				checked={item.item.value}
				onPress={item.item.cb}
				checkedIcon={props.isRadio ? 'dot-circle-o' : 'check-square-o'}
				uncheckedIcon={props.isRadio ? 'circle-o' : 'square-o'}
			/>
		);
	};
	
	return (
		<View style={styles.myContainer}>
			<Text style={styles.getStartedText}>{props.title}</Text>
			<FlatList data={dat} renderItem={renderResult} />
		</View>
	);
}


// ok, combine TimePicker and TimePicker2 to make the ultimate customizable time picker
// expected props:
//  - (req) hour - int from 0 to 23
//  - (req) minute - int from 0 to 59
//	- (req) onTimeChanged(hour,minute) - hour is 0-23, accounting for am/pm, minute is 0-59
//  - (opt) resolution - gap between minutes options, min is 1, max is 60, default is 1
//	- (opt) combined - if true, there is one picker for hours+mins, if false they are separated. default is false
//	- (opt) dialog - if true, android shows dropdown list as modal dialog, doesnt affect web or ios. default is false
function TimePicker3(props) {
	
	
}


function TimePicker1(props) {
	const [hours, setHours] = useState("10");
	const [mins, setMins] = useState("00");
	const [isPM, setIsPM] = useState(true);
	
	return (
		<View style={styles.horzContainer}>
			<Picker
				style={{width: 90, fontSize: 24}}
				mode="dropdown"
				selectedValue={hours}
				onValueChange={(itemValue, itemIndex) => setHours(itemValue)}>
				<Picker.Item label="01" value="01" />
				<Picker.Item label="02" value="02" />
				<Picker.Item label="03" value="03" />
				<Picker.Item label="04" value="04" />
				<Picker.Item label="05" value="05" />
				<Picker.Item label="06" value="06" />
				<Picker.Item label="07" value="07" />
				<Picker.Item label="08" value="08" />
				<Picker.Item label="09" value="09" />
				<Picker.Item label="10" value="10" />
				<Picker.Item label="11" value="11" />
				<Picker.Item label="12" value="12" />
			</Picker>
			<Text style={{fontSize: 24, fontWeight: "bold", paddingBottom: 4}}> : </Text>			
			<Picker
				style={{width: 90, fontSize: 24, textAlign: "right"}}
				mode="dropdown"
				selectedValue={mins}
				onValueChange={(itemValue, itemIndex) => setMins(itemValue)}>
				
				<Picker.Item label="00" value="00" />
				<Picker.Item label="01" value="01" />
				<Picker.Item label="02" value="02" />
				<Picker.Item label="03" value="03" />
				<Picker.Item label="04" value="04" />
				<Picker.Item label="05" value="05" />
				<Picker.Item label="06" value="06" />
				<Picker.Item label="07" value="07" />
				<Picker.Item label="08" value="08" />
				<Picker.Item label="09" value="09" />
				
				<Picker.Item label="10" value="10" />
				<Picker.Item label="11" value="11" />
				<Picker.Item label="12" value="12" />
				<Picker.Item label="13" value="13" />
				<Picker.Item label="14" value="14" />
				<Picker.Item label="15" value="15" />
				<Picker.Item label="16" value="16" />
				<Picker.Item label="17" value="17" />
				<Picker.Item label="18" value="18" />
				<Picker.Item label="19" value="19" />
				
				<Picker.Item label="20" value="20" />
				<Picker.Item label="21" value="21" />
				<Picker.Item label="22" value="22" />
				<Picker.Item label="23" value="23" />
				<Picker.Item label="24" value="24" />
				<Picker.Item label="25" value="25" />
				<Picker.Item label="26" value="26" />
				<Picker.Item label="27" value="27" />
				<Picker.Item label="28" value="28" />
				<Picker.Item label="29" value="29" />
				
				<Picker.Item label="30" value="30" />
				<Picker.Item label="31" value="31" />
				<Picker.Item label="32" value="32" />
				<Picker.Item label="33" value="33" />
				<Picker.Item label="34" value="34" />
				<Picker.Item label="35" value="35" />
				<Picker.Item label="36" value="36" />
				<Picker.Item label="37" value="37" />
				<Picker.Item label="38" value="38" />
				<Picker.Item label="39" value="39" />
				
				<Picker.Item label="40" value="40" />
				<Picker.Item label="41" value="41" />
				<Picker.Item label="42" value="42" />
				<Picker.Item label="43" value="43" />
				<Picker.Item label="44" value="44" />
				<Picker.Item label="45" value="45" />
				<Picker.Item label="46" value="46" />
				<Picker.Item label="47" value="47" />
				<Picker.Item label="48" value="48" />
				<Picker.Item label="49" value="49" />
				
				<Picker.Item label="50" value="50" />
				<Picker.Item label="51" value="51" />
				<Picker.Item label="52" value="52" />
				<Picker.Item label="53" value="53" />
				<Picker.Item label="54" value="54" />
				<Picker.Item label="55" value="55" />
				<Picker.Item label="56" value="56" />
				<Picker.Item label="57" value="57" />
				<Picker.Item label="58" value="58" />
				<Picker.Item label="59" value="59" />
			</Picker>
			<View style={styles.spacerMedium} />
			<View style={{alignItems: "center"}}>
				<Switch
					onValueChange={(value)=>setIsPM(value)}
					value={isPM}
				/>

				<View style={styles.horzContainer}>
					<Text style={isPM ? styles.textOff : styles.textOn}>AM</Text>
					<View style={styles.spacerSmall} />
					<Text style={isPM ? styles.textOn : styles.textOff}>PM</Text>
				</View>
			</View>
			
		</View>
	);
	
}*/

function TimePicker2(props) {
	const [timeStr, setTimeStr] = useState("10:30");
	const [isPM, setIsPM] = useState(true);
	
	const res = ((props.resolution && props.resolution > 0 && props.resolution <= 60) ? props.resolution : 1);
	const allTimes = [];
	for(let i=0; i<12; i++)
		for(let j=0; j<60; j+=res)
			allTimes.push((((i+11)%12)+1) + ":" + ("00" + j).slice(-2));
	
	const modeSetting = (props.dropdown ? "dropdown" : "dialog");
	
	return (
		<View style={styles.horzContainer}>
			<Picker
				style={{width: 110, fontSize: 24}}
				mode={modeSetting}
				selectedValue={timeStr}
				onValueChange={(itemValue, itemIndex) => setTimeStr(itemValue)}>				
				{allTimes.map( (t) => <Picker.Item label={t} value={t} />)}
			</Picker>
			<View style={styles.spacerMedium} />
			<View style={{alignItems: "center"}}>
				<Switch
					onValueChange={(value)=>setIsPM(value)}
					value={isPM}
				/>

				<View style={styles.horzContainer}>
					<Text style={isPM ? styles.textOff : styles.textOn}>AM</Text>
					<View style={styles.spacerSmall} />
					<Text style={isPM ? styles.textOn : styles.textOff}>PM</Text>
				</View>
			</View>
		</View>
	);
	
}


MoreScreen.navigationOptions = {
  header: null,
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingTop: 30,
  },
  myContainer: {
    margin: 30,
	width: "100%"
  },
  horzContainer: {
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center"
  },
  bordered: {
	  borderWidth: 2
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 20,
  }, 
  getStartedText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
	margin: 8
  },
  sectionHeaderText: {
    fontSize: 32,
    color: '#102452',
    lineHeight: 50,
    textAlign: 'center',
  },
  textInputStyle: {
	borderWidth: 1,
	borderColor: "#ccc",
	backgroundColor: "#f2f9fc",
	padding: 5,
	width: 110,
	height: 32,
	marginTop: 5,
	marginBottom: 15,
	textAlign: 'right'
  },
  textOn:{
	  color: "#009688",
	  fontWeight: "bold"
  },
  textOff:{
	  color: "#aaa"
  },
  
  spacerSmall:{
	  width: 10,
	  height: 10,
  },
  spacerMedium:{
	  width: 20,
	  height: 20,
  },
  spacerLarge:{
	  width: 60,
	  height: 60,
  },
  
});
