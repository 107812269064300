import React, { useState } from 'react';
import { StyleSheet, Text, View, TextInput, Button, Keyboard, ScrollView  } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// adapted from https://stackoverflow.com/questions/4434076/best-way-to-alphanumeric-check-in-javascript
function isAlphaNumeric(str) {
  for (let i = 0, len = str.length, code; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123))  // lower alpha (a-z)
			return false;    
  }
  return true;
};

/*
	TO DO:
	- fetch for sign-in (user, pass) => {success:true/false}
	- fetch for retrieve password => {success:true/false,password:abc123} password isn't present for existing accounts (instead, backend will notify coordinator). for non-existing unames, success=false
	- one fetch for both? inclusion of pass or not determines function
*/

export function SigninScreen(props) {

	const [formState, setFormState] = useState(0);
	const [usernameStr, setUsernameStr] = useState("");
	const [passwordStr, setPasswordStr] = useState("");
	const [displayPwStr, setDisplayPwStr] = useState("");
	const [uidStr, setUidStr] = useState("");
	const [isNetwork, setIsNetwork] = useState(false);
	const [curError, setCurError] = useState("");
	
	
	const loginReturnFunc = props.onSignIn || ((username, uid) => console.log("Sign in success, but no onSignIn handler was defined."));
	const logoutReturnFunc = props.onSignOut || (() => console.log("Sign out success, but no onSignOut handler was defined."));
	
	if(props.userid && !usernameStr) {
		setUsernameStr(props.userid.split("|")[0]);
		setFormState(4);
	}
	
	function isUsernameValid() {
		if(!usernameStr || usernameStr.length < 3)
			return false;
		if(!isAlphaNumeric(usernameStr))
			return false;
		return true;
	}

	
	function sendLogin(includePass, onSuccess) {
		let dat = {
			mode:"authc",
			secret:"ermz022",
			userid: usernameStr,			
		};
		
		if(includePass) dat['pass'] = passwordStr;
		
		setIsNetwork(true);
		
		fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
		{
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify(dat)
		})
		.then(response => response.json())
		.then(resp => { 
			console.log(resp);
			setIsNetwork(false);

			if(!resp.success) {
				setCurError("There was an error with your request. Please check your information, and internet connection, and try again.");
				return;
			}
			
			if(includePass && resp.id) {
				AsyncStorage.setItem("sleepHelpUsername", usernameStr+"|"+resp.id);
				console.log("Saved username and id to AsyncStorage, user "+usernameStr+" will remain logged in.");
			}
			
			if(onSuccess) onSuccess(resp);
		});			
	}
	

	return (
		<ScrollView style={sty.scrollCont}>
		<View style={sty.container}>
			<View>
			<View style={sty.innerContainer}>
				<Text style={sty.headerText} >Sign In to Faster Asleep</Text>
				{!curError ? null :
					<Text style={sty.errorText}>{curError}</Text>
				}
				<Text style={sty.lblText} >Username</Text>
				<TextInput 
					style={formState < 2 ? sty.inputText : sty.inputTextDisabled} editable={formState < 2}
					value={usernameStr}
					onChangeText={(txt) => setUsernameStr(txt)}
				/>
				{formState == 0 ?
				<View style={sty.innerContainer}>
					<Text style={sty.lblText}>Password</Text>
					<TextInput 
						style={sty.inputText} 
						secureTextEntry={true} 
						value={passwordStr}
						onChangeText={(txt) => setPasswordStr(txt)}
					/>
					<View style={sty.buttonRow}>
						<Button style={sty.buttons} 
							title="   Sign In   " 
							disabled={!isUsernameValid() || !passwordStr}
							onPress={() => sendLogin(true, (r) => {setUidStr(r.id); setFormState(4); loginReturnFunc(usernameStr,r.id);}) }
						/>
						{/*<Button style={sty.buttons} title="  Forgot Password?  " onPress={() => setFormState(1)} />*/}
					</View>
					<View style={sty.spacer} />	
					<Text style={sty.lblText2}>Forgot your username or password? Please contact the study coordinator Claire Starling by email or phone.</Text>
				</View>
				:
				formState == 1 ?
				<View style={sty.innerContainer}>
					<Text style={sty.lblText}>
						Please enter the username you were provided, then click Continue.{"\n\n"}						
					</Text>
					<View style={sty.buttonRow}>
						<Button style={sty.buttons} 
							title="   Continue   " 
							disabled={!isUsernameValid()}
							onPress={() => sendLogin(false, (r) => { if(r.password) { setDisplayPwStr(r.password); setFormState(2); } else setFormState(3) })}
						/>
					</View>
					<View style={sty.spacer} />	
					<Text style={sty.lblText}>
						If you forgot your username, please contact the study coordinator, Claire Starling, by email or phone.
					</Text>
				</View>
				:
				formState == 2 ?
				<View style={sty.innerContainer}>
					<Text style={sty.lblText}>Thank you for participating in the Faster Asleep trial. To sign in next time, you will need to provide this password:</Text>
					<Text style={sty.lblTextBold}>{displayPwStr}</Text>
					<View style={sty.spacer} />
					<Text style={sty.lblText}>Please write down your password now.</Text>
					<View style={sty.spacer} />
					<View style={sty.buttonRow}>
						<Button style={sty.buttons} title="   I wrote it down!   " onPress={() => setFormState(0)} />
					</View>
				</View>
				:
				formState == 3 ?
				<View style={sty.innerContainer}>
					<Text style={sty.lblText}>Thanks! Your study coordinator will be in touch soon to provide your password. We are sorry for the inconvenience.</Text>
				</View>
				:
				formState == 4 ?
				<View style={sty.innerContainer}>
					<Text style={sty.lblText}>You are currently signed in to Faster Asleep. Thank you for your participation!</Text>
					<View style={sty.spacer} />
					<View style={sty.spacer} />
					<Text style={sty.lblText}>Click the button below to sign out</Text>
					<View style={sty.spacer} />
					<View style={sty.buttonRow}>
						<Button style={sty.buttons} title="   Sign Out   " onPress={() => {setFormState(0); logoutReturnFunc()}} />
					</View>
				</View>				
				:
				null
				}
			</View>
			</View>
		</View>
		</ScrollView>
	
	);
}

const sty = StyleSheet.create({
  scrollCont: {
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'	  
  },
  container: {
	alignItems: 'center',
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'
  },
  innerContainer: {
	  width: 300,
	  maxWidth: "95%",
	  marginTop: 10,
	  marginBottom: 10
  },
  spacer: {
	  width: 12,
	  height: 12
  },
  inputText: {
	fontSize: 20,
	borderWidth: 1,
	borderColor: "#c8c8ea",
	marginVertical: 8,
	padding: 4,
	color: '#323180',
  },
  inputTextDisabled: {
	fontSize: 20,
	borderWidth: 1,
	borderColor: "#c8c8ea",
	marginVertical: 8,
	padding: 4,
	color: '#323180',
	backgroundColor: "#eeeeee"
  },
  headerText: {
	fontSize: 42,
	margin: 8,
	color: "#323180"
  },
  lblText: {
	fontSize: 24,
	color: "#55548c"
  },
  lblText2: {
	fontSize: 18,
	color: "#55548c"
  },
  lblTextBold: {
	fontSize: 24,
	color: "#050505",
	fontWeight: "bold"
  },
  buttonRow: {
	flexDirection: "row",
	justifyContent:"space-between"
  },
  buttons: {
	width: 200
  },
  errorText: {
	backgroundColor: "#eec0c0",
	fontSize: 16,
	color: "#402020",
	fontWeight: "bold",
	marginVertical: 20,
	paddingHorizontal: 40,
	paddingVertical: 12,
  }
});