import React, { useState } from 'react';
import { StyleSheet, Text, View, Button, ScrollView, TouchableOpacity } from 'react-native';


const CHR_a = "a".charCodeAt(0);

export function FaqScreen(props) {
	const [parsedText, setParsedText] = useState([]);
	const [sectionState, setSectionState] = useState([]);

	if(parsedText.length == 0) {
		const pt = getParsedTextFaq();
		setParsedText(pt);
		let ss = [];
		for(let i=0; i<pt.length; i++) {
			ss[i] = {show: true, showSub: []};
			for(let j=0; j<(!pt[i] ? 0 : pt[i].lines.length); j++)
				ss[i].showSub[j] = false;
		}
		setSectionState(ss);
		return null;
	}
	
	function renderEdu() {
		return parsedText.map((item, index) => 			
			<View key={index} style={sty.header0box}>
				<View style={sty.header0textbox}>
					<Text style={sty.header0text} >{index+1}) {!item ? "" : item.header}</Text>
				</View>
				
				{!sectionState[index].show ? null :
				<View>
				{!item ? null : item.lines.map((jtem, jndex) =>
					<View style={sty.header1box} key={jndex}>
						<TouchableOpacity style={sty.header1textbox} onPress={() => { 
							sectionState[index].showSub[jndex] = !sectionState[index].showSub[jndex]; 
							setSectionState([...sectionState]); 
						}}>
							<Text style={sectionState[index].showSub[jndex] ? sty.header1text : [sty.underline, sty.header1text]} >
								{String.fromCharCode(jndex+CHR_a)}. {!jtem ? "" : jtem.header}
							</Text>
						</TouchableOpacity>
						
						{!sectionState[index].showSub[jndex] ? null :
						<View>
						{!jtem ? null : jtem.lines.map((ktem, kndex) =>
							<Text key={kndex} style={sty.eduText}>{ktem}</Text>
						)}
						</View>
						}
					</View>
				)}
				</View>
				}
			</View>);
	}

	return (
	<ScrollView style={sty.scrollCont}>
		<View style={sty.container}>
			<Text style={sty.titleText}>Frequently Asked Questions</Text>			
			{renderEdu()}
		</View>
	</ScrollView>
	);
}


const sty = StyleSheet.create({
  scrollCont: {
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'	  
  },	
  container: {
	alignItems: 'center',
	width: '100%',
	backgroundColor: '#FFFFFF'
  },
  titleText: {
    fontSize: 32,
    color: '#102452',
    lineHeight: 50,
    textAlign: 'center',
	margin: 8
  },
  underline: {
	  textDecorationLine: 'underline'
  },
  header0box: {
	  width: '100%',
	  paddingLeft: 10,
	  paddingRight: 10,
	  borderWidth: 0,
	  borderColor: '#aaa'
  },
  header0textbox: {
	  backgroundColor: '#ffffff',
	  marginBottom: 0
  },
  header0text: {
	  color: '#102452',
	  fontSize: 20,
	  margin: 0
  },
  header1box: {
	  padding: 10,
	  marginLeft: 10,
	  borderWidth: 0,
	  borderColor: '#aaa'
  },
  header1textbox: {
	  backgroundColor: '#f8f8f8'
  },
  header1text: {
	  color: '#4b5d87',
	  fontSize: 18,
	  margin: 5
  },
  eduText: {
	  color: '#333',
	  fontSize: 16,
	  margin: 5,	 
	  padding: 5,
	  borderWidth: 0,
	  borderColor: '#aaa'
  }
});

function getParsedTextFaq() {
	const lines = FAQ_TEXT.split(/\r?\n/);
	let ret = []; // each array element should be an object like {header: <str>, lines: [...]}, where each element in lines is the same (recurse until no headers, 2 levels deep)
	let curObj = null;
	let curSubObj = null;
	for(let i=0; i<lines.length; i++) {
		const line = lines[i].trim();
		
		if(line.length > 2 && (line[1] == ")" || line[2] == ")")) { // test for top-level header
			const pi = line.indexOf(")");
			const index = parseInt(line.substring(0, pi).trim(), 10);
			const header = line.substring(pi+1, line.length).trim();
			
			// console.log("Switching to next header " + header);
			// console.log(JSON.stringify(curObj));
			// console.log(JSON.stringify(curSubObj));

			curObj = { header, lines: [] };
			ret[index - 1] = curObj;
			curSubObj = null;
		}
		else if(line.length > 1 && line[1] == "." && line[0].toLowerCase() >= "a" && line[0].toLowerCase() <= "z") { // test for sub header

			const index = line[0].toLowerCase().charCodeAt(0) - CHR_a;
			const header = line.substring(2, line.length).trim();
			
			// console.log("Switching to next subheader " + header);
			// console.log(JSON.stringify(curObj));
			// console.log(JSON.stringify(curSubObj));

			curSubObj = { header, lines: [] };
			if(curObj) curObj.lines[index] = curSubObj;
		}
		else if(curSubObj) {
			curSubObj.lines[curSubObj.lines.length] = line;
		}
	}

	
	for(let i=0; i<ret.length; i++) {
		for(let j=0; j<ret[i].lines.length; j++) {
			const l = ret[i].lines[j];
			while(l && l.lines.length > 0 && l.lines[l.lines.length - 1].length <= 0)
				l.lines.length = l.lines.length - 1;
			for(let k=0; k<l.lines.length; k++) {
				console.log("proc line="+l.lines[k]);
				// add the commented portion to only remove double blank lines, instead of all
				if(l.lines[k].length == 0) { // && k < l.lines.length - 1 && l.lines[k+1].length == 0) { 
					l.lines.splice(k,1);
					k--;
					console.log("removed");
				}
			}
		}
	}
	
	return ret;
}



const FAQ_TEXT = `

1) FAQ
a. Troubleshooting Hotspot Connection
We suggest plugging in all three devices, with the hotspot within 1 foot of the Echo dot.

If you are having trouble connecting to the Echo dot, check the hotspot connection by looking at the connection symbol (four lines on the far left). If only 1 or 2 bars light up, please move your hotspot to another location – preferably near a window.
While we suggest keeping the hotspot within 1 foot of the Echo dot, you can move the hotspot further away from the Echo dot in order to find a stronger hotspot connection.  

If your device shuts down unexpectedly, please restart by saying “Echo, Launch Faster Asleep”. You can also reach out to the research team if you are having continued issues such not connecting to the hotspot.
b. What do I do if the Echo dot won’t record my answers?
There currently exists a small bug, where the Echo dot cannot pick up on some times. If during your sleep logs you are being prompted to give an answer to the same question multiple times, please add 1 minute to your time and the Echo dot will be able to log that time. For example, if you are prompted to log the time you got into bed multiple times at 10:00pm and the device does not acknowledge that time, please try 10:01pm.

Additionally, please answer with simple yes/no responses or numbers. The device will have trouble processing sentences or answers such as “it depends”, “sometimes” or “midnight”.
c. What do I do if I can’t remember one of the times I need to log?
If you don’t know how to respond to something, please just give it your best estimate. 

Your responses are added up over many days and your best guess will help us best customize the program to your needs.
d. How do I interrupt or stop the Echo dot?
If you want to interrupt Echo, first say “Echo.” That will make Echo stop talking and listen for a command or an answer from you. If you would like to stop the Echo dot, please say “Echo, Stop” and the Echo dot will not continue. To restart the Echo dot, simply say “Echo, launch Faster Asleep”.

e. How do I launch education or relaxation content during the day?
If you would like to launch relaxation content during the day, launch Faster Asleep by saying “Echo Launch Faster Asleep”. Faster Asleep will then prompt you to select which mode you would like to use. Please say “Relaxation” for relaxation content or “Education” to replay the daily education content.

f. Who do I contact with any issues?
Please contact the study coordinator Claire Starling at Claire.M.Starling@MedStar.net or 410-610-3893
`;