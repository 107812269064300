import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { useState } from 'react';
import { AppState, Platform, View, PanResponder, Animated } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import TabBarIcon from '../components/TabBarIcon';
import HomeScreen from '../screens/HomeScreen';
import LinksScreen from '../screens/LinksScreen';
import MoreScreen from '../screens/MoreScreen';
import IntakeNavScreen from '../screens/IntakeNavScreen';
import { LoginScreen } from '../screens/LoginScreen';
import { UserHomeScreen } from '../screens/UserHomeScreen';
import { DevicesScreen } from '../screens/DevicesScreen';
import { EducationScreen } from '../screens/EducationScreen';
import { FaqScreen } from '../screens/FaqScreen';
import { SigninScreen } from '../screens/SigninScreen';
import { IntroScreen } from '../screens/IntroScreen';
import { Ed2Screen } from '../screens/Ed2Screen';



import NotifsTest from '../components/NotifsTest';

import Utils from '../Utils';
import Notifs from '../Notifs';

const BottomTab = createBottomTabNavigator();
let startPage = 'Login';

// ***** Determines whether we have a web build act like a web site or phone app
const isWebEnabled = true;  
// *****


export default function BottomTabNavigator({ navigation, route }) {
	const [savedUid, setSavedUid] = useState(null);// useState(localStorage.getItem("sleepHelperUserID") || "");
	const [userdata, setUserdata] = useState(null);
	const [userLogs, setUserLogs] = useState([]);
	const [loadingSave, setLoadingSave] = useState(false);
	const [profileRefresh, setProfileRefresh] = useState(false);
	const [pushToken, setPushToken] = useState(null);
	const [gettingPushToken, setGettingPushToken] = useState(false);
	
	const [trackingData, setTrackingData] = useState({});
	const [lastTrackTime, setLastTrackTime] = useState(0);
	const [sawIntro, setSawIntro] = useState(0); // 0 = uninitialized, 1 = saw the intro, -1 = error
	const [isNewSession, setIsNewSession] = useState(true); // gets set to false immediately, sends tracking data for ctrl site
	
	const isWeb = Platform.OS == 'web' && isWebEnabled;
	
	// for controlsite: manual tracking since panResponder was too weird. Saves an object of names and numeric values, which is zeroed out after being sent to the backend
	const trackingFunc = (name, count) => {
		if(!trackingData[name]) trackingData[name] = count; 
		else trackingData[name] += count; 
		setTrackingData({...trackingData});
		if(Date.now() - lastTrackTime >= 1000) {
			setLastTrackTime( Date.now() );
			updateFunc();
		}
	};
	const updateFunc = () => {
		if(!savedUid) return;
		const [name,uid] = savedUid.split("|");
		const bodyData = {mode:'act', userid: name, id: uid, tracking: trackingData};
		console.log(trackingData, bodyData);
		fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
		{
			method: "POST", 
			headers: { 'Content-Type': 'application/json' }, 
			mode: 'cors',
			body: JSON.stringify({mode:'act', userid: name, id: uid, tracking: trackingData})
		})
		.then(response => response.json())
		.then(resp => { console.log(resp); if(resp && resp.success) setTrackingData({}); });		
	};


	/*const panResponder = useState(PanResponder.create({
      // Ask to be the responder:
      onStartShouldSetPanResponder: (evt, gestureState) => isWeb,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => isWeb,
      onMoveShouldSetPanResponder: (evt, gestureState) => isWeb,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => isWeb,

      onPanResponderGrant: (evt, gestureState) => {
        // The gesture has started. Show visual feedback so the user knows
        // what is happening!
        // gestureState.d{x,y} will be set to zero now
		console.log(`Started responding. evt=${evt}, gesState=${gestureState}`);
      },
      onPanResponderMove: (evt, gestureState) => {
        // The most recent move distance is gestureState.move{X,Y}
        // The accumulated gesture distance since becoming responder is
        // gestureState.d{x,y}
		console.log(`Move responding. evt=${evt}, gesState=${gestureState}`);
      },
      onPanResponderRelease: (evt, gestureState) => {
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
		console.log(`Release responding. evt=${evt}, gesState=${gestureState}`);
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // Another component has become the responder, so this gesture
        // should be cancelled
		console.log(`Terminate responding. evt=${evt}, gesState=${gestureState}`);
      },
	}))[0];*/
	
	if(savedUid === null) {
		AsyncStorage.getItem(isWeb ? 'sleepHelpUsername' : 'sleepHelperUID')
			.then((res) => setSavedUid(res || ""), (err) => setSavedUid(""));
		return null;
	}
	if(isWeb && savedUid && !sawIntro) {
		AsyncStorage.getItem('sawintro')
			.then((res) => setSawIntro(res || -1), (err) => setSawIntro(-1));	
		return null;			
	}
	
	if(pushToken === null && !gettingPushToken && !isWeb) {
		Notifs.registerForPushNotificationsAsync().then(token => { 
			setPushToken(token); 
			setGettingPushToken(false); 
			console.log("Push token=" + token); 
			const curDateTime = new Date();
			const timeStr = curDateTime.getHours() + ":" + curDateTime.getMinutes() + ":" + curDateTime.getSeconds(); // these will lack leading 0 for single-digit numbers, but server doesnt care
			if(token && savedUid && savedUid.length == 36) {
				fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
				{
					method: "POST",
					headers: { 'Content-Type': 'application/json' },
					mode: 'cors',
					body: JSON.stringify({mode:'notif', userid: savedUid, pnt: token, time: timeStr})
				})
				.then(response => response.json())
				.then(resp => { console.log("Setting push token: success=" + resp.success); });
			}
		});
		setGettingPushToken(true);
		//const notifPermReq = Utils.registerForPushNotificationsAsync();
		//notifPermReq.then((token) => {
		//	setPushToken(token);
		//});
	}
	
	if(userdata === null || profileRefresh) {
		if(!isWeb && savedUid && savedUid.length == 36) {
			if(!loadingSave){
				const getLogs = profileRefresh;
				setLoadingSave(true);
				setProfileRefresh(false);
				fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
				{
					method: "POST",
					headers: { 'Content-Type': 'application/json' },
					mode: 'cors',
					body: JSON.stringify({mode:'profile', userid: savedUid})
				})
				.then(response => response.json())
				.then(resp => {  
					if(!resp || !resp.profile) {
						console.log("NO PROFILE RETURNED!");
						setSavedUid("");
						setUserdata({});
						return;
					}
					setUserdata({profile: resp.profile});
					setLoadingSave(false);
				});
				if(getLogs) {					
					const daysBack = ((userLogs.length == 0) ? 14 : 1);
					const startTimeStamp = Date.now() - (daysBack * 24 * 60 * 60 * 1000); // days,hrs,mins,sec,ms
					fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
					{
						method: "POST",
						headers: { 'Content-Type': 'application/json' },
						mode: 'cors',
						body: JSON.stringify({mode:'logs', userid: savedUid, datemin: startTimeStamp})
					})
					.then(response => response.json())
					.then(resp => {  
						if(!resp || !resp.logs) {
							console.log("NO LOGS RETURNED!");
							return;
						}
						// collate the returned logs with any existing ones
						for(let i=0; i<resp.logs.length; i++) {
							let k=0;
							for(k=0; k<userLogs.length; k++)
								if(userLogs[k].datatype == resp.logs[i].datatype)
									break;
							userLogs[k] = resp.logs[i];
						}
						console.log("set logs="+JSON.stringify(userLogs));
						setUserLogs([...userLogs]);
					});					
				}
			}
		}
		else
			setUserdata({});
		
		if(userdata == null)
			return null;
	}
	
  if(isNewSession) {
	  setIsNewSession(false);
	  if(isWeb) trackingFunc("start", 1);
	  return null;
  }
	
  // Set the header title on the parent stack navigator depending on the
  // currently active tab. Learn more in the documentation:
  // https://reactnavigation.org/docs/en/screen-options-resolution.html
  navigation.setOptions({ headerTitle: getHeaderTitle(route) });

  console.log("Nav param userdata="+JSON.stringify(userdata));
  
  const isLoggedIn = userdata && userdata.profile && userdata.profile.userid;
  const isIntakeComplete = isLoggedIn && userdata.profile.intakeData;
  const isDeviceLinked = isIntakeComplete && (userdata.profile.dev_bedroom || userdata.profile.dev_other);
  
  
  startPage = 
	(!isLoggedIn ? 'Login'
	: (!isIntakeComplete ? 'Intake' 
	: (!isDeviceLinked ? 'Devices' 
	: 'HomeScreen')));
  
  if(isWeb) {
	  startPage = "Sign In";
	  if(savedUid) startPage = sawIntro > 0 ? "Education" : "Introduction";
  }
	
  console.log("startPage="+startPage);
  
  return (<BottomTab.Navigator 
	initialRouteName={startPage} 
	screenOptions={{headerShown: false}}
	>
	{isWeb ? 
	  <BottomTab.Screen
	    name="Sign In"
        options={{
          title: 'Sign In',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />,
        }}>
			{() => (<SigninScreen 
						navigation={navigation} 
						userid={savedUid} 
						onSignIn={(username,id) => { setSavedUid(username+"|"+id); trackingFunc("signin", 1); setTimeout(() => navigation.navigate(sawIntro > 0 ? "Education" : "Introduction"), 100); }} 
						onSignOut={() => { AsyncStorage.setItem("sleepHelpUsername", "").then((r)=>setSavedUid(null),(e)=>setSavedUid(""));  trackingFunc("signout", 1); } }
					/>) }
	  </BottomTab.Screen> 
	  :
	  <BottomTab.Screen
        name="Login"
        options={{
          title: 'Login',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />,
        }}>
			{() => (<LoginScreen navigation={navigation} pushToken={pushToken} userdata={userdata} setUserdata={(udat) => { setUserdata(udat); if(udat === null) { setSavedUid(null); console.log("cleared uid"); } }} />) }
	  </BottomTab.Screen>
	}
	{isWeb || !isLoggedIn || (true && isDeviceLinked) ? null :
		<BottomTab.Screen
			name="Intake"
			options={{
			  title: 'Intake',
			  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />,
		}}>
			{() => (<IntakeNavScreen userdata={userdata} setUserdata={setUserdata} />) }
		</BottomTab.Screen>
	}
	{isWeb || !isIntakeComplete ? null :
		<BottomTab.Screen
			name="Devices"
			options={{
			title: 'Devices',
			tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />
		}}>
			{() => (
				<DevicesScreen
					profile={userdata.profile}
					onProfileUpdated={() => setUserdata(null)}
				/>
			)}
		</BottomTab.Screen>	 
	}	
	{isWeb || !isDeviceLinked ? null :
		<BottomTab.Screen
			name="HomeScreen"
			options={{
			  title: 'Home',
			  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />,
			}}>
				{() => (<UserHomeScreen userdata={!userLogs.length ? userdata : {logs: userLogs, ...userdata}} onRefresh={() => setProfileRefresh(true)}/>) }
		</BottomTab.Screen>	 
	}	
	{!isWeb || !savedUid ? null :
		<BottomTab.Screen
			name="Introduction"
			options={{
			  title: 'Introduction',
			  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />,
			}}>
				{() => (<IntroScreen onComplete={sawIntro > 0 ? null : () =>{ if(sawIntro < 1) AsyncStorage.setItem("sawintro", 1).then((r)=>{setSawIntro(1); navigation.navigate("Education");},(e)=>setSawIntro(1));  } }/>) }
		</BottomTab.Screen>		
	}
	
	{((!isWeb && !isDeviceLinked)  || (isWeb && (!savedUid || sawIntro < 1))) ? null :
		
		<BottomTab.Screen
			name={"Education"}
			options={{
			  title: 'Education',
			  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />,
			}} >
				{() => (isWeb ? (<Ed2Screen limitWidth={isWeb} tracker={!isWeb ? null : trackingFunc}/>) : (<EducationScreen limitWidth={isWeb} tracker={!isWeb ? null : trackingFunc}/>)) }
				{/*{() => (<View {...panResponder.panHandlers}><EducationScreen limitWidth={isWeb}/></View>) }*/} 
		</BottomTab.Screen>	 
		
	}
	{isWeb || !isDeviceLinked ? null :
		<BottomTab.Screen
			name="FAQ"
			options={{
			  title: 'FAQ',
			  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />,
			}}>
				{() => (<FaqScreen/>) }
		</BottomTab.Screen>	 
	}		  
	{/*<BottomTab.Screen
		name="Notifications"
		options={{
		  title: 'Notifications',
		  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"md-radio-button-" + (focused ? "on" : "off")} />,
		}}>
		{() => (<NotifsTest/>) }
	</BottomTab.Screen>	*/
	}
    </BottomTab.Navigator>);
}

function getHeaderTitle(route) {
  const routeName = route.state?.routes[route.state.index]?.name ?? startPage;
  
  switch (routeName) {

    case 'Home':
      return 'How to get started';
    case 'Links':
      return 'Links to learn more';
	  
    case 'HomeScreen':
      return 'Faster Asleep - Home';
	case 'Intake':
      return 'Faster Asleep - Intake Form';
	case 'Login':
		return 'Faster Asleep Login';
	case 'Devices':
		return 'Faster Asleep - Device Setup';
	case 'Education':
		return (isWebEnabled ? 'Education' : 'Faster Asleep - Insomnia Education');
	case 'FAQ':
		return 'Faster Asleep - Frequently Asked Questions';
	case 'Sign In':
		return 'Faster Asleep - Sign In';
	case 'Introduction':
		return 'Introduction';
	default:
		return 'Unknown - ' + routename;
  }
}
