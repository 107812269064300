import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useState } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';

import useCachedResources from './hooks/useCachedResources';
import BottomTabNavigator from './navigation/BottomTabNavigator';
import LinkingConfiguration from './navigation/LinkingConfiguration';
import { LogBox } from 'react-native'; 


// 2021-12-28 upgraded expo which changed yellowbox to logbox and also it doesn't silently fail on web now.
if(Platform.OS !== 'web') 
	LogBox.ignoreAllLogs();
/*[
  'VirtualizedLists should never be nested', // TODO: Remove when fixed
  'Warning: Each child in a list should have a unique "key" prop'
]);*/

const Stack = createStackNavigator();

export default function App(props) {
  const isLoadingComplete = useCachedResources();
  const [userId, setUserId] = useState();
  
  function createBottomTabNav() {
	const pageProps = {};
	
	  
	return (
		<BottomTabNavigator props={pageProps} />
	);	
  };
  
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}
        <NavigationContainer linking={LinkingConfiguration}>
          <Stack.Navigator>
            <Stack.Screen name="Faster Asleep" component={BottomTabNavigator} />
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
	overflow: "hidden"
  },
});
