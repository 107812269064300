import React, { useState } from 'react';
import { StyleSheet, Text, View, ScrollView, TouchableHighlight, Linking } from 'react-native';


export function Ed2Screen(props) {

	//const onComplete = props.onComplete || null;
	const trackingFunc = props.tracker || ((name, amt) => { });
	
	const RefLink1 = "https://www.sleepfoundation.org/insomnia/treatment/cognitive-behavioral-therapy-insomnia";
	const RefLink2 = "https://www.mentalhealth.va.gov/coe/cih-visn2/Documents/Provider_Education_Handouts/CBT_for_Insomnia_Information_Sheet_for_BHPs_Version_3.pdf";

	const handleLink = (linkUrl) => { 
		if(linkUrl == RefLink1) trackingFunc("ClickLink1", 1);
		else if(linkUrl == RefLink2) trackingFunc("ClickLink2", 1);
		else trackingFunc("ClickLink", 1);
		if(linkUrl) { console.log("Opening link: " + linkUrl); Linking.openURL(linkUrl);  }
	};

	return (
	<ScrollView style={sty.scrollCont}>
		<View style={sty.container}>
			<Text style={sty.titleText}>CBT-I Education</Text>
			<View style={sty.textCont}>
				<Text>
				Cognitive Behavioral Therapy for Insomnia (CBT-I) is the first choice for insomnia treatment. CBT-I involves different components including: sleep restriction, stimulus control, sleep education, sleep hygiene, and relaxation exercises. Research shows that even if they have had very long-term insomnia 70% of people who complete CBT-I will have long lasting sleep improvements.
				</Text>
				<View style={sty.spacer} />

				<Text>
				CBT-I includes the following components: 
				</Text>
				<View style={sty.spacer} />

				<Text>
					<Text style={sty.bulletPoint}>
						{`1) Psychoeducation and cognitive restructuring. `} 
					</Text>
					Cognitive behavioral therapy helps to shift common insomnia misconceptions and perceived poor sleep quality. 
				</Text>
				<View style={sty.spacer} />

				<Text>
					<Text style={sty.bulletPoint}>
						{`2) Sleep hygiene. `}
					</Text>
					Practical and general information about optimal sleep health, sleep hygiene and sleep/wake regulation are key CBT-I techniques to facilitate behavior change and set sleep health priorities. Avoiding consumption of nicotine, caffeine, and alcohol at night is recommended. Individuals with insomnia should also consider increasing exercise and improving the bedroom environment including minimizing screen use at night. 
				</Text>
				<View style={sty.spacer} />

				<Text>
					<Text style={sty.bulletPoint}>
						{`3) Relaxation exercises. `}
					</Text>
					Individuals with insomnia are encouraged to identify and use calming podcasts or other relaxation exercises. There are many types of relaxation exercises that may be helpful including body scans, progressive muscle relaxation, and deep breathing.  Whatever relaxation technique you prefer is fine to be used in CBT-I. 
				</Text>
				<View style={sty.spacer} />

				<Text>
					<Text style={sty.bulletPoint}>
						{`4) Stimulus control. `}
					</Text>
					Dissociating wakefulness and frustration lying in bed awake is a key component of CBT-I. You are encouraged to leave the bed after 20 minutes if you are lying in bed awake. You should only return to the bedroom when sleepy.
				</Text>
				<View style={sty.spacer} />

				<Text>
					<Text style={sty.bulletPoint}>
						{`5) Sleep restriction. `}
					</Text>
					Sleep restriction is a challenging aspect of CBT-I that directs individuals to use utmost discipline to reduce their sleep duration as a means of increasing their homeostatic sleep drive to ultimately consolidate, and improve sleep continuity and quality. 
				</Text>
				<View style={sty.spacer} />
				
				<Text>Some individuals choose to use online programs to learn more about CBT-I and others seek help in-person from a trained therapist.</Text>
				
				<View style={sty.spacer} />
				<View style={sty.spacer} />

				<Text>References:</Text>
				<TouchableHighlight onPress={() => handleLink(RefLink1)} style={sty.linkTO}>
					<Text style={sty.linkText}>{RefLink1}</Text>
				</TouchableHighlight>

				<TouchableHighlight onPress={() => handleLink(RefLink2)} style={sty.linkTO}>
					<Text style={sty.linkText}>{RefLink2}</Text>
				</TouchableHighlight>
				
				<View style={sty.spacer} />
			</View>
		</View>
	</ScrollView>
	);
}


const sty = StyleSheet.create({
  scrollCont: {
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'	  
  },	
  container: {
	alignItems: 'center',
	width: '100%',
	backgroundColor: '#FFFFFF'
  },
  titleText: {
    fontSize: 32,
    color: '#102452',
    lineHeight: 50,
    textAlign: 'center',
	margin: 8
  },
  textCont: {
	width: 600,
	maxWidth: "95%",
	fontSize: 18,
  },
  spacer: {
	  width: 20,
	  height: 19
  },
  bulletPoint: {
	  fontWeight: 'bold',
	  fontStyle: 'italic'
  },
  
  linkCont: {
  },
  linkTO: {
    marginTop: 12,
    alignItems: 'start',   
	zIndex: 100	
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',	  
  }

  
});