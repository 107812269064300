import React, { useState } from 'react';
import { StyleSheet, Text, View, Button } from 'react-native';
import { NumberStepper } from '../components/NumberStepper';


export function NumericCodeEntry(props) {
	const [authCode, setAuthCode] = useState([]);

	let digits = props.digits || 1;
	digits = Math.min(8, Math.max(1, digits));
	if(authCode.length != digits){
		const newCode = new Array(digits);
		for(let i=0; i<digits; i++) newCode[i] = 0;
		setAuthCode(newCode);
		return null;
	}

	const title = props.title || "";
	const buttonLabel = props.buttonLabel || "Submit";
	
	const onSubmit = props.onSubmit || ((code) => {console.log("No handler to accept code: "+code);});

	const authBoxes = new Array(digits);
	for(let i=0; i<authCode.length; i++) {
		const ix = i;
		authBoxes[i] = (
			<NumberStepper
				title="" 
				style={sty.stepper}
				vertical={true}
				minimum={-1}
				maximum={10}
				value={authCode[ix]} 
				onChange={(val)=>{authCode[ix] = (10 + val) % 10; setAuthCode([...authCode]);}}
				key={ix}
			/>
		);
	}

	return (
	<View style={sty.container}>

		<View>
			<View style={sty.createLabelContainer}>
				<Text style={sty.createLabel}>{title}</Text>
			</View>	
			<View style={sty.authSteppers}>
				{authBoxes}
			</View>
			<Button 
				color='#323180'
				title={' ' + buttonLabel + ' '}
				disabled={authCode.join('') == '00000'}
				onPress={()=>{onSubmit(authCode.join(''));}}
			/>						
		</View>


	</View>
	);
}


const sty = StyleSheet.create({
	container: {
		alignItems: 'center',
		width: '100%',
		backgroundColor: '#FFFFFF'
	},
	authSteppers: {
	  flexDirection: 'row',
	  borderWidth: 0,
	  alignItems: 'center',
	  justifyContent: 'space-around'
	},
	createLabelContainer: {
		marginTop: 12
	},
	createLabel: {
		fontSize: 16,
		textAlign: 'center',
		color: "#000040"
	}, 
	stepper: {
		width: 50
	},
	
});