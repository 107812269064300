import React, { useState } from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, FlatList, Picker, Switch, TextInput  } from 'react-native';
import { CheckBox } from 'react-native-elements';

export function MultiCheckBox(props) {
	const [internalVals, setInternalVals] = useState([]);
	
	function onChecked(index) {
		let ret = [];
		for(let i=0; i<props.fieldNames.length; i++)
		{
			let val = (props.fieldValues && props.fieldValues.length > i) ? props.fieldValues[i] 
			: (internalVals.length > i ? internalVals[i] : false);
			if(i == index)
				val = props.isRadio ? true : (!val);
			else if(props.isRadio) 
				val = false;
			ret.push(val);
		}
		setInternalVals(ret);
		if(props.onChange)
			props.onChange([...ret]);
	}

	const dat = [];
	for(let i=0; props.fieldNames && i < props.fieldNames.length; i++)
	{
		const v = (props.fieldValues && props.fieldValues.length > i) ? props.fieldValues[i] 
			: (internalVals.length > i ? internalVals[i] : false);
		dat[i] = { key: i+"", name: props.fieldNames[i], value: v, cb: () => { onChecked(i); }};
	}
	const maxIx = dat.length - 1;
	const horz = props.horizontal && true;
	
	const renderResult = (item) => {
		const condensedHorzMargins = {};
		if(horz){
			condensedHorzMargins.marginLeft = (item.item.key > 0) ? -10 : 0;
			condensedHorzMargins.marginRight = (item.item.key < maxIx) ? -10 : 0;
		}
		
		return (
			<View style={condensedHorzMargins}>
				<CheckBox				
					title={item.item.name}
					checked={item.item.value}
					onPress={item.item.cb}
					size={horz?18:24}
					containerStyle={[horz ? styles.cboxStyle : {},(item.item.value ? {backgroundColor: "#e3f1fa"}:{})]}
					checkedIcon={props.isRadio ? 'dot-circle-o' : 'check-square-o'}
					uncheckedIcon={props.isRadio ? 'circle-o' : 'square-o'}
				/>
			</View>
		);
	};
	
	return (
		<View>
			<View style={styles.container}>
				<Text style={styles.title}>{props.title}</Text>
				<View>
					<FlatList data={dat} renderItem={renderResult} keyExtractor={(item, index) => index.toString()}
					contentContainerStyle={{flexDirection: (props.horizontal ? "row" : "column"), justifyContent: "space-between"}} />
				</View>
			</View>
		</View>
	);
}


const styles = StyleSheet.create({
  container: {
	width: "100%"
  },

  title: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  cboxStyle: {
	paddingVertical: 4, 
	paddingLeft: 4, 
	paddingRight: 0,
	marginHorizontal: 0,
	marginVertical: 5
  }
});