import React, { useState } from 'react';
import { StyleSheet, Text, View, Button, ScrollView } from 'react-native';
import { NumberStepper } from '../components/NumberStepper';
import { NumericCodeEntry } from '../components/NumericCodeEntry';

const ALEXA_COMMANDS = 
	"Echo, launch Faster Asleep and give me a pairing code.";

export function DevicesScreen(props) {
	const [showDetailsBedroom, setShowDetailsBedroom] = useState(false);
	const [showDetailsOther, setShowDetailsOther] = useState(false);

	const profile = props.profile || {};
	const updProfile = props.onProfileUpdated || (()=>{});
	
	const devIdBedroom = profile.dev_bedroom;
	const devIdOther = profile.dev_other;
	
	const hideSecondDevice = (!devIdOther || devIdOther.length < 1) && true; // change to false to always show both devices
	
	function buildContentForDevice(isBedroom) {
		
		const deviceName = hideSecondDevice ? "" : (isBedroom ? "Bedroom" : "Other Room");
		const devid = isBedroom ? devIdBedroom : devIdOther;
		const isShowingDetails = isBedroom ? showDetailsBedroom : showDetailsOther;
		const setShowDetails = isBedroom ? setShowDetailsBedroom : setShowDetailsOther;
		const setShowOpposite = !isBedroom ? setShowDetailsBedroom : setShowDetailsOther;
		
		if(devid)
			return (
				<View style={sty.deviceContent}>
					<Text>DeviceID: {devid.substring(0,25)}...{devid.substring(devid.length - 8)}</Text>
					<Button 
						color='#bc7272'
						title={' Remove Device '}
						onPress={()=>{setShowDetails(false); removeDevice(isBedroom);}}
					/>
				</View>
			);
		
		if(!isShowingDetails)
			return (
				<Button 
					color='#323180' 
					title={' Add ' + deviceName + ' Device '}
					onPress={()=>{setShowDetails(true); setShowOpposite(false);}}
				/>			
			);

		return (
			<View style={sty.deviceContent}>
				<Button 
					color='#bc7272'
					title={' Cancel '}
					onPress={()=>{setShowDetails(false);}}
				/>	
				<View>
					<Text style={sty.instructText}>
						To add your {deviceName} device:
					</Text>
					<Text style={sty.instructText}>
						1. Make sure it is in the {deviceName} location and plugged in.
					</Text>
					<Text style={sty.instructText}>
						2. Speak the following command to the device, and it will respond with a 4-digit code.
					</Text>
					<Text style={sty.instructTextAlexa}>
						"{ALEXA_COMMANDS}"
					</Text>
					<Text style={sty.instructText}>
						3. Enter the code spoken by your {deviceName} device below:
					</Text>
				</View>
				<NumericCodeEntry 
					title='The code must be entered within 1 minute'
					digits={4}
					onSubmit={(code) => addDevice(isBedroom, code)}
				/>
			</View>
		);
	}

	
	function addDevice(isBedroom, confirmationCode) {
		console.log("Try to add device, bedroom="+isBedroom+", code="+confirmationCode);
		fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
		{
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify({mode:'syncdevice', bedroom: !!isBedroom, code: confirmationCode, userid: profile.userid})
		})
		.then(response => response.json())
		.then(resp => {  
			if(!resp || !resp.success) {
				console.log("Error adding device bedroom="+isBedroom);
			}
			
			if(isBedroom) setShowDetailsBedroom(false);
			else setShowDetailsOther(false);
			
			console.log("Added device, result:");
			console.log(resp);
			
			updProfile();
		});
	}
	
	
	function removeDevice(isBedroom) {
		console.log("Try to remove device, bedroom="+isBedroom);
		fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
		{
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify({mode:'removedevice', bedroom: !!isBedroom, userid: profile.userid})
		})
		.then(response => response.json())
		.then(resp => {  
			if(!resp || !resp.success) {
				console.log("Error removing device bedroom="+isBedroom);
			}

			if(isBedroom) setShowDetailsBedroom(false);
			else setShowDetailsOther(false);
			
			updProfile();
		});
	}
	

	return (
	<ScrollView style={sty.scrollCont}>
		<View style={sty.container}>
			<Text style={sty.titleText}>Devices</Text>
			<View style={sty.deviceCont}>
				<Text style={sty.deviceTitleText}>
					{hideSecondDevice ? "Echo" : "Bedroom"} Device - 
					<Text style={devIdBedroom ? sty.greenText : sty.redText}>{devIdBedroom ? " Connected" : " None"}</Text>
				</Text>
				<Text style={sty.deviceSubtitleText}>Your main Echo device, for recording sleep logs and interacting with Faster Asleep on a day-to-day basis</Text>

				<View style={sty.deviceContent}>
					{buildContentForDevice(true)}
				</View>
			</View>
			<View style={sty.spacer} />
			{hideSecondDevice ? null : 
			<View style={sty.deviceCont}>
				<Text style={sty.deviceTitleText}>
					Other Device - 
					<Text style={devIdOther ? sty.greenText : sty.redText}>{devIdOther ? " Connected" : " None"}</Text>
				</Text>
				<Text style={sty.deviceSubtitleText}>A device located outside of the bedroom, used during sleep hygeiene and reviewing sleep education content during the day.</Text>
				
				<View style={sty.deviceContent}>
					{buildContentForDevice(false)}
				</View>
			</View>
			}
		</View>
	</ScrollView>	
	);
}


const sty = StyleSheet.create({
  scrollCont: {
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'	  
  },	
  container: {
	alignItems: 'center',
	width: '100%',
	backgroundColor: '#FFFFFF'
  },
  titleText: {
    fontSize: 32,
    color: '#102452',
    lineHeight: 50,
    textAlign: 'center',
	margin: 8
  },
  deviceTitleText: {
    fontSize: 20,
    color: 'rgba(50,50,50, 1)',
    lineHeight: 24,
    textAlign: 'center',
	margin: 8
  }, 
  deviceSubtitleText: {
    fontSize: 12,
    color: 'rgba(110,110,110, 1)',
    textAlign: 'center',
	margin: 6
  },    
  deviceContent: {
	alignItems: 'center'
  },
  instructText:{
	fontSize: 16,
	margin: 8
  },
  instructTextAlexa:{
	fontSize: 16,
    lineHeight: 22,
	marginHorizontal: 15,
	paddingHorizontal: 15,
	paddingVertical: 8,
	fontWeight: 'bold',
	fontStyle: 'italic',
	backgroundColor: '#d9e2f7'
  },
  
  spacer: {
	  margin: 16
  },
  greenText: {
	  color: '#44bb44',
	  fontSize: 20,
      textAlign: 'center',
	  marginBottom: 8
  },
  redText: {
	  color: '#bb4444',
	  fontSize: 20,
	  textAlign: 'center',
	  marginBottom: 8
  }
});