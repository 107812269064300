import * as WebBrowser from 'expo-web-browser';
import React, { useState, useRef } from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, FlatList, Picker, Switch, TextInput, Button  } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Fontisto } from '@expo/vector-icons'; 
import { CheckBox } from 'react-native-elements';
import { MonoText } from '../components/StyledText';
import { TimePickerCallen } from '../components/TimePickerCallen';
import { MultiCheckBox } from '../components/MultiCheckBox';
import { LikertScale } from '../components/LikertScale';
import { DotsPaging } from '../components/DotsPaging';
import { NumberStepper } from '../components/NumberStepper';


export default function QuestionsScreen(props) {
	const scrollEl = useRef(null);
	const [lastPage, setLastPage] = useState(-1);
	const [serverResults, setServerResults] = useState(null);
	// have to detect change so we can set vars to same values
	const [wasDiffWeekend, setWasDiffWeekend] = useState(true);
	let bedActBools = [];
	let bedPartnerBools = [];
	let cantSleepBools = [];
	let substanceBools = [];
	let pastSolutionBools = [];
	let pastRxBools = [];
	let pastOtcBools = [];

	const ConcernValues = [
		'Falling asleep',
		'Staying asleep',
		'Poor sleep quality',
		'Nonrestorative sleep',
		'Physical discomfort (e.g. hot flashes)',
		'Mood or anxiety disruptions',
		'Early morning awakening',
		'Daytime sleepiness'
	];
	const StruggleValues = [
		'< 3 months',
		'3-6 months',
		'6-12 months',
		'1-2 years',
		'3-5 years',
		'> 5 years'
	];
	const NapValues = [
		'None', 
		'Occasionally, but not daily', 
		'1', 
		'2', 
		'3', 
		'4 or more'
	];
	const ExerValues = [
		'Never', 
		'Less than once per week', 
		'1-2 times per week', 
		'3-4 times per week', 
		'5-6 times per week', 
		'7+ times per week'
	];
	const BedActivityValues = [ 
		'Eating',
		'Watching TV', 
		'Using Phone', 
		'Using Tablet',
		'Using Laptop',
		'Listening to Music', 
		'Reading', 
		'Doing Work', 
		buildOptionOther('activitiesInBedOther'),
		'None of the above'
	];
	const BedPartnerValues = [ 
		'No one',
		'Partner', 
		'Child', 
		'Pet',
		buildOptionOther('bedPartnersOther')
	];
/* 	const CantSleepValues = [ 
		'Lie in bed and engage in an activity: look at my phone or other electronic device to check the time, the news, social media, Email.', 
		'Watch TV in bed',
		'Get something to eat',
		'Get something to drink (alcohol; water; juice; hot tea)',
		'Lie in bed and engage in a quiet activity: (e.g. reading, relaxation/meditation; listen to a podcast/audio book)', 
		'Get out of bed and engage in a quiet activity: (e.g. reading, relaxation)', 
		'Get out of bed and watch TV',
		'Get out of bed and engage in an activity, work, email, cleaning',
		'Go to sleep somewhere else',
		buildOptionOther('activitiesCantSleepOther'),
		'None of the above'
	]; */
	const CantSleepValues = [ 
		'Lie in bed and engage in a quiet activity (reading, relaxation/meditation, listen to a podcast/audio book)', 
		'Get out of bed and engage in a quiet activity (reading, relaxation/meditation, listen to a podcast/audio book)',
		'Watch TV in bed',
		'Get out of bed and watch TV',
		'Lie in bed and engage in an activity (look at my phone or other device to check the time, the news, social media, email)',
		'Get out of bed and engage in an activity like work, email, cleaning', 
		'Get something to eat', 
		'Get something to drink (alcohol, water, juice, hot tea)',
		'Go to sleep somewhere else',
		buildOptionOther('activitiesCantSleepOther'),
		'None of the above'
	];
	const SubstanceValues = [
		'Caffeine (Coffee, Tea, Soda, Chocolate)', 
		'Alcohol',
		'Tobacco',
		'Marijuana (THC/CBD)',
		'Stimulants (Adderall, Ritalin)', 
		'Sedatives (Ativan, Xanax)', 
		buildOptionOther('substancesOther'),
		'None of the above' 
	];	
	const PastSolutionValues = [
		'Prescription medications',
		'Over the Counter medications',
		'Decreased amount or timing of caffeine',
		'Cut out daytime naps',
		'Used relaxation',
		'Changed my schedule',
		buildOptionOther('pastSolutionsOther'),
		'None of the above'	
	];
	const PastRx = [
		'Ambien (Zolpidem)',
		'Sonata (Zaliplon)',
		'Lunesta (Eszopiclone)',
		'Xanax, Clonazepam, or Alprazolam',
		'Trazodone or Elavil',
		'Remeron',
		'Clonidine',
		'Gabapentin',
		buildOptionOther('pastRxsOther'),
		'None of the above'	
	];
	const PastOtc = [
		'Melatonin',
		'Benadryl (Antihistamines)',
		buildOptionOther('pastOtcsOther'),
		'None of the above'		
	];

	
	function handleCompletion() {
		if(!props.userid)
		{
			console.log("Cannot submit form, no userid provided!");
			setServerResults({error: "No userid is set, so form was not submitted."});
			return;
		}
		const formData = {
			mode: 'intake',
			userid: props.userid,
			form: {...DATA, timestamp: Date.now()}
		};
		fetch("https://9dcl6kmh6j.execute-api.us-east-1.amazonaws.com/default/httptest",
		{
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify(formData)
		})
		.then(response => response.json())
		.then(resp => { 
			if(!resp) {
				console.log("bad result from server!");
				return;
			}
			console.log(resp); 
			setServerResults(resp); 
			if(resp.success === true && props.onSubmitSuccess)
				props.onSubmitSuccess(resp.profile);
		});
	}

	if(props.page != lastPage) {
		setLastPage(props.page);
		if(scrollEl && scrollEl.scrollTo) scrollEl.scrollTo(0);
	}

	const formTime = Math.floor((Date.now() - props.time) / 1000);
	console.log("t="+formTime);
	
	const DATA = props.data;
	
	if(props.page === 6) {
		if(serverResults === null) {
			setServerResults({});
			handleCompletion();
		}
	}
	
	function convertStringsToMultiboxValues(stringData, labelArr) {
		const stringArr = !Array.isArray(stringData) ? [stringData] : stringData;
		const ret = new Array(labelArr.length);
		for(let i=0; i<ret.length; i++) {
			let found = false;
			const search = (typeof labelArr[i] === 'string') ? labelArr[i] : "Other";
			for(let j=0; !found && j<stringArr.length; j++)
				found = (stringArr[j] === search);
			ret[i] = found;
		}
		return ret;
	}
	function convertMultiboxToStrings(values, labels) {
		let ret = [];
		for(let i=0; i<values.length; i++)
			if(values[i] && labels.length > i)
				ret.push((typeof labels[i] === 'string') ? labels[i] : "Other");
		return ret;
	}

	function enforceMutexOption(prevValues, newValues, targetIndex) {
		const arrLen = newValues.length;
		if(prevValues.length === arrLen) {
			if(!prevValues[targetIndex] && newValues[targetIndex]) {
				for(let i=0; i<arrLen; i++)
					newValues[i] = (i === targetIndex);
			} 
			else if(prevValues[targetIndex] && newValues[targetIndex]) {
				for(let i=0; i<arrLen; i++)
					if(newValues[i] && (i !== targetIndex)) {
						newValues[targetIndex] = false;
						break;
					}										
			} 
		}
		return newValues;
	}
	
	function buildOptionOther(dataPropKey) {
		return (<View style={{flexDirection: "row", alignItems: "center", marginLeft: 10}}>
			<Text>Other: </Text><TextInput style={styles.textInputStyle} 
				onChangeText={(t) => { if(DATA[dataPropKey] === t) return; DATA[dataPropKey] = t; props.onChange({...DATA});}} />
		</View>);
	}
	
	function buildLikertScale(dataKey, title, minDesc, maxDesc, midDesc) {
		return (
			<View>
				<LikertScale 
					title={title} 
					minDescriptor={minDesc || ""} 
					maxDescriptor={maxDesc || ""} 
					midDescriptor={midDesc || ""}
					value={DATA[dataKey]} 
					onChange={(val) => {DATA[dataKey] = val; props.onChange({...DATA}); }}
				/>
				<View style={styles.spacerMedium} />			
			</View>
		);
	}
	
	
	let pageData = null;
	let validator = () => { return true; } //set in each case below to only enable "next" button when DATA for the page is valid
	
	switch(props.page) {
		
		case 0:
		pageData = (				
			<View style={styles.myContainer}>
				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Intake App:</Text>
				</View>
				<MultiCheckBox 
					title={"What are your primary concerns related to your sleep problems?\n(check all that apply)"}
					fieldNames={ConcernValues}
					fieldValues={convertStringsToMultiboxValues(DATA.primaryConcerns, ConcernValues)}
					onChange={(arr) => {						
						DATA.primaryConcerns = convertMultiboxToStrings(arr, ConcernValues); 
						props.onChange({...DATA}); 
					}} 
				/> 
			</View> 					
		); 
		validator = () => { return DATA.primaryConcerns.length > 0 };
		break;
			
			
		case 1: 
		pageData = (
			<View style={styles.myContainer}>
				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Sleep Issues:</Text>
				</View>
				
				<MultiCheckBox 
					title='How long have you struggled with your sleep?'
					fieldNames={StruggleValues}
					fieldValues={convertStringsToMultiboxValues(DATA.insomniaLength, StruggleValues)}
					isRadio={true}
					onChange={(arr) => { DATA.insomniaLength = convertMultiboxToStrings(arr, StruggleValues)[0]; props.onChange({...DATA}); }} 
				 />	
								
				<View style={styles.spacerMedium} />
				<Text style={styles.sectionSubheaderText}>For the following questions, please consider your average responses for your sleep over the past month.</Text>
				<View style={styles.spacerSmall} />
				<View style={styles.myContainer}>					
					<NumberStepper 
						title="Approximately how many times do you wake up in the middle of the night?" 
						value={DATA.wakeUpCount} 
						onChange={(val)=>{DATA.wakeUpCount=val; props.onChange({...DATA});}} 
					/>
				</View>
				<View style={styles.spacerMedium} />
				
				{ DATA.wakeUpCount <= 0 
				? null 
				:
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>How long are you typically awake for, in total, during the middle of the night?</Text>
					<TimePickerCallen dialog={true} resolution={5} combined={false} timespan={true} hour={DATA.wakeUpDuration.hr} minute={DATA.wakeUpDuration.min} onTimeChanged={(hr,min) => { 
						DATA.wakeUpDuration = { hr, min };
						props.onChange({...DATA});
					}}/>
					<View style={styles.spacerMedium} />
				</View>
				}
									 
						  
				<MultiCheckBox 
					title='Do you wake up earlier than intended (i.e., before your alarm clock)?'
					fieldNames={[ 'Yes', 'No' ]}
					isRadio={true}
					fieldValues={(DATA.wakeUpBeforeAlarm === null ? null : [DATA.wakeUpBeforeAlarm, !DATA.wakeUpBeforeAlarm])}
					onChange={(arr) => {DATA.wakeUpBeforeAlarm = arr[0]; props.onChange({...DATA}); }}
				 />					 
			</View>
		); 
		validator = () => { 
			return DATA.wakeUpBeforeAlarm !== null 
				&& DATA.insomniaLength !== null 
				&& DATA.wakeUpCount !== null	
				&& (DATA.wakeUpCount === 0 || DATA.wakeUpDuration.hr > 0 || DATA.wakeUpDuration.min > 0);
		}
		break;

		
		case 2: 
		const diffWeekend = DATA.differentWeekends !== false;
		if(diffWeekend !== wasDiffWeekend) {
			setWasDiffWeekend(diffWeekend);
			if(!diffWeekend) {
				// copy weekday values to weekend values
				DATA.bedTimeWeekend = {...DATA.bedTimeWeekday};
				DATA.solWeekend = {...DATA.solWeekday};
				DATA.wakeTimeWeekend = {...DATA.wakeTimeWeekday};
				props.onChange({...DATA});
			}
		}
		pageData = (
			<View style={styles.myContainer}>
				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Sleep Schedule:</Text>
				</View>

				<MultiCheckBox 
					title='Do you go to bed and wake up at different times on scheduled and unscheduled days (like weekdays and weekends)?'
					fieldNames={[ 'Yes', 'No' ]}
					fieldValues={(DATA.differentWeekends === null ? null : [DATA.differentWeekends, !DATA.differentWeekends])}
					isRadio={true}
					onChange={(arr) => {DATA.differentWeekends = arr[0]; props.onChange({...DATA}); }}
				 />						
				<View style={styles.spacerMedium} />
				
				
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>What time do you typically go to bed and try to fall asleep{!diffWeekend ? "" : " on a scheduled day (weekday)"}?</Text>
					<TimePickerCallen dialog={true} resolution={5} combined={false} hour={DATA.bedTimeWeekday.hr} minute={DATA.bedTimeWeekday.min} onTimeChanged={(hr,min) => { 
						DATA.bedTimeWeekday = { hr, min };
						if(!diffWeekend) DATA.bedTimeWeekend = { hr, min };
						props.onChange({...DATA});
					}}/>
				</View>							
				<View style={styles.spacerMedium} />
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>How long does it typically take you to fall asleep{!diffWeekend ? "" : " on a scheduled day (weekday)"}?</Text>
					<TimePickerCallen dialog={true} resolution={1} combined={false} timespan={true} hour={DATA.solWeekday.hr} minute={DATA.solWeekday.min} onTimeChanged={(hr,min) => { 
						DATA.solWeekday = { hr, min };
						if(!diffWeekend) DATA.solWeekend = { hr, min };
						props.onChange({...DATA});
					}}/>
				</View>				
				<View style={styles.spacerMedium} />
				<View style={styles.myContainer}>
					<Text style={styles.getStartedText}>What time do you typically wake up and start your day{!diffWeekend ? "" : " on a scheduled day (weekday)"}? </Text>
					<TimePickerCallen dialog={true} resolution={5} hour={DATA.wakeTimeWeekday.hr} minute={DATA.wakeTimeWeekday.min} onTimeChanged={(hr,min) => { 
						DATA.wakeTimeWeekday = { hr, min };
						if(!diffWeekend) DATA.wakeTimeWeekend = { hr, min };
						props.onChange({...DATA});
					}}/>
					{/*<Text style={[styles.getStartedText, {opacity: 0.4}]}>Debug: {("00"+hr1).slice(-2) + ":" + ("00"+min1).slice(-2)} / {(((hr1+11)%12)+1)+":"+("00"+min1).slice(-2)+(hr1 < 12 ? " AM" : " PM")}</Text>*/}
				</View>

				{(!diffWeekend
				? null
				: (<View>
					<View style={styles.spacerLarge} />
					<View style={styles.myContainer}>
						<Text style={styles.getStartedText}>What time do you typically go to bed and try to fall asleep on an unscheduled day (weekend)?</Text>
						<TimePickerCallen dialog={true} resolution={5} combined={false} hour={DATA.bedTimeWeekend.hr} minute={DATA.bedTimeWeekend.min} onTimeChanged={(hr,min) => { 
							DATA.bedTimeWeekend = { hr, min };
							props.onChange({...DATA});
						}}/>
					</View>							
					<View style={styles.spacerMedium} />
					<View style={styles.myContainer}>
						<Text style={styles.getStartedText}>How long does it typically take you to fall asleep on an unscheduled day (weekend)?</Text>
						<TimePickerCallen dialog={true} resolution={1} combined={false} timespan={true} hour={DATA.solWeekend.hr} minute={DATA.solWeekend.min} onTimeChanged={(hr,min) => { 
							DATA.solWeekend = { hr, min };
							props.onChange({...DATA});
						}}/>
					</View>
					<View style={styles.spacerMedium} />
					<View style={styles.myContainer}>
						<Text style={styles.getStartedText}>What time do you typically wake up and start your day on an unscheduled day (weekend)?</Text>
						<TimePickerCallen dialog={true} resolution={5} hour={DATA.wakeTimeWeekend.hr} minute={DATA.wakeTimeWeekend.min} onTimeChanged={(hr,min) => { 
							DATA.wakeTimeWeekend = { hr, min };
							props.onChange({...DATA});
						}}/>
					</View>
				</View>
				))}
					 
				<View style={styles.spacerLarge} />
				<View style={styles.myContainer}>						
					<NumberStepper 
						title="How many naps do you take in a day?" 
						value={DATA.napsPerDay} 
						onChange={(val)=>{DATA.napsPerDay=val; props.onChange({...DATA});}} 
					/>
									
					<View style={styles.spacerMedium} />
				 
					{(DATA.napsPerDay <= 0) 
					? null
					: <View>
						<Text style={styles.getStartedText}>How long does a typical nap last?</Text>
						<TimePickerCallen dialog={true} resolution={1} timespan={true} hour={DATA.napDuration.hr} minute={DATA.napDuration.min} onTimeChanged={(hr,min) => { 
							DATA.napDuration = { hr, min };
							props.onChange({...DATA});
						}}/>
					</View>
					}
				</View>				

			</View>
		); 
		validator = () => (DATA.differentWeekends !== null && DATA.napsPerDay !== null 
			&& (DATA.solWeekday.hr > 0 || DATA.solWeekday.min > 0)
			&& (DATA.solWeekend.hr > 0 || DATA.solWeekend.min > 0)
			&& (DATA.napsPerDay === 0 || DATA.napDuration.hr > 0 || DATA.napDuration.min > 0));
		break;

		
		case 3: 
		// too much boilerplate code... so I made it "data driven" >:)
		const affectedLikerts = [
			{key: 'fatigue', label: 'Fatigue'}, 
			{key: 'irritability', label: 'Irritability'},
			{key: 'concentration', label: 'Concentration'},
			{key: 'hyperactivity', label: 'Hyperactivity'}, 
			{key: 'impulsiveness', label: 'Impulsiveness'}, 
			{key: 'moodChanges', label: 'Mood changes'}, 
			{key: 'sleepinessCar', label: 'Sleepiness in Car'}, 
			{key: 'sleepinessTv', label: 'Sleepiness Watching TV'}, 
			{key: 'sleepinessWorkSchool', label: 'Sleepiness at Work/School'}, 
			{key: 'sleepinessOther', label: 'Sleepiness During Other Activities'} 
		];
		
		pageData = (
			<View style={styles.myContainer}>
				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Please indicate if your sleep affects your daytime function in the following areas: </Text>
				</View>
				<View style={styles.spacerLarge} />

				{affectedLikerts.map((obj,ix) => {
					return buildLikertScale(obj.key, obj.label, 'Minimally affected', 'Very affected', 'Somewhat affected');
				})}
				
				<MultiCheckBox 
					title='Have you ever fallen asleep during focused activities, like during a conversation with someone or while driving?'
					fieldNames={[ 'Yes', 'No' ]}
					fieldValues={(DATA.fallAsleepFocus === null ? null : [DATA.fallAsleepFocus, !DATA.fallAsleepFocus])}
					onChange={(arr) => {DATA.fallAsleepFocus = arr[0]; props.onChange({...DATA}); }}
					isRadio={true} 
				 />	
 				<View style={styles.spacerMedium} />

			</View>
		);
		validator = () => {
			if(DATA.fallAsleepFocus === null) return false;
			for(let i=0; i<affectedLikerts.length; i++)
				if(DATA[affectedLikerts[i].key] === null)
					return false;
			return true;
		};
		break;

		
		case 4: 
		pageData = (
			<View style={styles.myContainer}>
				 
				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Sleep Behaviors:</Text>
				</View>
				
				<MultiCheckBox 
					title={"Which of the following activities, other than sleep and sex, do you engage in while in your bed?\n(check all that apply)"}
					fieldNames={BedActivityValues}
					fieldValues={bedActBools = convertStringsToMultiboxValues(DATA.activitiesInBed, BedActivityValues)}
					onChange={(arr) => { 
						arr = enforceMutexOption(bedActBools, arr, arr.length - 1);						
						DATA.activitiesInBed = convertMultiboxToStrings(arr, BedActivityValues); 
						props.onChange({...DATA}); 
					}} 
				 />					 
				<View style={styles.spacerMedium}  />
				 				
				<MultiCheckBox 
					title={"Who shares a bed with you at night?\n(check all that apply)"}
					fieldNames={BedPartnerValues}
					fieldValues={bedPartnerBools = convertStringsToMultiboxValues(DATA.bedPartners, BedPartnerValues)}
					onChange={(arr) => {
						arr = enforceMutexOption(bedPartnerBools, arr, 0);						
						DATA.bedPartners = convertMultiboxToStrings(arr, BedPartnerValues); 
						props.onChange({...DATA}); 
					}}
				 />					 
				<View style={styles.spacerMedium} />
				{ DATA.bedPartners.length < 1 || DATA.bedPartners[0] === BedPartnerValues[0]
				? null
				:
				<View>
					<MultiCheckBox 
						title='Does sharing your bed disturb your sleep? (snoring, movement, wake patterns)'
						fieldNames={[ 'Yes', 'No' ]}
						fieldValues={(DATA.bedPartnerDisturb === null ? null : [DATA.bedPartnerDisturb, !DATA.bedPartnerDisturb])}
						onChange={(arr) => { DATA.bedPartnerDisturb = arr[0]; props.onChange({...DATA}); }}
						isRadio={true} 
					 />	
					<View style={styles.spacerMedium} />
				</View>
				}

				<MultiCheckBox 
					title={"When you cannot sleep, what do you do?\n(check all that apply)"}
					fieldNames={CantSleepValues}
					fieldValues={cantSleepBools = convertStringsToMultiboxValues(DATA.activitiesCantSleep, CantSleepValues)}
					onChange={(arr) => { 
						arr = enforceMutexOption(cantSleepBools, arr, arr.length - 1);
						DATA.activitiesCantSleep = convertMultiboxToStrings(arr, CantSleepValues); 
						props.onChange({...DATA}); 
					}}									
				 />					 
				<View style={styles.spacerMedium} />
				
				<View style={styles.myContainer}>				
					<MultiCheckBox 
						title='How many times per week do you exercise?'
						fieldNames={ExerValues}
						fieldValues={convertStringsToMultiboxValues(DATA.exercisePerWeek, ExerValues)}
						isRadio={true}
						onChange={(arr) => { DATA.exercisePerWeek = convertMultiboxToStrings(arr, ExerValues)[0]; props.onChange({...DATA}); }} 						
					/>
					{(DATA.exercisePerWeek == ExerValues[0]
					? null
					: <View style={styles.myContainer}>
						<View style={styles.spacerMedium} />
						<Text style={styles.getStartedText}>What time do you typically exercise{(DATA.exercisePerWeek != ExerValues[ExerValues.length - 1]) ? "" : " for the last time in a day"}? </Text>
						<TimePickerCallen dialog={true} resolution={5} hour={DATA.exerciseTime.hr} minute={DATA.exerciseTime.min} onTimeChanged={(hr,min) => { 
							DATA.exerciseTime = { hr, min };
							props.onChange({...DATA});
						}}/>
					</View>
					)}
				</View>

			</View>
		);
		validator = () => (DATA.bedPartners.length > 0 && DATA.activitiesInBed.length > 0 && DATA.activitiesCantSleep.length > 0 
			&& DATA.exercisePerWeek !== null && (DATA.bedPartners[0] === BedPartnerValues[0] || DATA.bedPartnerDisturb !== null));
		break;

		
		case 5: 
		const selectedRx = DATA.pastSolutions && DATA.pastSolutions.length > 0 && DATA.pastSolutions.indexOf(PastSolutionValues[0]) >= 0;
		const selectedOtc = DATA.pastSolutions && DATA.pastSolutions.length > 0 && DATA.pastSolutions.indexOf(PastSolutionValues[1]) >= 0;
		pageData = (
			<View style={styles.myContainer}>
				<View style={styles.myContainer}>
					<Text style={styles.sectionHeaderText}>Sleep Environment:</Text>
				</View>			
						  
				<MultiCheckBox 
					title='Do you spend time in bed during the day?'
					fieldNames={[ 'Yes', 'No' ]}
					isRadio={true}
					fieldValues={(DATA.inBedDuringDay === null ? null : [DATA.inBedDuringDay, !DATA.inBedDuringDay])}
					onChange={(arr) => {DATA.inBedDuringDay = arr[0]; props.onChange({...DATA}); }}
				 />	
				 
				{buildLikertScale('brightnessNight', 'How bright is your bedroom at night?', 'Completely Dark', 'Very Bright')}
				
				{buildLikertScale('brightnessMorning', 'How bright is your bedroom in the morning?', 'Completely Dark', 'Very Bright')}
				
				{buildLikertScale('loudnessNight', 'How loud is it in your bedroom at night?', 'Silent', 'Very Loud')}
				
				{buildLikertScale('comfortMattress', 'How comfortable is your mattress?', 'Very Uncomfortable', 'Very Comfortable')}
				
				
				<MultiCheckBox
					title={"Do you consume any of the following things which may impact your sleep?\n(check all that apply)"}
					fieldNames={SubstanceValues}
					fieldValues={substanceBools = convertStringsToMultiboxValues(DATA.substances, SubstanceValues)}
					onChange={(arr) => {
						arr = enforceMutexOption(substanceBools, arr, arr.length - 1);
						DATA.substances = convertMultiboxToStrings(arr, SubstanceValues); 
						props.onChange({...DATA}); 
					}}					
				/>		
				
				<MultiCheckBox
					title={"What have you done in the past to improve your sleep problems?\n(check all that apply)"}
					fieldNames={PastSolutionValues}
					fieldValues={pastSolutionBools = convertStringsToMultiboxValues(DATA.pastSolutions, PastSolutionValues)}
					onChange={(arr) => {
						arr = enforceMutexOption(pastSolutionBools, arr, arr.length - 1);
						DATA.pastSolutions = convertMultiboxToStrings(arr, PastSolutionValues); 
						props.onChange({...DATA}); 
					}}					
				/>	
				{!selectedRx ? null :
				<MultiCheckBox
					title={"Which prescription medications have you tried to help your insomnia?\n(check all that apply)"}
					fieldNames={PastRx}
					fieldValues={pastRxBools = convertStringsToMultiboxValues(DATA.pastRxs, PastRx)}
					onChange={(arr) => {
						arr = enforceMutexOption(pastRxBools, arr, arr.length - 1);
						DATA.pastRxs = convertMultiboxToStrings(arr, PastRx); 
						props.onChange({...DATA}); 
					}}					
				/>}
				{!selectedOtc ? null :
				<MultiCheckBox
					title={"Which over-the-counter medications have you tried to help your insomnia?\n(check all that apply)"}
					fieldNames={PastOtc}
					fieldValues={pastOtcBools = convertStringsToMultiboxValues(DATA.pastOtcs, PastOtc)}
					onChange={(arr) => {
						arr = enforceMutexOption(pastOtcBools, arr, arr.length - 1);
						DATA.pastOtcs = convertMultiboxToStrings(arr, PastOtc); 
						props.onChange({...DATA}); 
					}}					
				/>}		
			</View>
		); 
		validator = () => (DATA.brightnessNight !== null && DATA.brightnessMorning !== null && DATA.loudnessNight !== null && DATA.comfortMattress !== null
			&& DATA.inBedDuringDay !== null && DATA.substances.length > 0 && DATA.pastSolutions.length > 0 
			&& (!selectedRx || DATA.pastRxs.length > 0) && (!selectedOtc || DATA.pastOtcs.length > 0));
		break;

		
		case 6: 
		pageData = (
			<View style={styles.myContainer}>
				<Text style={{fontSize: 42, color: '#77c'}}>
					Intake form completed{!props.time ? "" : (" in (" + Math.floor(formTime / 60) + ":" + ("00"+(formTime % 60)).slice(-2) + ")")}. (Click the Devices button at the bottom to finish setting up Faster Asleep)
				</Text>	
				<Text style={{color: '#aaa', margin: 10}}>
				{ (!serverResults || Object.getOwnPropertyNames(serverResults).length === 0) ? "Processing results..." : ("Server results: \n" + JSON.stringify(serverResults, null, 2)) }
				</Text>
			</View>
		); break;

		
		default: 
			pageData = (
				<Text style={{fontSize: 90, color: '#777'}}>404 Content Not Found!</Text>
			); break;
		
		
	}
	
	
	
	

  return (
    <View style={styles.container}>
		<ScrollView ref={scrollEl} style={styles.container} contentContainerStyle={styles.contentContainer}>        
			<View style={styles.getStartedContainer}>			
				<DotsPaging length={props.pageCount || 5} active={props.page} marginHorizontal={4}  paddingHorizontal={50} />		
 
				{pageData}
 
				<View style={styles.spacerLarge} />

				<Button 
					title="Next"
					disabled={props.page >= (props.pageCount-1) || !validator()}
					onPress={()=>props.navigation.push("Page "+(props.page+1))}
				/>
				<View style={styles.spacerLarge} />
			</View>
		</ScrollView>
	</View>
  );
}

QuestionsScreen.navigationOptions = {
  header: null,
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingTop: 30,
  },
  myContainer: {
	width: "100%"
  },
  horzContainer: {
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center"
  },
  bordered: {
	  borderWidth: 2
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 20,
  }, 
  getStartedText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
	margin: 8
  },
  sectionHeaderText: {
    fontSize: 32,
    color: '#102452',
    lineHeight: 50,
    textAlign: 'center',
  },
  sectionSubheaderText: {
    fontSize: 22,
    color: '#102452',
    lineHeight: 30,
    textAlign: 'center',
  },
  textInputStyle: {
	borderWidth: 1,
	borderColor: "#ccc",
	backgroundColor: "#f2f9fc",
	padding: 5,
	width: 110,
	height: 32,
	marginTop: 5,
	marginBottom: 15,
	textAlign: 'left'
  },
  picker: {
	fontSize: 24,
	textAlign:'center'
  },  
  spacerSmall:{
	  width: 10,
	  height: 10,
  },
  spacerMedium:{
	  width: 20,
	  height: 20,
  },
  spacerLarge:{
	  width: 60,
	  height: 60,
  },
  
});
