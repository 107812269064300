import React, { useState } from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, FlatList, Picker, Switch, TextInput, Easing  } from 'react-native';
import { MultiCheckBox } from '../components/MultiCheckBox';
import { Slider } from '@miblanchard/react-native-slider';
import { DotsDisplay } from './DotsDisplay';

// props = {title, minDescriptor, maxDescriptor}
export function LikertScale(props) {
	const [vals, setVals] = useState(new Array(5));
	const [slideVal, setSlideVal] = useState(3);
	const scaleArr = ['1', '2', '3', '4', '5'];
	
	function handleChange(newValue){
		const val = newValue[0];
		console.log("new value1:");
		console.log(val);
		if(useSlider)
			setSlideVal(val);
		else
		{	for(let i=0; i<vals.length; i++)
				vals[i] = (i + 1) == val;
			setVals([...vals]);
		}
		console.log("new value2:");
		console.log(val);
		if(props.onChange) props.onChange(val);
	}

	const useSlider = props.slider !== false;
	const sliderNums = !useSlider ? null : [];
	if(useSlider) 
		for(let i=0; i<scaleArr.length; i++)
			sliderNums[i] = (				
				<Text style={(slideVal == (i + 1)) ? styles.numSelected : styles.numUnselected} key={i+""}>{i+1}</Text>
			);

	if(props.value === null || (props.value && props.value > 0 && props.value <= scaleArr.length)) {
		if(useSlider) {
			if(props.value === null && slideVal != 0) setSlideVal(0)
			else if(props.value !== null && slideVal != props.value) setSlideVal(props.value);
		}
		else {
			let needsReset = false;
			for(let i=0; i<vals.length; i++) {
				const targetVal = ((i+1) == props.value);
				if(vals[i] != targetVal) {
					needsReset = true;
					vals[i] = targetVal;
				}
			}
		}
	}
	let leftStyle = styles.textStyleLeft;
	let rightStyle = styles.textStyleRight;
	let centerStyle = styles.textStyleMiddle;
	if(!props.midDescriptor || props.midDescriptor.length == 0) {
		leftStyle = StyleSheet.compose(leftStyle, {flexBasis: 100});
		rightStyle = StyleSheet.compose(rightStyle, {flexBasis: 100});
		centerStyle = StyleSheet.compose(centerStyle, {flexBasis: 25});
	}
	
	return (
		<View style={/*props.style*/{width: "100%"}}>
		{( !useSlider 
		?	<MultiCheckBox horizontal={true} 
				title={props.title} 
				fieldNames={scaleArr} 
				fieldValues={vals} 
				isRadio={true} 
				onChange={(arr) => { handleChange(arr.indexOf(true) + 1); }}
			/>
		:	<View style={{width: "100%"}}>
				<Text style={styles.title}>{props.title}</Text>

				<View style={{marginBottom: 0, marginTop: 10, width: '100%', justifyContent: 'center'}}>
					<View style={{marginHorizontal: 3}}>
						<DotsDisplay length={5} active={slideVal-1} activeMode='left' activeDotWidth={17} passiveDotWidth={17} activeColor='#9ed2f7' passiveColor='#cccccc' />
					</View>
				</View>	
				
				<View style={{width: '100%', marginBottom: -49}}>
					<View style={{marginHorizontal: 2, flexDirection: 'row', justifyContent: 'space-between'}}>
						{sliderNums}
					</View>
				</View>
				
				<Slider containerStyle={{marginHorizontal: 2}} 
					value={slideVal} minimumValue={1} maximumValue={scaleArr.length} onValueChange={(val)=>handleChange(val)} step={1}
					thumbTouchSize={{width:100,height:50}}
					thumbTintColor={props.value === null ? "#cccccc" : "#0086e5"}
					maximumTrackTintColor="#cccccc"
					minimumTrackTintColor="#9ed2f7"
					trackClickable={true}
					animateTransitions={true}
					// renderThumbComponent={() => (
					// <View style={{borderRadius: 10, width: 20, height: 20, position: "absolute", backgroundColor:'#2f95dc'}}>
					//		<Text style={{color: '#FFF'}}>{slideVal}</Text>
					// </View>
					// )}
				/>
			
				<View style={styles.spacer} />
			</View>
		)}
			<View style={styles.textContainer}>
				<Text style={leftStyle}>{props.minDescriptor}</Text>
				{/*<Text >{useSlider ? slideVal : ""}</Text>*/}
				<Text style={centerStyle}>{props.midDescriptor}</Text>
				<Text style={rightStyle}>{props.maxDescriptor}</Text>
			</View>
		</View>
	);
}


const styles = StyleSheet.create({
  textContainer: {
	flexDirection: "row", 
	justifyContent: "space-between", 
	width: "100%"
  },
  spacer: {
	  height: 12
  },
  title: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  textStyleLeft: {
	color: "#888",
	flexBasis: 75,
	borderWidth: 0
  },
  textStyleRight: {
	color: "#888",
	flexBasis: 75,
	textAlign: "right",
	borderWidth: 0
  },
  textStyleMiddle: {
	color: "#888",
	flexBasis: 75,
	textAlign: "center",
	borderWidth: 0
  },
  numUnselected: {
	color: "#ccc",
	fontSize: 14,
	width: 20,
	height: 20,
	textAlign: 'center'
  },
  numSelected: {
	color: "#000",
	fontWeight: 'bold',
	fontSize: 16,
	height: 17,
	width: 20,
	textAlign: 'center'
  }

});