import React, { useState } from 'react';
import { StyleSheet, Text, View, Button, TouchableOpacity  } from 'react-native';
import { CheckBox } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';

export function NumberStepper(props) {
	
	const setValue = !!props.value || props.value === 0 ? props.value : null;
	const min = props.minimum || 0;
	const max = !!props.maximum || props.maximum === 0 ? props.maximum : 100;
	
	const minusEnabled = setValue === null || setValue > min;
	const plusEnabled = setValue === null || setValue < max;
	
	const colorEnabled = "rgba(0,0,0,0.75)";
	const colorDisabled = "rgba(0,0,0,0.25)";
	
	const contStyle = (!props.style ? styles.container : StyleSheet.compose(styles.container, props.style));
	
	return (
		<View style={contStyle}>
			<Text style={styles.title}>{props.title}</Text>
			<View style={!props.vertical ? styles.pickerContainerHorz : styles.pickerContainerVert}>
				<TouchableOpacity disabled={!minusEnabled} onPress={()=>props.onChange(setValue === null ? min : Math.max(min, setValue-1))}>
					<Ionicons name='ios-remove-circle-outline' size={50} color={minusEnabled ? colorEnabled : colorDisabled} />
				</TouchableOpacity>
				<View style={styles.spacer} />
				<Text style={styles.number}>{setValue === null ? " " : setValue}</Text>
				<View style={styles.spacer} />
				<TouchableOpacity disabled={!plusEnabled} onPress={()=>props.onChange(setValue === null ? (min + 1) : Math.min(max, setValue+1))}>
					<Ionicons name='ios-add-circle-outline' size={50} color={plusEnabled ? colorEnabled : colorDisabled}  />
				</TouchableOpacity>				
			</View>
			<Text style={styles.bottomText}>{setValue === null ? "Click plus or minus to enter a number" : " "}</Text>
		</View>
	);
}

const styles = StyleSheet.create({
  container: {
	width: "100%",
	alignItems: 'center'
  },
  pickerContainerHorz: {
	flexDirection: 'row',
	alignItems: 'center'
  },
  pickerContainerVert: {
	flexDirection: 'column-reverse',
	alignItems: 'center'
  },
  title: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  number: {
    fontSize: 24,
    color: '#222',
    lineHeight: 24,
    textAlign: 'center',
  },
  bottomText: {
	  color: "#aaa"
  },
  spacer: {
	marginHorizontal: 15,
	marginVertical: 8,
  }
});