// rewrite of https://www.npmjs.com/package/react-native-dots-pagination due to bugs in package
import React, { Component, useRef, useState } from 'react';
import { View, ScrollView, Platform, StyleSheet } from 'react-native';


const Styles = StyleSheet.create({
  container: {
	flexDirection: 'row',
    justifyContent: 'center', 
    alignItems: 'center'
  },

  passive: { },
  active: { }
});

//const scalesPageToFit = Platform.OS === 'android';

const DEFAULT_PASSIVE_DOT_WIDTH = 10;
const DEFAULT_ACTIVE_DOT_WIDTH = 15;

export function DotsPaging(props){

	const scrollRef = useRef(null);
	const [prevActive, setPrevActive] = useState(0);

	const length = props.length || 1;
	const active = props.active || 0;

	const width = props.width || 300;
	const marginHorizontal = props.marginHorizontal || 2;
	const paddingVertical = props.paddingVertical || 10;
	const paddingHorizontal = props.paddingHorizontal || 10;
	const activeDotWidth = props.activeDotWidth || DEFAULT_ACTIVE_DOT_WIDTH;
	const activeDotHeight = props.activeDotHeight || DEFAULT_ACTIVE_DOT_WIDTH;
	const passiveDotWidth = props.passiveDotWidth || DEFAULT_PASSIVE_DOT_WIDTH;
	const passiveDotHeight = props.passiveDotHeight || DEFAULT_PASSIVE_DOT_WIDTH;
	const passiveColor = props.passiveColor || '#CCCCCC';
	const activeColor = props.activeColor || '#016bd8';
	const activeBorder = props.activeBorder || false;
	const activeBorderWidth = props.activeBorderWidth || 3;
	const activeBorderColor = props.activeBorderColor || '#FFF';
	
    /*if (prevActive !== active)
	{
		setPrevActive(active);
		scrollTo(newActive);
	}

	function scrollTo(index) {
		const { width, activeDotWidth } = this.props;
		const get = this[`dots_${index}`];

		if (get) {
		  const x = get.x - (width / 2 - activeDotWidth);

		  return this.scrollRef.scrollTo({ x });
		}
	}*/
	
	
	function dotStyle(isActive) {

		const width = isActive ? activeDotWidth : passiveDotWidth;
		const marginTop = !isActive ? 0 : -width / 6;

		let height = width;

		if (isActive) 
			height = activeDotHeight;
		else
			height = passiveDotHeight;


		let style = {
			width,
			height,
			marginHorizontal,
			backgroundColor: isActive ? activeColor : passiveColor,
			borderRadius: width,
			marginTop,
		};
		
		//console.log(style);
		

		// active Border Styles.
		if (activeBorder && isActive) {
			style.borderWidth = activeBorderWidth;
			style.borderColor = activeBorderColor;
		}

		return style;
	}	
	
	
    const list = [...Array(length).keys()];
    const scrollWidth = marginHorizontal * list.length * passiveDotWidth;	
	
	return (
      <View style={[Styles.container, { paddingVertical, paddingHorizontal, width: width < scrollWidth ? width : scrollWidth }]}>

          {list.map(i => {
            return (
              <View
                key={i}
                style={dotStyle(active === i)}
                onLayout={({
                  nativeEvent: {
                    layout: { x, y },
                  },
                }) => {
				  <View />
                }}
              />
            );
          })}

      </View>
    );	
};