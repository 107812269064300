import React, { useState, useRef } from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Button  } from 'react-native';
import { SleepLogChart } from '../components/SleepLogChart';

import Utils from '../Utils';

export function UserHomeScreen(props) {
	const [showDebug, setShowDebug] = useState(false);
	const [showExpanded, setShowExpanded] = useState(false);
	const [initLoad, setInitLoad] = useState(false);
	
	const udata = props.userdata;	
	const onRefresh = props.onRefresh;
	const metrics = Utils.calcSleepMetricAverages(udata.logs);
	const showMetrics = !!metrics && udata.logs.length;
	let recentTrends = null; // past couple days are used to determine if stats are improving or declining
	
	//const profileAttribs = [];
	//for(const k in udata) {
	//	profileAttribs[profileAttribs.length] = { key: k, value: udata[k] };
	//}
	
	//profileAttribs.sort((a,b) => (a.replace("intakeData", "zzzzzzz")).localeCompare(b.replace("intakeData", "zzzzzzz")));
	
	const boxColors = [ '#ffffff',  '#eeeeee', '#dedede', '#cac9f4',];
	
	function buildObjectBoxes(obj, depth) {
		const ok = [];
		for(const ky in obj) {
			ok.push(ky);
		}
		if(depth >= 1)
		{
			ok.sort((a,b) =>{ 
				const aObj = typeof obj[a] === 'object' && obj[a] !== null && !Array.isArray(obj[a]);
				const bObj = typeof obj[b] === 'object' && obj[b] !== null && !Array.isArray(obj[b]);
				if(aObj != bObj)
					return aObj ? 1 : -1;
				return a.localeCompare(b);
			});
		}
		//console.log(JSON.stringify(ok));
		
		const isArr = Array.isArray(obj);
		const boxes = [];
		for(let i=0; i<ok.length; i++) {
			const k = ok[i];
			const val = obj[k];
			const isText = !(depth > 0 && typeof val === 'object' && val !== null);

			let prefix = "";
			for(let j=0; j<3-depth; j++)
				prefix += ". ";
			
			//console.log(prefix + " d=" + depth + ", i=" + i + ", isText=" + isText + ", k=" + k + ", v=" + (!isText ? "[obj]" : JSON.stringify(val)));			

			const boxContent = !isText ? buildObjectBoxes(val, depth-1) : JSON.stringify(val, null, 2);

			//if(!isText) console.log(prefix + " d=" + depth + ", i=" + i + ", content="+typeof(boxContent)+", "+boxContent.length+" (array="+Array.isArray(boxContent)+")");	
			
			const bstyle = StyleSheet.compose(sty.boxStyle, {backgroundColor: boxColors[depth]});
			boxes.push(
				<View key={depth + "-" + i} style={bstyle}>
					{isArr
					? null
					: <Text style={sty.boxHeader}>{k.replace('"','')}</Text>
					}
					{isText
					? <Text>{boxContent}</Text>
					: boxContent
					}
				</View>
			);
		}
		return boxes && boxes.length ? boxes : null;
	};
	
	//console.log("DATA**********************\n"+JSON.stringify(udata));
	
	
	
	let minDate = -1, maxDate = -1;
	if(showMetrics) {
		let logCount = udata.logs.length;
		for(let i=0; i<logCount; i++) {
			const dt = new Date(udata.logs[i].datatype.substring("MorningLog_".length)).getTime();
			minDate = (minDate < 0 || dt < minDate) ? dt : minDate;
			maxDate = (maxDate < 0 || dt > maxDate) ? dt : maxDate;
		}
		if(minDate > 0 && maxDate > 0) {
			minDate = new Date(minDate);
			minDate = (minDate.getUTCMonth() + 1) + "/" + minDate.getUTCDate();
			maxDate = new Date(maxDate);
			maxDate = (maxDate.getUTCMonth() + 1) + "/" + maxDate.getUTCDate();
		}
		else minDate = maxDate = -1;
		
		// if we have at least 5 logs, calculate trends for coloring metrics numbers
		if(logCount > 4) {
			recentTrends = {};
			const rLogs = udata.logs.slice(Math.ceil(logCount / 2));
			const rMetrics = Utils.calcSleepMetricAverages(rLogs);
			console.log(metrics);
			console.log(rMetrics);
			const stats = ["tib","timeAsleep","waso","sol"];
			for(let i=0; i<stats.length; i++){
				const k = stats[i];
				const mVal = metrics[k];
				const rmVal = rMetrics[k];
				console.log("comparing "+k+" avg="+mVal+" to recent="+rmVal+", ratio="+(mVal / rmVal));
				if(mVal / rmVal < 0.98) recentTrends[k] = 1;
				else if(mVal / rmVal > 1.02) recentTrends[k] = -1;
				else recentTrends[k] = 0;
			}
			// SE must be calculated manually
			const mSE = metrics["timeAsleep"] / metrics["tib"];
			const rmSE = rMetrics["timeAsleep"] / rMetrics["tib"];
			if(mSE / rmSE < 0.98) recentTrends["se"] = 1;
			else if(mSE / rmSE > 1.02) recentTrends["se"] = -1;
			else recentTrends["se"] = 0;
			console.log(recentTrends);
		}
	}
	
	function makeMetricItem(label, value, trendColor, trendArrow) {
		const textStyle = !trendColor ? sty.metricsText : (trendColor > 0 ? sty.metricsTextIncrease : sty.metricsTextDecrease);
		const arrowText = !trendColor || !trendArrow ? "" : (trendArrow > 0 ? " ➚" : " ➘")
		return (
			<View style={sty.metricsItem}>
				<View style={sty.metricsLabel}><Text style={sty.metricsLabelText}>{label}</Text></View>
				<View style={sty.metricsValue}><Text style={textStyle}>{value} {arrowText}</Text></View>
			</View>
		);
	}
	
	if(!initLoad) {
		setInitLoad(true);
		if(onRefresh) onRefresh();
	}
	
	return (
		<ScrollView style={sty.scrollCont}>
			<View style={sty.container}>
				<Text style={sty.header}>My Sleep Data</Text>


				{!showMetrics ? null :
				<View style={sty.metricsBox}>
					<Text style={sty.metricsHeader}>Averages from {minDate === -1 ? "recent data" : (minDate + " to " + maxDate)}:</Text>
					<View>
					{makeMetricItem("Time To Fall Asleep:", Utils.minsToSpeech(metrics.sol), -(recentTrends?.sol || 0), recentTrends?.sol || 0)}
					{makeMetricItem("Time Awake At Night:", Utils.minsToSpeech(metrics.waso), -(recentTrends?.waso || 0), recentTrends?.waso || 0)}
					{makeMetricItem("Time Spent In Bed:", Utils.minsToSpeech(metrics.tib), -(recentTrends?.tib || 0), recentTrends?.tib || 0)}
					{makeMetricItem("Time Spent Asleep:", Utils.minsToSpeech(metrics.timeAsleep), recentTrends?.timeAsleep || 0, recentTrends?.timeAsleep || 0)}
					{makeMetricItem("Sleep Efficiency:", (Math.round(1000 * metrics.timeAsleep / metrics.tib) / 10) + "%", recentTrends?.se || 0, recentTrends?.se || 0)}
					</View>
				</View>
				}

				
				<SleepLogChart logs={udata.logs} expanded={showExpanded}/>
				
				{!showMetrics || true ? null :
				<View>
					<View style={sty.spacer} />
					<Button
							color='#246bb1'
							title={showExpanded ? ' Shrink Chart ' : ' Expand Chart '}
							onPress={()=>{setShowExpanded(!showExpanded)}}					
					/>
				</View>
				}

				<View style={sty.spacer} />
				{!showMetrics && false ? null :
				<View style={sty.refreshButton}>
					<Button
						color='#bc7272'
						title={' Refresh '}
						onPress={()=>{ if(onRefresh) { onRefresh(); } }}					
					/>
				</View>
				}
				<View style={sty.spacer} />
				<View style={sty.spacer} />
				<View style={sty.spacer} />
				
				<Button
						color='#95c7c7'
						title={showDebug ? ' Hide Debug Data ' : ' Show Debug Data '}
						onPress={()=>{setShowDebug(!showDebug)}}					
				/>
				<View style={sty.spacer} />
				{showDebug ? buildObjectBoxes(udata,3) : null}
			</View>
		</ScrollView>
	);
}

const sty = StyleSheet.create({
  scrollCont: {
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'	  
  },
  container: {
	alignItems: 'center',
	width: '100%',
	minHeight: '100%',
	backgroundColor: '#FFFFFF'
  },
  header: {
	color: '#323180',
	fontSize: 20,
	margin: 2,
  },
  boxStyle: {
	alignItems: 'stretch',
	padding: 2,
	paddingLeft: 5,
	marginLeft: 6,
	marginVertical: 2,
  },
  boxHeader: {
	fontSize: 11,
	fontWeight: 'bold'	
  },
  refreshButton: {
	  margin: 20
  },
  spacer: {
	  height: 25
  },
  
  metricsBox: {	
	alignItems: 'center',
	backgroundColor: "#e5e5d2",
	paddingBottom: 4,
	marginBottom: 6,
	marginTop: 3
  },
  metricsHeader: {
	color: '#3e3d66',
	fontSize: 14,
	margin: 2,
	fontWeight: 'bold'
  },
  
  metricsItem: {
	flexDirection:'row', 
	justifyContent: 'flex-end',
	backgroundColor: "#f8f8f8",
	margin: 1,
	marginBottom: 0,
	paddingLeft: 15,
	padding: 0.5
  },
  metricsLabel: {
	  marginRight: 8,  
	  textAlign: 'right',
  },
  metricsLabelText: {
	  width: '100%',
	  fontSize: 12,
  },
  metricsValue: {
	  width: 170,
  },
  metricsTextIncrease: {
	color: '#2d6629',
	width: '100%',
	fontWeight: '600',
	fontSize: 12	
  },
  metricsTextDecrease: {
	color: '#801a2a',
	width: '100%',
	fontWeight: '600',
	fontSize: 12			
  },
  metricsText: {
	color: '#404040',
	width: '100%',
	fontWeight: '600',
	fontSize: 12,		
  },

});