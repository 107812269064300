import React, { useState } from 'react';
import { StyleSheet, Text, View, Button, ScrollView } from 'react-native';
import { SleepDataBar } from './SleepDataBar';

import Utils from '../Utils';

export function SleepLogChart(props) {

	const logs = props.logs || [];
	const isExpanded = props.expanded === true;
	
	let startHr = -1, endHr = -1;
	let minDate = -1, maxDate = -1;
	
	function compareHours(curHr, newHr, returnEarliest) {
		const isCurPM = curHr >= 12; 
		const isNewPM = newHr >= 12;
		// we could simplify the following since the logic is identical *except* using max/min, but that code would not be readable at all.
		if(!returnEarliest) {
			if(isCurPM === isNewPM) 		return Math.max(newHr, curHr); 	// both AM or both PM, return largest
			else if(!isCurPM && isNewPM) 	return newHr; 					// old was AM, new is PM, assume movement from morning to afternoon				
			else 							return curHr; 					// only other case is old was PM, new is AM, assume new is earlier and return old
		} 
		else {
			if(isCurPM === isNewPM) 		return Math.min(newHr, curHr); 	// both AM or both PM, return smallest
			else if(!isCurPM && isNewPM) 	return newHr; 					// old was AM, new is PM, assume movement before midnight
			else 							return curHr; 					// only other case is old was PM, new is AM, assume new is later and return old
		}		
	}
	
	// determine range of times needed for these logs (earliest to bed + latest wake up)
	for(let i=0; i<logs.length; i++) {
		const dt = new Date(logs[i].datatype.substring("MorningLog_".length)).getTime();
		minDate = (minDate < 0 || dt < minDate) ? dt : minDate;
		maxDate = (maxDate < 0 || dt > maxDate) ? dt : maxDate;
		
		const bedStart = Utils.parseTime(logs[i].seqData.timeInBed);//, false);
		const bedEnd = Utils.parseTime(logs[i].seqData.timeOutOfBed);//, true);
		
		console.log(startHr + " to " + endHr + ", compare to " + bedStart.hr + " and " + bedEnd.hr);
		if(startHr < 0 || endHr < 0) {
			startHr = bedStart.hr;
			endHr = bedEnd.hr;
		} 
		else {
			startHr = compareHours(startHr, bedStart.hr, true);
			endHr = compareHours(endHr, bedEnd.hr, false);
		}
		//check if startHr uninit'd OR startHr is a.m. comparing to p.m. OR both startHrs are p.m.
		//if(startHr < 0 || (startHr < 12 && bedStart.hr > 13) || (bedStart.hr < startHr && bedStart.hr > 14))
		//	startHr = bedStart.hr;
		//if(endHr < 0 || bedEnd.hr > endHr)
		//	endHr = bedEnd.hr;
		console.log(startHr + " to " + endHr);
	}
	
	if(startHr < 0) { 
		startHr = 21; 
		endHr = 12; 
	}
	else {
		startHr = (startHr + 24) % 24;
		endHr = (endHr + 25) % 24;
	}
	
	const oneDay = 24 * 60 * 60 * 1000;
	const dataBars = [];	
	for(let i=0,d=minDate; logs.length && d < maxDate+oneDay; i++, d+=oneDay) {
		const mon = (new Date(d)).getUTCMonth() + 1; // months are zero-based, day is not. weird.
		const day = (new Date(d)).getUTCDate();
		const year = (new Date(d)).getUTCFullYear();
		const logNameSuffix = year + "-" + ((mon < 10 ? "0" : "") + mon) + "-" + ((day < 10 ? "0" : "") + day);
		const logName = "MorningLog_" + logNameSuffix;
		let log = null;
		for(let j=0; !log && j<logs.length; j++)
			if(logs[j].datatype == logName)
				log = logs[j];
		console.log(logNameSuffix+": "+JSON.stringify(log));
		dataBars[i] = (
			<SleepDataBar key={i} 
				log={!log ? null : log.seqData} 
				label={(i%2==0 ? "\n" : "") + mon + "/" + day}
				startHour={startHr} 
				endHour={endHr} />
		);
	}
	
	const timeLabels = [];
	for(let i=startHr; true; i = (i + 1) % 24) {
		const lblStr = (((i + 23) % 12) + 1) + ":00";
		const ampm = (i != startHr && i != 0 && i != 12)
			? null
			: (i < 12 ? "AM" : "PM");
		timeLabels[timeLabels.length] = (
			<View key={i} style={i == endHr ? styles.timeLabelLast : styles.timeLabel}>
				<View style={styles.sepLine} />
				{i == endHr ? null : <View style={styles.sepLine2} />}
				<View style={styles.timeOffset}>
					<Text style={styles.timeTxt}>{lblStr}</Text>
					{!ampm ? null : (<Text style={styles.ampmTxt}>{ampm}</Text>)}
				</View>
			</View>
		);
		
		if(i == endHr)
			break;
	}
	
	const sty = StyleSheet.compose(styles.container, isExpanded ? styles.containerHeightExpanded : styles.containerHeightNormal);
	
	return (
	<View>
		<View style={sty}>
			<View style={styles.timesBox}>
				{timeLabels}
			</View>
			<View style={styles.barBox}>
				{dataBars.length > 0 ? dataBars	:
				(
					<View style={styles.noDataView}>
						<Text style={styles.noDataText}>No data loaded. Please click the refresh button</Text>
					</View>
				)}
			</View>
		</View>
	</View>
	);
}

const ChartHeight = 280;
const ChartHeightExpand = 420;
const ChartWidth = 316;
const TimesAreaWidth = 35;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "stretch", 
		width: ChartWidth,
		height: ChartHeight,
		marginBottom: 26,
		marginTop: 6,
		borderWidth: 0
	},
	containerHeightNormal: {
		height: ChartHeight,		
	},
	containerHeightExpanded: {
		height: ChartHeightExpand,		
	},
	timesBox: {
		justifyContent: "space-between",
		alignItems: "stretch", 
		//paddingLeft: 0,
		paddingRight: 2,
		padding: 'auto',
		flexGrow: 0,
		width: TimesAreaWidth,	
	},
	timeLabel: {
		flexGrow: 1
	},
	timeLabelLast: {
		flexGrow: 0
	},
	timeTxt: {
		fontSize: 11,
		minHeight: 0,
		textAlign: "right",
		zIndex: 1
	},
	ampmTxt: {
		fontSize: 8,
		zIndex: 1,
		textAlign: "right",
		position: "absolute",
		width: "100%",
		top: 10
	},
	timeOffset: {
		position: "absolute",
		width: "100%",
		top: -5,
	},
	sepLine: {
		position: "absolute",
		left: TimesAreaWidth,
		width: (ChartWidth - TimesAreaWidth),
		height: 1,
		backgroundColor: "#aaaaaa"
	},
	sepLine2: {
		position: "absolute",
		top: "50%",
		left: (TimesAreaWidth - 4),
		width: (ChartWidth - TimesAreaWidth + 4),
		height: 1,
		backgroundColor: "#eeeeee"
	},
	
	barBox: {
		flexDirection: "row",
		flex: 1,
		justifyContent: "space-around",
		borderWidth: 0.0,
		borderColor: "blue",
		marginLeft: 2,
		marginRight: 2
	},
	noDataView: {
		height: "100%",
		width: (ChartWidth * 0.67),
		justifyContent: "center",
		alignItems: "center"
	},
	noDataText: {
		fontSize: 28,
		zIndex: 1,
		color: "#444444",
		textAlign: "center"
	}
});